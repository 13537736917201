import createValidator from 'revalidate/lib/createValidator';
import { isValid, differenceInYears, parse } from 'date-fns';
import { date } from '@eti/utils';
import { INVALID_CHILD_BIRTH_DATE } from '../constants/defaultErrorMessages';
const isValidChildBirthDate = function (inBoundDate, format) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  return createValidator(message => input => {
    if (!input) {
      return null;
    }
    const birthDate = parse(input, format, new Date());
    const parsedInBoundDate = date.convertToDate(inBoundDate);
    const validInBoundDate = isValid(parsedInBoundDate);
    if (!validInBoundDate) {
      return null;
    }
    const validBirthDate = isValid(birthDate);
    const diff = validBirthDate ? differenceInYears(parsedInBoundDate, birthDate) : null;
    if (!validBirthDate) {
      return message || INVALID_CHILD_BIRTH_DATE;
    }
    return diff >= 2 && diff <= 17 ? null : message || INVALID_CHILD_BIRTH_DATE;
  }, INVALID_CHILD_BIRTH_DATE);
};
export default isValidChildBirthDate;