import { useProperty, useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { Inline, Stack } from '@etg/wings';
import { ReactComponent as AppStore } from '@eti/assets/logos/store-badges/appstore-badge.svg';
import { ReactComponent as GooglePlayStore } from '@eti/assets/logos/store-badges/googleplay-badge.svg';

const linkStyle = css`
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
`;

const badgeStyle = css`
  height: 44px;
  width: auto;
`;

const linksWrapper = css`
  padding-inline-start: 8px;
`;

const ReceiptMobileAppBannerLinks = () => {
  const { p } = useProperty();
  const { t } = useTranslation();

  const appStoreAriaLabel = t('ScreenReader.Order.DownloadApp.AppStoreAriaLabel');
  const playStoreAriaLabel = t('ScreenReader.Order.DownloadApp.PlayStoreAriaLabel');

  const appStoreHref = p('Order.DownloadApp.AppStoreLink');
  const playStoreHref = p('Order.DownloadApp.PlayStoreLink');

  return (
    <Stack data-testid="orderPage-mobileApp-banner-links" spacing={32}>
      <Inline>{t('Order.DownloadApp.Text')}</Inline>
      <Inline className={linksWrapper} spacing={32}>
        <a
          aria-label={appStoreAriaLabel}
          className={linkStyle}
          data-testid="orderPage-mobileApp-banner-appStore-button"
          href={appStoreHref}
        >
          <AppStore className={badgeStyle} />
        </a>
        <a
          aria-label={playStoreAriaLabel}
          className={linkStyle}
          data-testid="orderPage-mobileApp-banner-googlePlay-button"
          href={playStoreHref}
        >
          <GooglePlayStore className={badgeStyle} />
        </a>
      </Inline>
    </Stack>
  );
};

export default ReceiptMobileAppBannerLinks;
