import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import Media from 'react-media';
import { breakpoints } from '@etg/wings';
import { useFetchSearchLocations } from '../utils/useFetchSearchLocations';
import { getNoOptionsMessage, ValueContainer } from '../utils/LocationDropdown';
import Control from './dropdown/Control';
import NoOptionsMessage from './dropdown/NoOptionsMessage';
import Menu from './dropdown/Menu';
import MenuList from './dropdown/MenuList';
import Option from './dropdown/Option';
import { getStyles } from './styles/locationDropdown';
import { Custom } from './CustomComponents';
import LocationDropdownOption from './LocationDropdownOption';

const getValue = (value) =>
  value && Object.keys(value).length > 0 && value.iataCode
    ? { value: value.iataCode, label: value.name }
    : null;

const localCustomStyles = css`
  padding: 0 0.5rem;
`;

const CustomControlComponent = ({ children, selectProps, ...rest }) => (
  <Control
    className={localCustomStyles}
    isError={selectProps?.controlProps?.isError}
    selectProps={selectProps}
    {...rest}
  >
    {children}
  </Control>
);

CustomControlComponent.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.any,
};

const LocationDropdown = ({
  customStyles,
  isError,
  id,
  innerRef,
  isRequired,
  name,
  onBlur,
  onChange,
  onSwitchDirection,
  placeholder,
  value,
}) => {
  const { t } = useTranslation();
  const { getSearchLocations } = useFetchSearchLocations();

  const fetchSearchLocations = async (searchTerm) => {
    const searchLocations = await getSearchLocations(searchTerm);

    return searchLocations.map((searchLocation) => ({
      label: (
        <LocationDropdownOption
          allAirportsText={t('Air.Search.AllAirports')}
          hasMultipleAirports={searchLocation.multipleAirports}
          key={searchLocation.iataCode}
          searchLocation={searchLocation}
        />
      ),
      value: searchLocation.iataCode,
    }));
  };

  const handleBlur = () => {
    if (isFunction(onBlur)) {
      onBlur();
    }
  };

  const handleChange = (option, { action }) => {
    const selectedOption = option ? option.label.props.searchLocation : null;

    if (action === 'clear') {
      if (innerRef) {
        setTimeout(() => {
          innerRef.current.focus();
        }, 1);
      }
    }

    if (isFunction(onChange)) {
      onChange(selectedOption, action);
    }
  };

  return (
    <Media query={`(min-width: ${breakpoints._768})`}>
      {(matches) => (
        <AsyncSelect
          aria-label={placeholder}
          blurInputOnSelect
          components={{
            DropdownIndicator: (dropdownIndicatorProps) =>
              !dropdownIndicatorProps.isFocused && !matches && /.origin$/.test(name)
                ? Custom.SwitchDirectionIndicator({ onSwitchDirection, ...dropdownIndicatorProps })
                : null,
            IndicatorSeparator: () => null,
            ClearIndicator: (clearIndicatorProps) =>
              clearIndicatorProps.isFocused && clearIndicatorProps.hasValue
                ? Custom.ClearIndicator(clearIndicatorProps)
                : null,
            ValueContainer: ValueContainer(name),
            Control: CustomControlComponent,
            Menu,
            MenuList,
            Option,
            NoOptionsMessage,
          }}
          controlProps={{ isError }}
          inputId={id}
          isClearable
          loadOptions={fetchSearchLocations}
          name={name}
          noOptionsMessage={getNoOptionsMessage(
            t('Air.Search.CitiesSelection.SearchHint'),
            t('Air.Search.CitiesSelection.NoSearchResults'),
          )}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          ref={innerRef}
          styles={getStyles(isError, isRequired, customStyles)}
          value={getValue(value)}
        />
      )}
    </Media>
  );
};

LocationDropdown.propTypes = {
  customStyles: PropTypes.shape({
    control: PropTypes.objectOf(PropTypes.any),
  }),
  id: PropTypes.string,
  innerRef: PropTypes.objectOf(PropTypes.any),
  isError: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSwitchDirection: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
};

export default LocationDropdown;
