export const validateCabinUpgradeAvailabilityTexts = availabilityTexts => {
  if (!availabilityTexts || Object.keys(availabilityTexts).length === 0) {
    return false;
  }
  const {
    availabilityCard
  } = availabilityTexts;
  if (!availabilityCard || Object.keys(availabilityCard).length === 0) {
    return false;
  }
  const {
    availabilityTitle,
    availableStatus,
    statusTooltip,
    unavailableStatus
  } = availabilityCard;
  return Boolean(availabilityTitle && availableStatus && unavailableStatus && statusTooltip);
};