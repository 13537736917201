import getIcon from '../common/utils/icon';
import { getPrice, getSelected, getProvince, getTravelers } from '../common/utils/selection';
import * as constants from '../common/utils/constants';
import getGraphQlSelection, { getDBFGraphQlSelectionManuLife } from '../common/utils/getGraphQlSelection';
import { TRIP_CANCELLATION_PROTECTION } from '../../products';
import TripCancellationProtection from './components/TripCancellationProtection';
const {
  name: formSection,
  id
} = TRIP_CANCELLATION_PROTECTION;
export const tripCancellationProtection = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(String(id), formSection),
  getDBFGraphQlSelection: getDBFGraphQlSelectionManuLife(String(id), formSection),
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getProvince: getProvince.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getTravelers: getTravelers.bind(null, formSection),
  ...TRIP_CANCELLATION_PROTECTION,
  ...constants
};
export default TripCancellationProtection;