import type { ChangeEvent } from 'react';
import { useTranslation } from '@eti/providers';
import { passengerFields } from '../../constants/formFields';
import type { Passengers, PassengerConstraints } from '../../models';
import { getChildrenToggleConstraints, isValidChildSelection } from '../../utils/passengers';
import PassengerRow from './PassengerRow';

interface ChildPassengersProps {
  constraints?: PassengerConstraints;
  selectedPassengers: Passengers;
}

const ChildPassengers = ({ constraints, selectedPassengers }: ChildPassengersProps) => {
  const { t } = useTranslation();
  const toggleConstraints = getChildrenToggleConstraints(selectedPassengers, constraints);

  const onChange = (event: ChangeEvent<HTMLButtonElement>, newValue: number) => {
    const changeIsValid = isValidChildSelection(newValue, selectedPassengers, constraints);

    if (!changeIsValid) {
      event.preventDefault();
    }
  };

  return (
    <PassengerRow
      label={t('Air.Search.Children')}
      name={passengerFields.CHILDREN}
      onChange={onChange}
      toggleConstraints={toggleConstraints}
    />
  );
};

export default ChildPassengers;
