import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSiteContext } from '@eti/providers';
import type { DataField } from '../contentful/ContentfulTypes';
import { ALL_BRANDS, ALL_MARKETS } from '../contentful/constants/contentfulConstants';

export const useContentful = () => {
  const [content, setContent] = useState<DataField[]>();
  const [loading, setLoading] = useState(true);
  const { slug, parent } = useParams<{ slug: string; parent: string }>();
  const { brand, market, language } = useSiteContext();

  const path = parent ? `${parent}/${slug}` : slug;
  const { locale } = language;

  useEffect(() => {
    fetch(
      `/cms/api/collections/v1/${market.code}/${brand.code.toUpperCase()}/${locale.replace(
        '_',
        '-',
      )}/${path}`,
      { method: 'GET' },
    )
      .then((response) => response.json())
      .then(({ data }) => {
        if (data) {
          const globalPage = data?.globalCollection?.items[0];
          if (!globalPage) {
            window.location.href = '/404';
          }
          if (!content) {
            const contents = globalPage?.contentCollection?.items.filter((item: any) => {
              if (
                (item?.contentOwner?.brand?.includes(brand.code.toUpperCase()) ||
                  item?.contentOwner?.brand?.includes(ALL_BRANDS)) &&
                (item?.contentOwner?.market?.includes(market.code) ||
                  item?.contentOwner?.market?.includes(ALL_MARKETS))
              ) {
                return item;
              }
              return null;
            });
            setContent(contents);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        throw new Error(`[Network error]: ${error}`);
      });
  }, [content, loading, market.code, brand.code, locale, path]);

  return { content, loading };
};
