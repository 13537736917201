import { useState, useRef, Fragment } from 'react';
import { observeClickOutside } from 'react-ref-observe';
import Media from 'react-media';
import { vars, Inline, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import type { SubMenuItem } from '@eti/schema-types';
import { populateListWithId } from '../../../utils/idGenerator';
import { useCloseOnEsc } from '../../../utils/useCloseOnEsc';
import { useCloseOnFocusOut } from '../../../utils/useCloseOnFocusOut';
import { useNavigateOnArrowKeys } from '../../../utils/useNavigateOnArrowKeys';
import DropdownMenu from './DropdownMenu';

const listItemStyles = css`
  position: relative;
`;

const buttonStyles = css`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  color: ${vars.colors.header.content};
  cursor: pointer;
  font-size: 0.875rem;
  padding: 8px;

  &:hover {
    background-color: ${vars.colors.header.hover};
    transition: 0.3s;
  }

  @media print {
    display: none;
  }
`;

const linkStyles = css`
  border-radius: 3px;
  color: ${vars.colors.header.content};
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  padding: 0;
  text-decoration: none;

  &:hover {
    background-color: ${vars.colors.header.hover};
    transition: 0.3s;
  }

  @media (min-width: ${breakpoints._768}) {
    padding: 8px;
  }

  @media print {
    display: none;
  }
`;

interface MenuProps {
  menu: {
    id: string;
    name: string;
    path: string;
    subMenu: SubMenuItem[];
  }[];
}

const DesktopMenu = ({ menu }: MenuProps) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLUListElement | null>(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<string | null>(null);

  const closeSubMenu = () => {
    if (isSubMenuOpen) {
      setIsSubMenuOpen(null);
    }
  };

  const toggleSubMenu = (id: string) => {
    if (isSubMenuOpen === null) {
      setIsSubMenuOpen(id);
    } else {
      closeSubMenu();
    }
  };

  observeClickOutside([dropdownRef], closeSubMenu);
  useCloseOnEsc(containerRef, closeSubMenu, buttonRef);
  useCloseOnFocusOut(dropdownRef, closeSubMenu);
  const { handleKeyDown } = useNavigateOnArrowKeys({
    buttonRef,
    containerRef,
    isSubMenuOpen: Boolean(isSubMenuOpen),
  });

  return (
    <Inline as="nav">
      <Inline
        alignY="center"
        as="ul"
        data-testid="desktop-menu"
        noWrap
        spacing={{ [breakpoints._0]: 32, [breakpoints._768]: 16 }}
      >
        <Media query={`(min-width: ${breakpoints._768})`}>
          {(matches) => (
            <>
              {menu.map(({ id, name, subMenu, path }) => (
                <Fragment key={id}>
                  {subMenu?.length > 0 ? (
                    <>
                      {matches && (
                        <li className={listItemStyles} ref={dropdownRef}>
                          <button
                            aria-controls={`dropdown-menu-${id}`}
                            aria-expanded={isSubMenuOpen === id}
                            className={buttonStyles}
                            data-testid={`menu-button-${name}`}
                            onClick={() => toggleSubMenu(id)}
                            onKeyDown={handleKeyDown}
                            ref={buttonRef}
                            type="button"
                          >
                            {name}
                          </button>
                          {isSubMenuOpen === id && (
                            <DropdownMenu
                              id={`dropdown-menu-${id}`}
                              menu={populateListWithId(subMenu)}
                              onKeyDown={handleKeyDown}
                              ref={containerRef}
                            />
                          )}
                        </li>
                      )}
                    </>
                  ) : (
                    <li>
                      <a className={linkStyles} data-testid={`menu-link-${name}`} href={path}>
                        {name}
                      </a>
                    </li>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </Media>
      </Inline>
    </Inline>
  );
};

export default DesktopMenu;
