import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, Heading, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { useProperty, useTranslation } from '@eti/providers';
import { routes } from '../../../constants/routesAndApisConstants';
import SocialMediaLoginContainer from './SocialMediaLoginContainer';
import LoginFormContainer from './LoginFormContainer';
import LoginMessage from './LoginMessage';
import { SourceOfLogin } from './models';

const notificationStyles = css`
  margin: 0;
`;

const MyBookingsModal = ({ onDismiss, isLoginModalOpen }) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const history = useHistory();
  const [notification, setNotification] = useState(null);

  const shouldShowGoogleButton = p('IbeClient.MyBookings.Google.Button.Enabled');
  const shouldShowFacebookButton = p('IbeClient.MyBookings.Facebook.Button.Enabled');
  const shouldShowSocialMediaLoginContainer = shouldShowGoogleButton || shouldShowFacebookButton;

  const handleDismiss = useCallback(() => {
    setNotification(null);
    onDismiss();
  }, [onDismiss]);

  const handleOrderInfoLogin = () => {
    handleDismiss();
    history.push(routes.ORDER_LIST);
  };

  const handleSocialLogin = useCallback(() => {
    handleDismiss();

    history.push(routes.ORDER_LIST);
  }, [handleDismiss, history]);

  return (
    <Dialog
      closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
      data-testid="myBookings-login-modal"
      isOpen={isLoginModalOpen}
      onDismiss={handleDismiss}
      title={t('Postbooking.Login.Header.Title')}
    >
      <Stack>
        <Stack>
          <Heading level={3}>{t('Postbooking.Login.Headline')}</Heading>
          {Boolean(notification) && (
            <LoginMessage className={notificationStyles} notification={notification} />
          )}
        </Stack>
        <Stack spacing={8}>
          <LoginFormContainer
            handleLoginSuccess={handleOrderInfoLogin}
            setNotification={setNotification}
            source={SourceOfLogin.MODAL}
          />
          {shouldShowSocialMediaLoginContainer && (
            <SocialMediaLoginContainer
              callback={handleSocialLogin}
              setNotification={setNotification}
              shouldShowFacebookButton={shouldShowFacebookButton}
              shouldShowGoogleButton={shouldShowGoogleButton}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};

MyBookingsModal.propTypes = {
  isLoginModalOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default MyBookingsModal;
