import {
  type FieldPath,
  type FieldValues,
  useController,
  type UseControllerProps,
  type UseControllerReturn,
  useFormContext,
} from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import type { ExtendedControl } from './useFormWithModeExposed';

type UseControllerWithWarningsReturn<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<UseControllerReturn<TFieldValues, TName>, 'fieldState'> & {
  fieldState: UseControllerReturn<TFieldValues, TName>['fieldState'] & { warning?: string };
};

export const useControllerWithWarnings = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName> & {
    warningValidation?: (value: string) => boolean | string;
  },
): UseControllerWithWarningsReturn<TFieldValues, TName> => {
  const { field, fieldState, formState, ...rest } = useController(props);
  const { control } = useFormContext();
  const { mode } = control as ExtendedControl;
  const { warningValidation } = props;

  const [warning, setWarning] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (warningValidation && !mode) {
      throw new Error(
        'To use warning rules you need to use useFormWithModeExposed to initialize your form.',
      );
    }
  }, [warningValidation, mode]);

  const validateAndSetWarnings = useCallback(
    (value?: string) => {
      if (!warningValidation) {
        return;
      }

      const result = warningValidation(value || field.value);
      setWarning(typeof result === 'string' ? result : undefined);
    },
    [field.value, warningValidation],
  );

  const handleWarningsOnChange = useCallback(
    (e: any) => {
      field.onChange(e);

      if (!warningValidation) {
        return;
      }

      // For onChange mode, validate whenever the value changes
      if (mode === 'onChange' || mode === 'all') {
        validateAndSetWarnings(e);
      }

      // For onTouched mode, validate on change only if the field was touched before
      if ((mode === 'onTouched' || mode === 'onSubmit') && fieldState.isTouched) {
        validateAndSetWarnings(e);
      }
    },
    [field, fieldState.isTouched, mode, validateAndSetWarnings, warningValidation],
  );

  const handleWarningsOnBlur = useCallback(() => {
    field.onBlur();

    if (!warningValidation) {
      return;
    }

    // For onBlur mode, always validate on blur
    if (mode === 'onBlur' || mode === 'all') {
      validateAndSetWarnings();
    }
  }, [field, mode, validateAndSetWarnings, warningValidation]);

  useEffect(() => {
    if (!warningValidation) {
      return;
    }

    if (formState.isSubmitted && (mode === 'onSubmit' || mode === 'all')) {
      validateAndSetWarnings();
    }
  }, [formState.isSubmitted, mode, validateAndSetWarnings, warningValidation]);

  useEffect(() => {
    if (!formState.isDirty && !formState.isSubmitted) {
      setWarning(undefined);
    }
  }, [formState.isDirty, formState.isSubmitted]);

  return {
    field: {
      ...field,
      onChange: handleWarningsOnChange,
      onBlur: handleWarningsOnBlur,
    },
    fieldState: {
      ...fieldState,
      warning,
    },
    formState,
    ...rest,
  };
};
