import { breakpoints, PageBlock } from '@etg/wings';
import { useProperty, useTranslation } from '@eti/providers';
import { css, etiColors } from '@eti/styles';
import { device } from '@eti/utils';
import { Helmet } from 'react-helmet';
import Loading from '../../../common/loading/components/Loading';
import ReceiptMobileAppBanner from '../../receipt/components/ReceiptMobileAppBanner';

const blockStyles = css`
  align-items: center;
  height: 100%;
`;

const mobileAppBannerStyle = css`
  border-bottom: 1px dotted ${etiColors.grayScale[8]};
  margin: 0 auto;
  padding: 16px 10px;

  @media (min-width: ${breakpoints._768}) {
    max-width: 750px;
    padding: 16px 15px;
  }

  @media (min-width: ${breakpoints._970}) {
    max-width: 970px;
  }

  @media (min-width: ${breakpoints._1024}) {
    max-width: none;
  }
`;

const MobileAppDownloadPage = () => {
  const { p } = useProperty();
  const { t } = useTranslation();

  const appStoreLink = p('Order.DownloadApp.AppStoreLink');
  const playStoreLink = p('Order.DownloadApp.PlayStoreLink');
  const isMobileDevice = device.openAppStoreLink(appStoreLink, playStoreLink);

  return (
    <>
      <Helmet title={t('PageTitle.rf.mobile-app-download.action')} />
      {isMobileDevice ? (
        <Loading />
      ) : (
        <PageBlock className={blockStyles} withPaddingY>
          <ReceiptMobileAppBanner className={mobileAppBannerStyle} />
        </PageBlock>
      )}
    </>
  );
};

export default MobileAppDownloadPage;
