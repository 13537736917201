import { useEffect } from 'react';
import { useProperty } from '@eti/providers';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line camelcase
declare const window: Window & { spa_variables: any };

const useJoyned = (): void => {
  const { p } = useProperty();
  const { pathname } = useLocation();

  const isEnabled: boolean = p('IbeClient.Joyned.Enabled');
  const scriptSrc: string = p('IbeClient.Joyned.ScriptSrc');
  const excludedPages: string[] = p('IbeClient.Joyned.ExcludedPages');
  const isExcludedPage: boolean = excludedPages?.includes(
    Object.keys(window.spa_variables.routes)[
      Object.values(window.spa_variables.routes).indexOf(pathname)
    ],
  );

  useEffect(() => {
    const toggleJoyned = (): void => {
      const scriptsToBeRemoved =
        document.querySelectorAll<HTMLScriptElement>('script#joyned-script');
      const shouldNotExist = !isEnabled || isExcludedPage;
      const shouldBeRemoved = scriptsToBeRemoved.length > 0;

      if (shouldNotExist && shouldBeRemoved) {
        scriptsToBeRemoved.forEach((element) => element.remove());
        return;
      }

      if (shouldNotExist) {
        return;
      }

      const script: HTMLScriptElement = document.createElement('script');
      script.id = 'joyned-script';
      script.src = scriptSrc;
      script.defer = true;
      script.crossOrigin = 'anonymous';

      document.head.appendChild(script);
    };

    toggleJoyned();
  }, [isEnabled, isExcludedPage, scriptSrc]);
};

export { useJoyned };
