import { css, mediaQueries } from '@eti/styles';

export const getPartnerSpecificStylesFor = (id: string) => {
  switch (id) {
    // Payment providers -------------------
    case 'applepay':
      return css`
        height: 40px;
        width: 63px;
      `;
    case 'alipay':
      return css`
        height: 26px;
        width: auto;
      `;
    case 'amex':
      return css`
        height: 44px;
        width: auto;
      `;
    case 'Bancontact':
      return css`
        height: 42px;
        width: 61px;
      `;
    case 'eurocard':
      return css`
        height: 28px;
        width: 71px;
      `;
    case 'ideal':
      return css`
        height: 40px;
        width: 45px;
      `;
    case 'paypal':
      return css`
        height: 27px;
        width: auto;
      `;
    case 'sofort':
      return css`
        height: 30px;
        width: auto;
      `;
    case 'trustly':
      return css`
        height: 24px;
        width: auto;
      `;
    case 'visa':
    case 'mir':
    case 'hyundai':
    case 'kb':
    case 'shinhan':
    case 'woori':
      return css`
        height: 22px;
        width: auto;
      `;
    case 'rupay':
    case 'bc':
    case 'kebhana':
      return css`
        height: 25px;
        width: auto;
      `;
    case 'samsung':
    case 'lotte':
    case 'nh':
      return css`
        height: 18px;
        width: auto;
      `;
    // Trusted Partners --------------------
    case 'aaa':
      return css`
        height: 26px;
        width: 62px;
      `;
    case 'amadeus':
      return css`
        height: 13px;
        width: 93px;
      `;
    case 'ecpat':
      return css`
        height: 49px;
        width: 34px;
      `;
    case 'iata':
      return css`
        height: 31px;
        width: 49px;
      `;
    case 'pix':
      return css`
        height: 32px;
        width: 91px;
      `;
    case 'sabre':
      return css`
        height: 18px;
        width: 74px;
      `;
    case 'srf':
      return css`
        height: 39px;
        width: 37px;
      `;
    case 'tch':
      return css`
        height: 28px;
        width: auto;
      `;
    case 'tripadvisor':
      return css`
        height: 17px;
        width: 100px;
      `;
    case 'cadastur':
      return css`
        height: 22px;
        width: 138px;

        @media ${mediaQueries.small.only} {
          width: 100%;
        }
      `;

    default:
      return css`
        height: 32px;
        width: auto;
      `;
  }
};
