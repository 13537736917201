import getIcon from '../common/utils/icon';
import { getPrice, getSelected, getProvince, getTravelers } from '../common/utils/selection';
import * as constants from '../common/utils/constants';
import getGraphQlSelection, { getDBFGraphQlSelectionManuLife } from '../common/utils/getGraphQlSelection';
import { ALL_INCLUSIVE_PROTECTION } from '../../products';
import AllInclusiveProtection from './components/AllInclusiveProtection';
const {
  name: formSection,
  id
} = ALL_INCLUSIVE_PROTECTION;
export const allInclusiveProtection = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(String(id), formSection),
  getDBFGraphQlSelection: getDBFGraphQlSelectionManuLife(String(id), formSection),
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getProvince: getProvince.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getTravelers: getTravelers.bind(null, formSection),
  ...ALL_INCLUSIVE_PROTECTION,
  ...constants
};
export default AllInclusiveProtection;