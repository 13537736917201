import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { CANCEL_24_HOURS } from '../products';
import CancelTwentyFourHours from './components/CancelTwentyFourHours';
const formSection = CANCEL_24_HOURS.name;
export const cancelTwentyFourHours = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'traveler'),
  ...CANCEL_24_HOURS
};
export default CancelTwentyFourHours;