import { useReducer } from 'react';
import { breakpoints, Heading, Inline, Stack, vars } from '@etg/wings';
import { ExpandableContent } from '@eti/components';
import type { MainMenuItem } from '@eti/schema-types';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { CaretDown } from '@phosphor-icons/react';

const linkStackStyles = css`
  margin-bottom: 1rem;
`;

const headingStyles = css`
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0;
  -webkit-tap-highlight-color: transparent;
  width: 100%;

  &:focus-visible {
    outline: 0.125rem solid ${vars.colors.footer.link};
    outline-offset: 0.0625rem;
  }

  @media (min-width: ${breakpoints._970}) {
    margin: 0;
  }
`;

const iconStyles = (isOpen: boolean) => css`
  flex-shrink: 0;
  transform: rotateX(${isOpen ? '180deg' : '0deg'});
  transition: transform 0.15s ease-in-out;
`;

const wrapperStyles = css`
  border-bottom: 0.0625rem solid ${vars.colors.divider};
`;

interface ToggleableFooterMenuProps {
  'data-testid': string;
  menu: MainMenuItem[];
  title: string;
}

const ToggleableFooterMenu = ({
  'data-testid': dataTestId,
  menu,
  title,
}: ToggleableFooterMenuProps) => {
  const [isOpened, toggleOpened] = useReducer((previous) => !previous, false);

  return (
    <div className={wrapperStyles}>
      <Inline
        alignY="center"
        aria-expanded={isOpened}
        as="button"
        className={cx(resetButtonStyle, headingStyles)}
        data-testid={`expandHeader-${dataTestId}`}
        onClick={toggleOpened}
      >
        <Heading as="h2" level={4}>
          {title}
        </Heading>
        <CaretDown
          aria-hidden
          className={iconStyles(isOpened)}
          data-testid="expandHeader-Icon"
          size="1.375rem"
          weight="light"
        />
      </Inline>
      <ExpandableContent data-testid={dataTestId} isExpanded={isOpened} shouldUnmountOnExit={false}>
        <Stack as="ul" className={linkStackStyles} data-testid={`expandContentStack-${title}`}>
          {menu.map((mainMenuItem) =>
            mainMenuItem.path ? (
              <li key={`linkbutton-${mainMenuItem.name}`}>
                <a
                  data-testid={`expandLink-${mainMenuItem.path.slice(1)}`}
                  href={mainMenuItem.path}
                >
                  {mainMenuItem.name}
                </a>
              </li>
            ) : null,
          )}
        </Stack>
      </ExpandableContent>
    </div>
  );
};

export default ToggleableFooterMenu;
