import { constants, isPartner } from '@eti/utils';
import type { Brand } from '@eti/schema-types';

const { brands } = constants;
const partner = 'skyscanner';
const gotogate: Brand = { id: 9, code: 'GOTOGATE', name: 'GoToGate/FB' };

export type Theme = {
  brand: Brand;
  partner: string;
};
type ThemeRecord = Record<string, Theme>;

export const whitelabels: ThemeRecord = Object.values(brands).reduce((acc, brandId) => {
  if (isPartner(brandId)) {
    return { ...acc, [brandId]: { brand: gotogate, partner: brandId } };
  }
  return acc;
}, {});

export const themes: ThemeRecord = {
  [brands.ADR]: { brand: { id: 101, code: 'ADR', name: 'Aeroporti di Roma' }, partner },
  [brands.JOURNEYDETAILS]: {
    brand: { id: 104, code: 'JOURNEYDETAILS', name: 'JourneyDetails' },
    partner,
  },
  [brands.AZULMUNDO]: { brand: { id: 103, code: 'AZULMUNDO', name: 'Azul Mundo' }, partner },
  [brands.BUSINESSCLASS]: {
    brand: { id: 28, code: 'BUSINESSCLASS', name: 'BusinessClass' },
    partner,
  },
  [brands.FLIGHTNETWORK]: {
    brand: { id: 26, code: 'FLIGHTNETWORK', name: 'FlightNetwork' },
    partner,
  },
  [brands.GOTOGATE]: { brand: gotogate, partner },
  [brands.MYTRIP]: { brand: { id: 18, code: 'MYTRIP', name: 'MyTrip' }, partner },
  [brands.NOGKO]: { brand: { id: 29, code: 'NOKGO', name: 'NokAir' }, partner },
  [brands.RADISSONHOTELS]: {
    brand: { id: 102, code: 'RADISSONHOTELS', name: 'Radisson Hotels' },
    partner,
  },
  [brands.SEAT24]: { brand: { id: 8, code: 'SEAT24', name: 'Seat24' }, partner },
  [brands.SUPERSAVER]: { brand: { id: 1, code: 'SUPERSAVER', name: 'Supersaver' }, partner },
  [brands.TRAVELFINDER]: { brand: { id: 6, code: 'TRAVELFINDER', name: 'TravelFinder' }, partner },
  [brands.TRAVELSTART]: { brand: { id: 10, code: 'TRAVELSTART', name: 'TravelStart' }, partner },
  [brands.TUI]: { brand: { id: 27, code: 'TUI', name: 'Tui' }, partner },
  ...whitelabels,
};
