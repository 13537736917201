import { Button, Dialog, Stack, breakpoints, vars } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { CaretDown } from '@phosphor-icons/react';
import type { MutableRefObject, ReactNode } from 'react';
import { forwardRef, useEffect, useReducer, useRef } from 'react';
import FocusTrap from 'react-focus-lock';
import Media from 'react-media';
import { observeClickOutside } from 'react-ref-observe';

const wrapperStyles = css`
  display: flex;
  flex: 1;
  position: relative;

  &::before {
    border: 3px solid ${vars.colors.inputs.focus};
    border-radius: 6px;
    content: '';
    height: calc(100% + 6px);
    left: -3px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -3px;
    transition: opacity 0.2s ease-in-out;
    width: calc(100% + 6px);
  }

  &:focus-within {
    z-index: 1;
  }

  &:focus-within::before {
    opacity: 1;
  }
`;

const triggerStyles = css`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-color: ${vars.colors.inputs.border};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: flex;
  font-family: inherit;
  font-size: 1rem;
  height: 3rem;
  justify-content: space-between;
  outline: none;
  padding-inline: 12px 10px;
  text-align: start;
  width: 100%;

  &[disabled] {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:focus {
    border-color: ${vars.colors.inputs.main};
    padding-inline-end: 11px;
  }

  [data-inputgroup] & {
    border-radius: 0;
  }

  [data-inputgroup] *:not(:last-child) & {
    border-inline-end: none;
  }

  [data-inputgroup] &:focus {
    border-inline-end: 1px solid ${vars.colors.inputs.main};
    border-radius: 3px;
  }

  [data-inputgroup] > *:first-child & {
    border-end-start-radius: 3px;
    border-start-start-radius: 3px;
  }

  [data-inputgroup] > *:last-child & {
    border-end-end-radius: 3px;
    border-start-end-radius: 3px;
  }

  [data-inputgroup] *:not(:last-child) &:focus {
    padding-inline-end: 11px;
  }
`;

const dialogStyles = css`
  & > * > :nth-child(2) {
    min-height: 390px;
  }

  /* ensures the footer ”confirm” button sticks to the bottom of the dialog. */
  & > * > :last-child {
    margin-top: auto;
  }
`;

const desktopListWrapper = css`
  background-color: #fff;
  border: 1px solid ${vars.colors.inputs.border};
  border-radius: 3px;
  inset-inline: 0;
  max-height: 340px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;
`;

const labelStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const getCaretWrapperStyles = (isOpened: boolean) => css`
  display: flex;
  margin-inline-start: 8px;
  position: relative;

  &::before {
    background-color: ${vars.colors.inputs.tint};
    border-radius: 3px;
    content: '';
    height: calc(100% + 1px);
    opacity: ${isOpened ? '1' : '0'};
    position: absolute;
    top: -1px;
    transition: opacity 0.4s ease-in-out;
    width: 100%;
  }
`;

const getCaretStyles = (isOpened: boolean) => css`
  flex-shrink: 0;
  transform: rotateX(${isOpened ? '180deg' : '0deg'});
  transition: transform 0.15s ease-in-out;
`;

interface DesktopOptionsWrapperProps {
  children: ReactNode;
  close: () => void;
  id: string;
  wrapperRef: MutableRefObject<HTMLElement | null>;
}

const DesktopOptionsWrapper = ({ children, close, id, wrapperRef }: DesktopOptionsWrapperProps) => {
  observeClickOutside([wrapperRef], close);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [close]);

  return (
    <div className={desktopListWrapper} id={id}>
      {children}
    </div>
  );
};

export interface PassengersToggleProps {
  buttonId?: string;
  children: ReactNode;
  childrenAgesLabel?: ReactNode;
  isDisabled?: boolean;
  label: ReactNode;
  labelledBy?: string;
  title: string;
}

const PassengersToggle = forwardRef<HTMLButtonElement, PassengersToggleProps>(
  function PassengersToggle(
    { buttonId, children, childrenAgesLabel, isDisabled, label, labelledBy, title },
    ref,
  ) {
    const { t } = useTranslation();
    const [opened, toggleOpened] = useReducer((previous) => !previous, false);
    const wrapperRef = useRef(null);
    const passengersSelectionId = 'searchform-passengers-selection';
    const formattedChildrenAgesLabel = childrenAgesLabel && `. ${childrenAgesLabel}`;

    return (
      <div ref={wrapperRef}>
        <div className={wrapperStyles}>
          <button
            aria-controls={passengersSelectionId}
            aria-expanded={opened}
            aria-label={`${title}: ${label}${formattedChildrenAgesLabel}`}
            aria-labelledby={labelledBy}
            aria-live="assertive"
            className={triggerStyles}
            data-testid="searchForm-passengers-dropdown"
            disabled={isDisabled}
            id={buttonId}
            onClick={toggleOpened}
            ref={ref}
            type="button"
          >
            <span aria-atomic="true" aria-hidden className={labelStyles}>
              {label}
            </span>
            <span className={getCaretWrapperStyles(opened)}>
              <CaretDown aria-hidden className={getCaretStyles(opened)} size={20} weight="light" />
            </span>
          </button>
        </div>
        <Media query={`(min-width: ${breakpoints._768})`}>
          {(matches) =>
            matches ? (
              opened && (
                <DesktopOptionsWrapper
                  close={toggleOpened}
                  id={passengersSelectionId}
                  wrapperRef={wrapperRef}
                >
                  <FocusTrap returnFocus>{children}</FocusTrap>
                </DesktopOptionsWrapper>
              )
            ) : (
              <Dialog
                className={dialogStyles}
                closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
                footer={
                  <Stack>
                    <Button
                      data-testid="passengers-confirm"
                      onClick={toggleOpened}
                      variant="primary"
                    >
                      {t('Searchform.Passengers.Dialog.Confirm.Label')}
                    </Button>
                  </Stack>
                }
                id={passengersSelectionId}
                isOpen={opened}
                onDismiss={toggleOpened}
                title={title}
              >
                {children}
              </Dialog>
            )
          }
        </Media>
      </div>
    );
  },
);

export default PassengersToggle;
