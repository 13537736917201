import createValidator from 'revalidate/lib/createValidator';
import { parse, differenceInYears, isValid } from 'date-fns';
import { date } from '@eti/utils';
import { INVALID_ADULT_BIRTH_DATE } from '../constants/defaultErrorMessages';
const isValidAdultBirthDate = function (inBoundDate, format, adultMinimumAge) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  if (adultMinimumAge === void 0) {
    adultMinimumAge = 18;
  }
  return createValidator(message => input => {
    if (!input) {
      return null;
    }
    const birthDate = parse(input, format, new Date());
    if (!isValid(birthDate)) {
      return message || INVALID_ADULT_BIRTH_DATE;
    }
    const parsedInBoundDate = date.convertToDate(inBoundDate);
    if (!isValid(parsedInBoundDate)) {
      return null;
    }
    const age = differenceInYears(parsedInBoundDate, birthDate);
    return age >= adultMinimumAge ? null : message || INVALID_ADULT_BIRTH_DATE;
  }, INVALID_ADULT_BIRTH_DATE);
};
export default isValidAdultBirthDate;