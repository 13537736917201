import { vars } from '@etg/wings';
import { css } from '@eti/styles';
import variants from '../../utils/variants';
const notificationVariants = {
  [variants.INFORMATION]: css`
    background-color: ${vars.colors.signal.information.tint};
    border: 1px solid ${vars.colors.signal.information.light};
  `,
  [variants.CONFIRMATION]: css`
    background-color: ${vars.colors.signal.positive.tint};
    border: 1px solid ${vars.colors.signal.positive.light};
  `,
  [variants.WARNING]: css`
    background: ${vars.colors.signal.caution.tint};
    border: 1px solid ${vars.colors.signal.caution.light};
  `,
  [variants.CRITICAL]: css`
    background: ${vars.colors.signal.critical.tint};
    border: 1px solid ${vars.colors.signal.critical.light};
  `
};
export const variantStyles = function (variant) {
  if (variant === void 0) {
    variant = 'info';
  }
  return notificationVariants[variant];
};