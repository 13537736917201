import { useEffect } from 'react';
import { useElementInViewport } from '../../../custom-hooks/useElementInViewport';
import { useProductSnowplowTracking } from '../../../custom-hooks/useProductSnowplowTracking';
const BAGGAGE_STATUS = {
  AVAILABLE: 'Available',
  INCLUDED: 'Included',
  NOT_INCLUDED: 'Not Included'
};
const useBaggageVisibilityTracker = _ref => {
  let {
    isBaggageAvailableOrIncluded,
    includedBaggageInTrip,
    productName,
    productPosition,
    rootElement
  } = _ref;
  const {
    sendProductSeenEvent
  } = useProductSnowplowTracking(productName, {
    productPosition,
    rootElement,
    sendSeenEvent: false
  });
  const isFullyVisible = useElementInViewport({
    elementRef: rootElement
  });
  const availableForPurchase = !includedBaggageInTrip && isBaggageAvailableOrIncluded;
  const alreadyIncluded = includedBaggageInTrip;
  const notAvailableForPurchase = !isBaggageAvailableOrIncluded && !includedBaggageInTrip;
  useEffect(() => {
    if (isFullyVisible) {
      let availability = '';
      if (availableForPurchase) {
        availability = BAGGAGE_STATUS.AVAILABLE;
      } else if (alreadyIncluded) {
        availability = BAGGAGE_STATUS.INCLUDED;
      } else if (notAvailableForPurchase) {
        availability = BAGGAGE_STATUS.NOT_INCLUDED;
      }
      if (availability) {
        sendProductSeenEvent({
          availability
        });
      }
    }
  }, [isFullyVisible, sendProductSeenEvent, availableForPurchase, alreadyIncluded, notAvailableForPurchase]);
};
export default useBaggageVisibilityTracker;