import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { BAGGAGE_SERVICE } from '../products';
import BaggageService from './components/BaggageService';
import getIcon from './utils/icon';
const formSection = BAGGAGE_SERVICE.name;
export const baggageService = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'traveler'),
  ...BAGGAGE_SERVICE
};
export default BaggageService;