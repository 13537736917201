import { breakpoints, Button, Dialog, Inline, Stack, vars } from '@etg/wings';
import { ExpandableContent } from '@eti/components';
import { useTranslation } from '@eti/providers';
import type { TripType } from '@eti/schema-types';
import { css, cx, keyframes } from '@eti/styles';
import { useToggle } from '../../../common/custom-hooks/toggle';
import TripReminderHeader from './TripReminderHeader';
import TripContents from './TripReminderContents';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const wrapperStyles = css`
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in;
  background-color: #fff;
  border: 2px solid ${vars.colors.signal.information.light};
  border-radius: 3px;
  bottom: 6px;
  left: 50%;
  max-width: 468px;
  opacity: 1;
  position: fixed;
  transform: translateX(-50%);
  width: calc(100% - 12px);

  @media (min-width: ${breakpoints._768}) {
    bottom: 48px;
  }
`;

const actionButtonStyles = css`
  min-width: 140px;
`;

export type TripData = {
  date: { departure: Date | null; return: Date | null };
  destination: string;
  origin: string;
  totalPrice: string;
  travelers: string;
  type: TripType;
};

interface TripReminderProps {
  className?: string;
  onDiscardReminder: () => void;
  purchaseFlowStep: string;
  tripData: TripData;
}

const TripReminder = ({
  className,
  onDiscardReminder,
  purchaseFlowStep,
  tripData,
}: TripReminderProps) => {
  const { t } = useTranslation();
  const { isOpen, handleToggle } = useToggle(false);
  const { isOpen: isConfirmOverlayOpen, handleToggle: toggleConfirmOverlay } = useToggle(false);

  const handleDiscardReminder = () => {
    toggleConfirmOverlay();
    onDiscardReminder();
  };

  return (
    <div className={cx(wrapperStyles, className)}>
      <TripReminderHeader
        onDiscardReminder={toggleConfirmOverlay}
        onToggleTripContents={handleToggle}
      />
      <ExpandableContent isExpanded={isOpen}>
        <TripContents
          onToggleTripContents={handleToggle}
          purchaseFlowStep={purchaseFlowStep}
          tripData={tripData}
        />
      </ExpandableContent>
      <Dialog
        closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
        data-testid="tripReminder-confirmOverlay"
        isOpen={isConfirmOverlayOpen}
        onDismiss={toggleConfirmOverlay}
        title={t('TripReminder.ConfirmOverlay.Heading.Label')}
      >
        <Stack>
          {t('TripReminder.ConfirmOverlay.Message')}
          <Inline align="end" collapseBelow={breakpoints._560} spacing={8}>
            <Button
              className={actionButtonStyles}
              data-testid="tripReminder-discardOverlay-cancelButton"
              onClick={toggleConfirmOverlay}
            >
              {t('TripReminder.ConfirmOverlay.Buttons.Cancel')}
            </Button>
            <Button
              className={actionButtonStyles}
              data-testid="tripReminder-discardOverlay-discardButton"
              onClick={handleDiscardReminder}
              variant="primary"
            >
              {t('TripReminder.ConfirmOverlay.Buttons.Confirm')}
            </Button>
          </Inline>
        </Stack>
      </Dialog>
    </div>
  );
};

export default TripReminder;
