import { useEffect } from 'react';
import { breakpoints, Button, Card, Heading, Inline, PageBlock, Stack } from '@etg/wings';
import mobileBackground from '@eti/assets/images/panel/content/error_800.webp';
import tabletBackground from '@eti/assets/images/panel/content/error_1200.webp';
import desktopBackground from '@eti/assets/images/panel/content/error_1600.webp';
import { useProperty, useSiteContext, useSnowplowTracking, useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { analytics } from '@eti/utils';
import { getResponsivePageViewEvent } from '../../../common/data-layer/dataLayer';

const blockStyles = css`
  align-content: center;
  background-image: url('${mobileBackground}');
  background-size: cover;
  height: 100%;

  @media (min-width: ${breakpoints._560}) {
    background-image: url('${tabletBackground}');
  }

  @media (min-width: ${breakpoints._1024}) {
    background-image: url('${desktopBackground}');
  }
`;

const cardStyles = css`
  @media (min-width: ${breakpoints._768}) {
    max-width: 39.375rem;
  }
`;

const ErrorPage = () => {
  const { agencyAccount } = useSiteContext();
  const { p } = useProperty();
  const { t } = useTranslation();
  const { isSnowplowTrackingEnabled, sendTrackEvent } = useSnowplowTracking();

  analytics.useGADataLayerEffect(getResponsivePageViewEvent('/rf/error', 'Error', 'Error'));

  useEffect(() => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({ category: 'Error', action: 'Seen', label: 'Page' });
    }
  }, [isSnowplowTrackingEnabled, sendTrackEvent]);

  const errorPageHomeUrl = p('IBEClient.ErrorMessageLink.Url');

  return (
    <PageBlock className={blockStyles} withPaddingY>
      <Inline align="center">
        <Card
          className={cardStyles}
          padding={{ [breakpoints._0]: 16, [breakpoints._560]: 32, [breakpoints._1024]: 40 }}
        >
          <Stack align="center" spacing={24}>
            <Stack align="center" spacing={8}>
              <Heading level={1}>{t('StatusPage.Error.Title')}</Heading>
              <div>{t('StatusPage.Error.Message')}</div>
            </Stack>
            {errorPageHomeUrl && !agencyAccount && (
              <Inline collapseBelow={breakpoints._1024} spacing={16}>
                <Button as="a" href={errorPageHomeUrl}>
                  {t('StatusPage.LinkLabel.Home')}
                </Button>
              </Inline>
            )}
          </Stack>
        </Card>
      </Inline>
    </PageBlock>
  );
};

export default ErrorPage;
