import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const ConnectionGuaranteePage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="content/cookies"
      pageTitle={t('PageTitle.rf.connection-guarantee.action')}
      textKey="ConnectionGuarantee.HtmlContent"
    />
  );
};
export default ConnectionGuaranteePage;
