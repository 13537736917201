import { useState } from 'react';
import { useDidUpdateEffect } from '../../../custom-hooks/useDidUpdateEffect';
import { cleanText, getProductNameForTracking, getProductPrice, TRACKING_PROPERTIES, useProductSnowplowTracking } from '../../../custom-hooks/useProductSnowplowTracking';
const BAGGAGE_SELECTED_VALUE = {
  FALSE: 'false',
  TRUE: 'true',
  COMBINED: 'combined'
};
const useBaggageTracker = (productName, numberOfTravelers, totalBaggageQuantity, currentTravelerId, productPosition, shouldApplySelectionToAll, rootElement) => {
  const [selectedValue, setSelectedValue] = useState('false');
  const [previousBaggageQty, setPreviousBaggageQty] = useState(0);
  const {
    sendAddToCartEvent,
    sendSelectEvent,
    updateContext
  } = useProductSnowplowTracking(productName, {
    rootElement,
    productPosition,
    sendSeenEvent: false
  });
  useDidUpdateEffect(async () => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    setPreviousBaggageQty(totalBaggageQuantity);
    if (!isFirstTraveler || numberOfTravelers <= 1) {
      return;
    }
    if (isFirstTraveler && numberOfTravelers > 1) {
      if (selectedValue !== BAGGAGE_SELECTED_VALUE.FALSE) {
        const currentBaggagePrice = Number(cleanText(getProductPrice(rootElement, selectedValue === BAGGAGE_SELECTED_VALUE.COMBINED)));
        const totalBaggageQty = shouldApplySelectionToAll ? numberOfTravelers - previousBaggageQty : numberOfTravelers - 1;
        await updateContext({
          price: `${currentBaggagePrice * totalBaggageQty}`,
          quantity: totalBaggageQty
        });
        sendAddToCartEvent({
          addToCart: shouldApplySelectionToAll,
          value: 'Same baggage option'
        });
      } else {
        await updateContext({
          price: '0',
          quantity: null
        });
        sendSelectEvent({
          isSelected: shouldApplySelectionToAll,
          label: `Same ${getProductNameForTracking(productName)} option`
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplySelectionToAll]);
  const getUpdatedQuantity = () => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    if (shouldApplySelectionToAll && isFirstTraveler) {
      return numberOfTravelers;
    }
    return 1;
  };
  const getUpdatedPrice = selectionValue => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    const currentPrice = selectionValue === BAGGAGE_SELECTED_VALUE.COMBINED ? cleanText(getProductPrice(rootElement, true)) : cleanText(getProductPrice(rootElement));
    if (shouldApplySelectionToAll && isFirstTraveler) {
      return Number(currentPrice) * getUpdatedQuantity();
    }
    return Number(currentPrice);
  };
  const addToCartEventOnChange = async selectionValue => {
    const isSelected = selectionValue !== 'false';
    setSelectedValue(selectionValue);
    setPreviousBaggageQty(getUpdatedQuantity());
    await updateContext({
      price: `${getUpdatedPrice(selectionValue)}`,
      quantity: getUpdatedQuantity()
    });
    sendAddToCartEvent({
      addToCart: isSelected,
      ...(selectionValue === 'combined' && {
        value: 'Baggage x 2'
      })
    });
  };
  const addToCartModalEvent = async value => {
    setSelectedValue(value ? 'true' : 'false');
    setPreviousBaggageQty(getUpdatedQuantity());
    await updateContext({
      price: `${getUpdatedPrice(value ? 'true' : 'false')}`,
      quantity: getUpdatedQuantity()
    });
    sendAddToCartEvent({
      addToCart: value,
      property: TRACKING_PROPERTIES.VALIDATION_POP_UP
    });
  };
  return {
    addToCartEventOnChange,
    addToCartModalEvent
  };
};
export default useBaggageTracker;