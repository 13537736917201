import type { RefObject } from 'react';
import { useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
) => {
  useEffect(() => {
    const eventListener: Handler = (event) => {
      const element = ref?.current;

      if (!element || element.contains(event.target as HTMLElement)) {
        return;
      }

      handler(event);
    };

    window.addEventListener('click', eventListener, true);

    return () => {
      window.removeEventListener('click', eventListener);
    };
  }, [ref, handler]);
};
