import { SEATING_PREFERENCE_VALUES } from './constants';
const isEmpty = obj => Object.entries(obj).length <= 0;
const isSelected = obj => Object.values(obj).filter(val => val !== null && (val.value || val.seatPreference)).length > 0;
export const isSeatsSelectedForCurrentSegment = function (allSelectedSeatsValue, boundSegmentId) {
  if (allSelectedSeatsValue === void 0) {
    allSelectedSeatsValue = {};
  }
  if (boundSegmentId === void 0) {
    boundSegmentId = [];
  }
  if (boundSegmentId.length && !isEmpty(allSelectedSeatsValue)) {
    const selectedSegment = allSelectedSeatsValue[boundSegmentId[1]];
    if (Boolean(selectedSegment) && isSelected(selectedSegment)) {
      const traveler = selectedSegment;
      return traveler.seatPreference !== SEATING_PREFERENCE_VALUES.NO_OPTION;
    }
  }
  return false;
};
export const getTotalSelectedSeatsForTravelers = function (selectedSeats) {
  if (selectedSeats === void 0) {
    selectedSeats = {};
  }
  return Object.entries(selectedSeats).reduce((acc, _ref) => {
    let [key, val] = _ref;
    if (key === 'selected') {
      return acc;
    }
    const travelerIds = Object.values(val).map(traveler => traveler && Number.isInteger(traveler.id) && traveler.id).filter(id => id !== null);
    return [...acc, {
      boundSegmentIndex: key,
      travelerIds
    }];
  }, []);
};