import { Input } from '@etg/wings';
import { useValidation } from '@eti/providers';
import type { MutableRefObject } from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { Field as ReduxFormField } from 'redux-form';
import { formatGetDate, formatSetDate } from '../../utils/format';

interface DateInputProps {
  input: WrappedFieldInputProps;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  meta: WrappedFieldMetaProps;
  name: string;
}

const DateInput = ({ input, inputRef, meta, name, ...rest }: DateInputProps) => {
  const hasError = Boolean(meta.invalid && !meta.active && (input.value !== '' || meta.touched));
  return (
    <Input
      {...rest}
      {...input}
      autoComplete="off"
      data-testid={`${name}-input`}
      hasError={hasError}
      id={name}
      inputMode="none"
      ref={inputRef}
    />
  );
};

interface ConnectedDateInputProps {
  className?: string;
  dateFormat?: string;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  name: string;
  onClick?: () => void;
  onFocus?: any;
  placeholder?: string;
  validationRuleName: string;
}

const ConnectedDateInput = ({
  dateFormat,
  inputRef,
  name,
  onFocus,
  validationRuleName,
  ...rest
}: ConnectedDateInputProps) => {
  const validate = useValidation(validationRuleName);

  return (
    <ReduxFormField
      component={DateInput}
      format={formatGetDate(dateFormat)}
      inputRef={inputRef}
      name={name}
      normalize={formatSetDate}
      onFocus={onFocus}
      props={{ name } as any}
      validate={validate}
      {...rest}
    />
  );
};

export default ConnectedDateInput;
