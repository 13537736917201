import { lazy, Suspense } from 'react';
import { css } from '@eti/styles';
import { constants } from '@eti/utils';

const { brands } = constants;

const logoStyles = css`
  height: 2rem;
`;

const logos = {
  [brands.AZULMUNDO]: lazy(() => import('./narrow-logos/AzulMundo')),
};

type SupportedThemes = keyof typeof logos;

interface NarrowSiteLogoProps {
  brand: SupportedThemes;
}

const NarrowSiteLogo = ({ brand }: NarrowSiteLogoProps) => {
  const Logo = logos[brand] ?? null;

  return (
    <Suspense fallback="">
      <Logo className={logoStyles} />
    </Suspense>
  );
};

export default NarrowSiteLogo;
