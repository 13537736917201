import { useEffect, useState } from 'react';
import { Dialog, Inline, RadioGroup, Radio, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { Button } from '@eti/debug';
import { useTranslation } from '@eti/providers';

const iconStyle = css`
  fill: #fff;
  height: 1rem;
  margin-right: 0.625rem;
  width: 1rem;
`;

const GraphQLIcon = () => (
  <svg className={iconStyle} viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
    <path d="m57.468 302.66-14.376-8.3 160.15-277.38 14.376 8.3z" />
    <path d="m39.8 272.2h320.3v16.6h-320.3z" />
    <path d="m206.348 374.026-160.21-92.5 8.3-14.376 160.21 92.5zm139.174-241.079-160.21-92.5 8.3-14.376 160.21 92.5z" />
    <path d="m54.482 132.883-8.3-14.375 160.21-92.5 8.3 14.376z" />
    <path d="m342.568 302.663-160.15-277.38 14.376-8.3 160.15 277.38zm-290.068-195.163h16.6v185h-16.6zm278.4 0h16.6v185h-16.6z" />
    <path d="m203.522 367-7.25-12.558 139.34-80.45 7.25 12.557z" />
    <path d="m369.5 297.9c-9.6 16.7-31 22.4-47.7 12.8s-22.4-31-12.8-47.7 31-22.4 47.7-12.8c16.8 9.7 22.5 31 12.8 47.7m-278.6-160.9c-9.6 16.7-31 22.4-47.7 12.8s-22.4-31-12.8-47.7 31-22.4 47.7-12.8c16.7 9.7 22.4 31 12.8 47.7m-60.4 160.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.8 9.6-38.1 3.9-47.7-12.8m278.6-160.9c-9.6-16.7-3.9-38 12.8-47.7 16.7-9.6 38-3.9 47.7 12.8 9.6 16.7 3.9 38-12.8 47.7-16.7 9.6-38.1 3.9-47.7-12.8m-109.1 258.8c-19.3 0-34.9-15.6-34.9-34.9s15.6-34.9 34.9-34.9 34.9 15.6 34.9 34.9c0 19.2-15.6 34.9-34.9 34.9m0-321.8c-19.3 0-34.9-15.6-34.9-34.9s15.6-34.9 34.9-34.9 34.9 15.6 34.9 34.9-15.6 34.9-34.9 34.9" />
  </svg>
);

const setServerState = async (query, value) => {
  const response = await fetch(
    `/debug.mockGraphqlSuccessResponse?query=${query}&value=${String(value)}`,
  );
  const { currentValue } = await response.json();
  return currentValue;
};

const DebugGraphQlResponses = () => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateTripSuccessResponse, setUpdateTripSuccessResponse] = useState('true');
  const [selectTripSuccessResponse, setSelectTripSuccessResponse] = useState('true');
  const [addDiscountSuccessResponse, setAddDiscountSuccessResponse] = useState('true');

  useEffect(() => {
    const cookies = document.cookie.split(';');
    const isUpdateTripSetToFalse = cookies.some((cookie) =>
      cookie.includes('UpdateSelectedTripBeforeReservationResponseSuccess=false'),
    );
    const isSelectTripSetToFalse = cookies.some((cookie) =>
      cookie.includes('SelectTripResponseSuccess=false'),
    );
    const isAddDiscountSetToFalse = cookies.some((cookie) =>
      cookie.includes('AddDiscountCodeResponseSuccess=false'),
    );

    if (isUpdateTripSetToFalse) {
      setUpdateTripSuccessResponse('false');
    }
    if (isSelectTripSetToFalse) {
      setSelectTripSuccessResponse('false');
    }
    if (isAddDiscountSetToFalse) {
      setAddDiscountSuccessResponse('false');
    }
  }, []);

  const dismissDialog = () => setIsDialogOpen(false);

  const handleResponseChange = (query) => async (event) => {
    const currentValue = await setServerState(query, event.target.value);
    switch (query) {
      case 'UpdateSelectedTripBeforeReservationResponse': {
        setUpdateTripSuccessResponse(currentValue);
        break;
      }
      case 'AddDiscountCodeResponse': {
        setAddDiscountSuccessResponse(currentValue);
        break;
      }
      case 'SelectTripResponse':
      default: {
        setSelectTripSuccessResponse(currentValue);
        break;
      }
    }
  };

  return (
    <>
      <Button data-testid="mockServerSuccessResponses-button" onClick={() => setIsDialogOpen(true)}>
        <GraphQLIcon />
        Mock responses
      </Button>
      <Dialog
        closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
        isOpen={isDialogOpen}
        onDismiss={dismissDialog}
        title="GraphQL success responses"
      >
        <Stack>
          <p>
            These toggles affect what the mocked API returns, so it should be sufficient to toggle
            any value, and then close the dialog and trigger another request.
          </p>
          <Stack spacing={24}>
            <RadioGroup
              name="updateTripBeforeReservation"
              onChange={handleResponseChange('UpdateSelectedTripBeforeReservationResponse')}
              selectedValue={updateTripSuccessResponse}
            >
              <Stack spacing={8}>
                <RadioGroup.Legend>updateTripBeforeReservation</RadioGroup.Legend>
                <Inline spacing={24}>
                  <Radio.Label htmlFor="updateTripBeforeReservation_true">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="updateTripBeforeReservation_true" value="true" />
                      True
                    </Inline>
                  </Radio.Label>
                  <Radio.Label htmlFor="updateTripBeforeReservation_false">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="updateTripBeforeReservation_false" value="false" />
                      False
                    </Inline>
                  </Radio.Label>
                </Inline>
              </Stack>
            </RadioGroup>

            <RadioGroup
              name="selectTrip"
              onChange={handleResponseChange('SelectTripResponse')}
              selectedValue={selectTripSuccessResponse}
            >
              <Stack spacing={8}>
                <RadioGroup.Legend>selectTrip</RadioGroup.Legend>
                <Inline spacing={24}>
                  <Radio.Label htmlFor="selectTrip_true">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="selectTrip_true" value="true" />
                      True
                    </Inline>
                  </Radio.Label>
                  <Radio.Label htmlFor="selectTrip_false">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="selectTrip_false" value="false" />
                      False
                    </Inline>
                  </Radio.Label>
                </Inline>
              </Stack>
            </RadioGroup>

            <RadioGroup
              name="addDiscount"
              onChange={handleResponseChange('AddDiscountCodeResponse')}
              selectedValue={addDiscountSuccessResponse}
            >
              <Stack spacing={8}>
                <RadioGroup.Legend>addDiscount</RadioGroup.Legend>
                <Inline spacing={24}>
                  <Radio.Label htmlFor="addDiscount_true">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="addDiscount_true" value="true" />
                      True
                    </Inline>
                  </Radio.Label>
                  <Radio.Label htmlFor="addDiscount_false">
                    <Inline alignY="center" spacing={8}>
                      <Radio id="addDiscount_false" value="false" />
                      False
                    </Inline>
                  </Radio.Label>
                </Inline>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default DebugGraphQlResponses;
