import { tripTypes } from '../constants/tripTypesConstants';
import getTravelersText from '../widgets/search-form/utils/getTravelersText';

const multiCityTripTypes = [
  tripTypes.MULTI_STOP,
  tripTypes.OPEN_JAW_SINGLE_ORIGIN,
  tripTypes.OPEN_JAW_SINGLE_DESTINATION,
  tripTypes.OPEN_JAW_DOUBLE,
];

export const getFormattedDate = (inParameter) => {
  const date = typeof inParameter === 'object' ? inParameter : new Date(inParameter);

  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
  ].join('-');
};

export const getDepartureDateFromFormValues = ({ tripType, singleBound, multiBound }) => {
  const bound = multiCityTripTypes.includes(tripType.toUpperCase()) ? multiBound[0] : singleBound;

  return getFormattedDate(bound.departureDate);
};

export const getReturnDateFromFormValues = ({ tripType, singleBound, multiBound }) => {
  const typeOfTrip = tripType.toUpperCase();
  if (typeOfTrip === tripTypes.ONE_WAY) {
    return '';
  }

  const date = multiCityTripTypes.includes(typeOfTrip)
    ? multiBound[multiBound.length - 1].departureDate
    : singleBound.returnDate;

  return getFormattedDate(date);
};

export const getOriginFromFormValues = ({ tripType, singleBound, multiBound }) => {
  if (multiCityTripTypes.includes(tripType.toUpperCase())) {
    return multiBound.map((bound) => ({
      city: bound.origin.city,
    }));
  }

  return {
    city: singleBound.origin.city,
  };
};

export const getDestinationFromFormValues = ({ tripType, singleBound, multiBound }) => {
  if (multiCityTripTypes.includes(tripType.toUpperCase())) {
    return multiBound.map((bound) => ({
      city: bound.destination.city,
    }));
  }

  return {
    city: singleBound.destination.city,
  };
};

const transformToAPIRoute = (bound) => ({
  originCode: bound.origin.iataCode,
  originName: bound.origin.name,
  originCity: bound.origin.city,
  originCityCode: bound.origin.cityCode,
  originCountry: bound.origin.country,
  originCountryCode: bound.origin.countryCode,
  originContinent: bound.origin.continent,
  originContinentCode: bound.origin.continentCode,
  destinationCode: bound.destination.iataCode,
  destinationName: bound.destination.name,
  destinationCity: bound.destination.city,
  destinationCityCode: bound.destination.cityCode,
  destinationCountry: bound.destination.country,
  destinationCountryCode: bound.destination.countryCode,
  destinationContinent: bound.destination.continent,
  destinationContinentCode: bound.destination.continentCode,
  departureAt: new Date(bound.departureDate).getTime(),
  departureDate: getFormattedDate(bound.departureDate),
  departureTimeOfDay: bound.departureTimeOfDay,
});

const transformOneWayAndReturnSearchRoutesToAPIRoutes = (singleBound, tripType) => [
  transformToAPIRoute(singleBound),
  ...(tripType === tripTypes.RETURN
    ? [
        transformToAPIRoute({
          origin: singleBound.destination,
          destination: singleBound.origin,
          departureDate: singleBound.returnDate,
          departureTimeOfDay: singleBound.returnTimeOfDay,
        }),
      ]
    : []),
];

const transformMultiStopSearchRoutesToAPIRoutes = (searchFormRoutes) =>
  searchFormRoutes.map((route) => transformToAPIRoute(route));

const transformInputObjectsToAPIRoutes = (singleBound, multiBound, tripType) => {
  if (tripType === tripTypes.MULTI_STOP) {
    return transformMultiStopSearchRoutesToAPIRoutes(multiBound);
  }

  return transformOneWayAndReturnSearchRoutesToAPIRoutes(singleBound, tripType);
};

const getRoutesFromFormValues = ({ singleBound, multiBound, tripType }) =>
  transformInputObjectsToAPIRoutes(singleBound, multiBound, tripType);
const getTravelersSummary = (passengers, travelerTexts) => {
  const { adults, children, infants } = passengers;
  return getTravelersText(adults, children, infants, travelerTexts);
};
export const getTravelersFromFormValues = (passengers, travelerTexts) => {
  const { adults, children, childrenAges, infants, infantsAges } = passengers;
  return {
    adults,
    childAges: [...childrenAges.slice(0, children), ...infantsAges.slice(0, infants)],
    summary: getTravelersSummary(passengers, travelerTexts),
  };
};

export const getFormattedSearchData = (formValues, travelerTexts) => {
  const origin = getOriginFromFormValues(formValues);
  const destination = getDestinationFromFormValues(formValues);
  return {
    cabinClass: formValues.cabinClass,
    carrierCodes: formValues.carrierCodes,
    date: {
      departure: getDepartureDateFromFormValues(formValues),
      return: getReturnDateFromFormValues(formValues),
    },
    destination: Array.isArray(destination) ? destination[destination.length - 1] : destination,
    origin: Array.isArray(origin) ? origin[0] : origin,
    routes: getRoutesFromFormValues(formValues),
    shouldOnlyShowDirectFlights: formValues?.directFlight,
    stopoverTypes: formValues?.stopoverTypes,
    stopoverEnabled: formValues?.stopoverEnabled,
    shortStopover: formValues?.shortStopover,
    oneDayStopover: formValues?.oneDayStopover,
    oneNightStopover: formValues?.oneNightStopover,
    twoNightsStopover: formValues?.twoNightsStopover,
    travelers: getTravelersFromFormValues(formValues.passengers, travelerTexts),
    tripType: formValues.tripType,
  };
};
