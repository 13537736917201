import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const BaggageInsurancePage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="content/cookies"
      pageTitle={t('PageTitle.rf.baggage-insurance.action')}
      textKey="BaggageInsurance.HtmlContent"
    />
  );
};

export default BaggageInsurancePage;
