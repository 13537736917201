import {
  breakpoints,
  Button,
  Card,
  Dialog,
  Heading,
  Inline,
  LinkButton,
  PageBlock,
  Stack,
  vars,
} from '@etg/wings';
import { css } from '@eti/styles';
import { CaretRight, CaretLeft } from '@phosphor-icons/react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation, useDirection, useSiteContext } from '@eti/providers';
import { DangerousHtmlContent, Notification, LoadingDots, Pictogram } from '@eti/components';
import { Collection, DevBar } from '@eti/debug';
import useFlightStatus from '../hooks/useFlightStatus';
import { routes } from '../../../constants/routesAndApisConstants';
import { isDev } from '../../../common/debug/utils';
import { DebugCommonComponents } from '../../../common/debug/components';
import CaretButton from '../../order-details/components/common/CaretButton';

const blockStyles = css`
  background-color: ${vars.colors.pageBackdrop};
  height: 100%;
`;

const stackStyles = css`
  margin: 0 auto;
  max-width: 344px;
`;

const cardStyles = css`
  display: flex;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

const buttonStyles = css`
  align-items: center;
  padding: 0;
  text-align: left;
`;

const dateTimeStyles = css`
  font-size: 0.875rem;
`;

const getContainerStyle = css`
  align-items: center;
  color: ${vars.colors.links.default};
  display: flex;
  font-size: 2.5rem;
  height: 100%;
  justify-content: center;
`;

const MtpSelectFlightPage = () => {
  const { isRTL } = useDirection();
  const { t } = useTranslation();
  const { brand } = useSiteContext();
  const { status, isLoading, hasError, isLoggedIn } = useFlightStatus();
  const history = useHistory();

  if (isLoading) {
    return <LoadingDots containerStyle={getContainerStyle} />;
  }

  if (!isLoggedIn && !hasError) {
    return <Redirect to={routes.MOBILE_TRAVEL_PLAN_LOGIN} />;
  }

  const onSelectFlight = (index) => {
    history.push(routes.MOBILE_TRAVEL_PLAN_FLIGHT_STATUS, { index });
  };

  const handleClose = () => {
    history.push(routes.ORDER_DETAILS);
  };

  return (
    <PageBlock className={blockStyles} withPaddingY>
      <Stack className={stackStyles} spacing={8}>
        {hasError && (
          <>
            <Notification dataTestId="select-flight-error-notification" variant="critical">
              <DangerousHtmlContent content={t('StatusPage.Error.Message')} />
            </Notification>

            <LinkButton onClick={() => history.push(routes.MOBILE_TRAVEL_PLAN_LOGIN)}>
              {isRTL ? <CaretRight /> : <CaretLeft />}
              {t('MobileTravelPlan.Login.SubmitButton')}
            </LinkButton>
          </>
        )}
        {!hasError && !status?.hasData && (
          <Dialog
            closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
            data-testid="mtp-noData-dialog"
            isOpen
          >
            <Stack>
              <Inline align="center">
                <Pictogram brand={brand.code} id="generic-error" />
              </Inline>
              <DangerousHtmlContent content={t('MobileTravelPlan.ErrorDialog.Message')} />
              <Inline align="end" collapseBelow={breakpoints._560}>
                <Button data-testid="dialog-close" onClick={handleClose} variant="primary">
                  {t('MobileTravelPlan.ErrorDialog.Button.Text')}
                </Button>
              </Inline>
            </Stack>
          </Dialog>
        )}
        {!hasError && !isLoading && status?.flights && status?.hasData && (
          <>
            {status.flights.map(({ overview }, index) => (
              <Card
                className={cardStyles}
                key={`${overview.arrival.airport.code}${overview.departure.airport.code}`}
                onClick={() => {
                  onSelectFlight(index);
                }}
              >
                <CaretButton className={buttonStyles} dataTestId={`select-flight-${index}`}>
                  <Stack spacing={0}>
                    <time className={dateTimeStyles}>{overview.departure.dateTime}</time>
                    <Heading as="h4" data-testid={`select-flight-airports-${index}`} level={1}>
                      {`${overview.departure.airport.code} - ${overview.arrival.airport.code}`}
                    </Heading>
                  </Stack>
                </CaretButton>
              </Card>
            ))}
          </>
        )}
      </Stack>
      {isDev && (
        <DevBar>
          <Collection>
            <DebugCommonComponents />
          </Collection>
        </DevBar>
      )}
    </PageBlock>
  );
};

export default MtpSelectFlightPage;
