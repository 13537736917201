import { breakpoints, Card, Heading, Inline, PageBlock, Stack, vars } from '@etg/wings';
import { DevBar, Collection } from '@eti/debug';
import {
  useTranslation,
  useSiteContext,
  useProperty,
  useDirection,
  useSearch,
} from '@eti/providers';
import { css } from '@eti/styles';
import { CaretLeft } from '@phosphor-icons/react';
import { useHistory, Link } from 'react-router-dom';
import { DebugCommonComponents } from '../../../common/debug/components';
import { isDev } from '../../../common/debug/utils';
import { routes } from '../../../constants/routesAndApisConstants';
import { SearchForm } from '../../../widgets/search-form';
import { getSearchFormDataFrom } from '../../../widgets/search-form/utils/initialValues';

const blockStyles = css`
  background: ${vars.colors.pageBackdrop};
  height: 100%;
`;

const subHeadingStyles = css`
  max-width: 47.5rem;
  text-align: center;
`;

const searchFormCardStyles = css`
  @media (min-width: ${breakpoints._768}) {
    max-width: 59.375rem;
  }
`;

const linkWrapperStyles = css`
  background-color: #fff;
  height: 3.75rem;
`;

const PageNotFound = () => {
  const history = useHistory();
  const { language } = useSiteContext();
  const { t } = useTranslation();
  const { p } = useProperty();
  const { isRTL } = useDirection();
  const { currentSearchData } = useSearch();

  const errorPageHomeUrl = p('IBEClient.ErrorMessageLink.Url');

  const handleSearch = () => {
    history.push(routes.RESULT);
  };

  return (
    <>
      <PageBlock className={blockStyles} withPaddingY>
        <Stack align="center" spacing={48}>
          <Stack align="center" spacing={16}>
            <Heading data-testid="page-not-found-title" level={1}>
              {t('StatusPage.PageNotFound.Title')}
            </Heading>
            <Heading as="p" className={subHeadingStyles} level={2}>
              {t('StatusPage.PageNotFound.Message')}
            </Heading>
          </Stack>
          <Card className={searchFormCardStyles}>
            <SearchForm
              language={language.code}
              onSearch={handleSearch}
              search={currentSearchData ? getSearchFormDataFrom(currentSearchData) : undefined}
            />
          </Card>
        </Stack>
      </PageBlock>
      <Inline
        align="center"
        alignY="center"
        as={Link}
        className={linkWrapperStyles}
        data-testid="start-page-link"
        noWrap
        spacing={8}
        to={errorPageHomeUrl}
      >
        <CaretLeft aria-hidden mirrored={isRTL} size="1.25rem" weight="light" />
        {t('StatusPage.LinkLabel.Home')}
      </Inline>
      {isDev && (
        <DevBar>
          <Collection>
            <DebugCommonComponents />
          </Collection>
        </DevBar>
      )}
    </>
  );
};

export default PageNotFound;
