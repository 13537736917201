import { breakpoints } from '@etg/wings';
import { ArrowsLeftRight } from '@phosphor-icons/react';
import type { ComponentPropsWithoutRef } from 'react';
import { css, cx, resetButtonStyle } from '@eti/styles';

const styles = css`
  ${resetButtonStyle};
  display: none;

  @media (min-width: ${breakpoints._768}) {
    display: block;

    &:focus-visible {
      outline: auto;
    }
  }
`;

const rotateDegreeStyles = (rotate: number) => css`
  transform: rotate(${rotate}deg);
`;

interface SwitchDirectionButtonProps extends ComponentPropsWithoutRef<'button'> {
  'data-testid'?: string;
  rotate?: number;
}

const SwitchDirectionButton = ({
  className,
  'data-testid': dataTestId,
  onClick,
  rotate,
  ...props
}: SwitchDirectionButtonProps) => (
  <button
    className={cx(styles, className)}
    data-testid={dataTestId}
    onClick={onClick}
    type="button"
    {...props}
  >
    <ArrowsLeftRight
      className={rotate ? rotateDegreeStyles(rotate) : undefined}
      size="24"
      weight="light"
    />
  </button>
);

export default SwitchDirectionButton;
