import { vars, Heading, Stack, Inline } from '@etg/wings';
import { useProperty, useTranslation } from '@eti/providers';
import { Overlay } from '@eti/components';
import { css, cx } from '@eti/styles';
import type { SubMenuItem } from '@eti/schema-types';
import { useLanguage } from '../../../common/localization/custom-hooks/language';
import LanguageSelectionDropdown from '../../../common/localization/components/LanguageSelectionDropdown';
import SettingsGraphqlContainer from '../../../common/settings/components/SettingsGraphqlContainer';

const drawerStyles = css`
  color: ${vars.colors.header.content};
  padding: 8px;
`;

const drawerHeaderStyles = css`
  background-color: ${vars.colors.header.base};
  color: ${vars.colors.header.content};
`;

const menuItemStyles = (isSettingsEnabled: boolean, shouldShowMultiLanguage: boolean) => css`
  border-bottom: 1px solid ${vars.colors.divider};
  padding-bottom: 24px;

  &:last-child {
    ${!shouldShowMultiLanguage && !isSettingsEnabled ? 'border-bottom: 0;' : ''}
  }
`;

const linkStyles = css`
  color: ${vars.colors.header.content};
  font-size: 1rem;
  text-decoration: none;
`;

interface MobileMenuProps {
  isMobileMenuOpen: boolean;
  menu: {
    id: string;
    name: string;
    path: string;
    subMenu: SubMenuItem[];
  }[];
  menuTitleText: string;
  toggleMobileMenu: () => void;
}

const MobileMenu = ({
  isMobileMenuOpen,
  menu,
  menuTitleText,
  toggleMobileMenu,
}: MobileMenuProps) => {
  const { lessThanTwoAvailableLanguages } = useLanguage();
  const { p } = useProperty();
  const { t } = useTranslation();
  const isSettingsEnabled =
    p('IbeClient.Settings.Selection.Country.Enabled') ||
    p('IbeClient.Settings.Selection.Language.Enabled') ||
    p('IbeClient.Settings.Selection.Currency.Enabled');
  const shouldShowMultiLanguage = p('Site.MultiLanguage.Enabled') && !lessThanTwoAvailableLanguages;

  return (
    <Overlay.Drawer
      backgroundColor={vars.colors.header.base}
      className={cx(drawerStyles, { 'mobile-menu-open': isMobileMenuOpen })}
      contentLabel={menuTitleText}
      handleClose={toggleMobileMenu}
      isOpen={isMobileMenuOpen}
      shouldOpenHorizontally
    >
      <Overlay.Header
        className={drawerHeaderStyles}
        dialogCloseButtonAriaLabel={t('SiteMenu.CloseMenu.Label')}
        onClose={toggleMobileMenu}
        title={menuTitleText}
      />
      <Overlay.Content>
        <Stack data-testid="menu" spacing={24}>
          <Stack as="nav" spacing={24}>
            {menu.map(({ id, name: subMenuTitle, subMenu }) => (
              <Stack
                className={menuItemStyles(shouldShowMultiLanguage, isSettingsEnabled)}
                key={id}
                spacing={24}
              >
                <Heading level={3}>{subMenuTitle}</Heading>
                <Stack as="ul" spacing={24}>
                  {subMenu.map(({ name, path, target }) => (
                    <li key={name}>
                      <a
                        className={linkStyles}
                        data-testid={`menu-link-${name}`}
                        href={path}
                        target={target}
                      >
                        {name}
                      </a>
                    </li>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
          {isSettingsEnabled ? (
            <Inline align="start">
              <SettingsGraphqlContainer componentType="Menu" />
            </Inline>
          ) : (
            shouldShowMultiLanguage && <LanguageSelectionDropdown />
          )}
        </Stack>
      </Overlay.Content>
    </Overlay.Drawer>
  );
};

export default MobileMenu;
