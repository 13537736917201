import { formValueSelector } from 'redux-form';
import * as products from '../../products';
const stringToBoolean = value => {
  if (typeof value !== 'string') {
    return value;
  }
  const val = value === null || value === void 0 ? void 0 : value.toLowerCase().trim();
  return val === 'true' || val === 'false' ? JSON.parse(val) : val;
};
export const getSelected = (name, form) => state => {
  var _formData$value;
  const formData = formValueSelector(form)(state, name);
  return formData ? stringToBoolean(formData === null || formData === void 0 || (_formData$value = formData.value) === null || _formData$value === void 0 ? void 0 : _formData$value.value) : null;
};
const getProductId = name => {
  const product = Object.values(products).find(prod => (prod === null || prod === void 0 ? void 0 : prod.name) === name);
  return product && 'id' in product && product.id ? String(product.id) : null;
};
export const getGraphQlSelection = (name, type) => _ref => {
  let {
    form,
    state
  } = _ref;
  const UiSelection = getSelected(name, form)(state);
  const productId = getProductId(name);
  if (!productId || !UiSelection) {
    return null;
  }
  if (type === 'traveler') {
    return {
      productId,
      selectionTraveler: formValueSelector(form)(state, name).travelerIds
    };
  }
  return {
    productId,
    selectionNone: true
  };
};
export const getDBFGraphQlSelection = (name, type) => _ref2 => {
  let {
    form,
    state
  } = _ref2;
  const UiSelection = getSelected(name, form)(state);
  const productId = getProductId(name);
  if (!productId || UiSelection == null) {
    return null;
  }
  if (type === 'traveler') {
    return {
      productId,
      selectionTraveler: UiSelection ? formValueSelector(form)(state, name).travelerIds : []
    };
  }
  return {
    productId,
    selectionNone: UiSelection
  };
};
export const getPrice = (name, form) => state => {
  const formData = formValueSelector(form)(state, name);
  return formData ? formData.value.priceRaw : null;
};