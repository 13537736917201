import { css, mediaQueries } from '@eti/styles';
export const readMoreGrid = css`
  display: grid;

  .text {
    grid-area: 2 / 1 / 3 / 2;
  }

  .children {
    display: grid;
    grid-area: 1 / 1 / 2 / 2;
    margin-bottom: 16px;
    padding-inline: 16px;
  }
`;
export const productCardGrid = css`
  grid-area: 5 / 2 / 6 / 3;
  width: auto;

  @media ${mediaQueries.medium.up} {
    margin-inline-start: 16px;
  }
`;