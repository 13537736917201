import React, { useCallback, useEffect, useRef, useState } from 'react';
import { breakpoints, Button, Heading, Inline, PageBlock, Stack, vars } from '@etg/wings';
import { DangerousHtmlContent } from '@eti/components';
import { useProperty, useTranslation } from '@eti/providers';
import { css, cx, getTextStyles, mediaQueries } from '@eti/styles';
import { Printer } from '@phosphor-icons/react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Loading from '../../../common/loading/components/Loading';
import { useBackgroundImage } from '../../../utils/backgroundImage';
import TripReminder from '../../../widgets/trip-reminder/containers/TripReminderContainer';

const printStyles = css`
  @media print {
    display: none;
  }
`;

const heroStyles = css`
  aspect-ratio: 16 / 9;

  @media (min-width: ${breakpoints._560}) {
    aspect-ratio: 21 / 9;
  }

  @media (min-width: ${breakpoints._1024}) {
    aspect-ratio: auto;
    padding: 16.5625rem 0;
  }
`;

const heroContentStyles = css`
  padding-block: 6.25rem;

  @media print {
    padding: 0;
  }
`;

const listStyles = css`
  @media ${mediaQueries.large.up} {
    font-size: 0.875rem;
  }

  a {
    font-weight: 700;
  }

  p {
    margin-bottom: 0.875rem;
    margin-top: 0.25rem;
  }

  table {
    width: 100%;
  }

  ol {
    padding-left: 0.625rem;

    ul {
      padding-left: 2rem;
      padding-top: 1rem;
    }

    li {
      padding-left: 0;
      &::before {
        background-color: transparent;
        content: none;
        height: 0;
        position: initial;
        width: 0;
      }
    }
  }
`;

const contentStyles = css`
  scroll-margin-top: 80px;
`;

const heroSubTextStyles = css`
  color: #fff;
  text-shadow: 0 0 10px #000;

  @media print {
    color: ${vars.colors.text};
    text-shadow: none;
  }
`;

const scrollIntoViewWithOffset = (selector: string, offset: number) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      (document.querySelector(selector)?.getBoundingClientRect().top ?? 0) -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

const scrollToParagraph = (anchor: string) => {
  const key = `[name=${anchor}]`;
  const element = document.querySelector(key);
  if (element) {
    setTimeout(
      () =>
        window.requestAnimationFrame(() => {
          scrollIntoViewWithOffset(key, 80);
        }),
      100,
    );
  }
};

interface SubPageProps {
  className?: string;
  heroSubText?: string;
  heroTitle?: string;
  image?: string;
  pageTitle?: string;
  shouldShowPrintButton?: boolean;
  text?: string | null;
  textKey?: string;
}

const SubPage = ({
  className,
  heroSubText,
  heroTitle,
  image,
  pageTitle,
  shouldShowPrintButton,
  text = null,
  textKey,
}: SubPageProps) => {
  const { p } = useProperty();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const [content, setContent] = useState(text);
  const [title, setTitle] = useState();
  const [subText, setSubText] = useState();
  const contentRef = useRef(null);

  const { cssClass } = useBackgroundImage(image);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const shouldHideHotelDeals = p('TravelConditions.HideHotelDeals');
  const shouldHideRentalCarDeals = p('TravelConditions.HideRentalCarDeals');

  const hideHotelsAndRentalCarDeals = useCallback(
    (responseContext) => {
      const contentWrapper = document.createElement('div');
      contentWrapper.innerHTML = responseContext;

      if (shouldHideHotelDeals) {
        contentWrapper.querySelector(`a[href='#MEDIATION_HOTEL']`)?.closest('li')?.remove();
        contentWrapper.querySelector(`a[name='MEDIATION_HOTEL']`)?.closest('li')?.remove();
      }

      if (shouldHideRentalCarDeals) {
        contentWrapper.querySelector(`a[href='#MEDIATION_RENTAL_CAR']`)?.closest('li')?.remove();
        contentWrapper.querySelector(`a[name='MEDIATION_RENTAL_CAR']`)?.closest('li')?.remove();
      }
      return String(contentWrapper.innerHTML);
    },
    [shouldHideHotelDeals, shouldHideRentalCarDeals],
  );

  useEffect(() => {
    if (!content) {
      const urlParam = encodeURIComponent(`["${textKey}","${heroTitle}","${heroSubText}"]`);

      const fetchText = async () => {
        const resp = await fetch(`/rpc.fetch-uitexts.do.action?data=${urlParam}&allowHtml=true`);
        const respJson = await resp.json();
        const respText = respJson.model[textKey ?? ''] || '';
        const respTitle = respJson.model[heroTitle ?? ''] || '';
        const respSubText = respJson.model[heroSubText ?? ''] || '';
        const responseContext =
          shouldHideHotelDeals || shouldHideRentalCarDeals
            ? hideHotelsAndRentalCarDeals(respText)
            : respText;
        setContent(responseContext);
        setTitle(respTitle);
        setSubText(respSubText);
      };

      fetchText();
    }
  }, [
    content,
    heroTitle,
    heroSubText,
    hideHotelsAndRentalCarDeals,
    shouldHideHotelDeals,
    shouldHideRentalCarDeals,
    textKey,
  ]);

  useEffect(() => {
    if (content && hash) {
      scrollToParagraph(hash.replace('#', ''));
    }
  }, [content, hash]);

  const handlePrint = () => {
    if (p('IbeClient.Print.HideHeaderAndFooter.Enabled')) {
      reactToPrintFn();
    } else {
      window.print();
    }
  };

  const hasHeroContent = title && subText;

  return (
    <>
      <Helmet title={pageTitle} />
      {content == null ? (
        <Loading dataTestId="sub-page-loading-spinner" />
      ) : (
        <div className={cx(getTextStyles(), listStyles, className)} ref={contentRef}>
          {image && cssClass && (
            <PageBlock
              className={cx(
                cssClass,
                !hasHeroContent && printStyles,
                hasHeroContent ? heroContentStyles : heroStyles,
              )}
              withPaddingY
            >
              {title && (
                <>
                  <Heading
                    className={heroSubTextStyles}
                    data-testid="sub-page-hero-title"
                    level={1}
                  >
                    {title}
                  </Heading>
                  {subText && (
                    <Heading
                      className={heroSubTextStyles}
                      data-testid="sub-page-hero-subtext"
                      level={2}
                    >
                      {subText}
                    </Heading>
                  )}
                </>
              )}
            </PageBlock>
          )}
          <PageBlock withPaddingY>
            <Stack spacing={16}>
              {shouldShowPrintButton && (
                <Inline align="end" className={printStyles}>
                  <Button
                    data-testid="sub-page-print-button"
                    iconPrefix={<Printer aria-hidden />}
                    onClick={handlePrint}
                    size="small"
                  >
                    {t('IbeClient.Print.Button.Label')}
                  </Button>
                </Inline>
              )}
              <DangerousHtmlContent className={contentStyles} content={content} />
            </Stack>
          </PageBlock>
        </div>
      )}
      <TripReminder className={printStyles} />
    </>
  );
};

export default SubPage;
