import type { ElementType } from 'react';
import { useEffect } from 'react';
import { Route as ReactRouterRoute, Redirect, useLocation } from 'react-router-dom';
import { usePageViewDataLayerEvent } from '../../data-layer/dataLayer';

interface RouteProps {
  component: ElementType;
  dataLayerPathname?: string;
  exact?: boolean;
  path: string | string[];
  props: any;
  redirectTo?: string;
}
const Route = ({
  component: Component,
  dataLayerPathname,
  exact,
  path,
  props,
  redirectTo,
}: RouteProps) => {
  const location = useLocation();
  usePageViewDataLayerEvent(dataLayerPathname);

  const { setIsMenuVisible } = props;

  useEffect(() => {
    setIsMenuVisible({ type: location.pathname });
  }, [setIsMenuVisible, location.pathname]);

  return (
    <ReactRouterRoute
      exact={exact}
      path={path}
      render={() => (!redirectTo ? <Component {...props} /> : <Redirect to={redirectTo} />)}
    />
  );
};
export default Route;
