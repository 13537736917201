import { Button, Card, Heading, Inline, PageBlock, Stack, vars } from '@etg/wings';
import { DangerousHtmlContent, LoadingDots, Notification, Pictogram } from '@eti/components';
import { css } from '@eti/styles';
import { useForm } from 'react-hook-form';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation, useSiteContext } from '@eti/providers';
import { Collection, DevBar } from '@eti/debug';
import useFlightStatus from '../hooks/useFlightStatus';
import useLogin from '../hooks/useLogin';
import { DebugCommonComponents } from '../../../common/debug/components';
import { isDev } from '../../../common/debug/utils';
import { routes } from '../../../constants/routesAndApisConstants';
import TextInput from './TextInput';

const EMAIL_CHECK =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const ORDER_NUMBER_CHECK = /^([a-zA-Z0-9]{6,10}|^\d{4}-\d{3}-\d{3})$/;
const EMAIL = 'login-email';
const ORDER_NUMBER = 'login-order-number';

const blockStyles = css`
  background-color: ${vars.colors.pageBackdrop};
  height: 100%;
`;

const contentStyles = css`
  max-width: 344px;
`;

const loadingDotsStyles = css`
  align-items: center;
  color: ${vars.colors.links.default};
  display: flex;
  font-size: 2.5rem;
  height: 100%;
  justify-content: center;
`;

const MtpLoginPage = () => {
  const { t } = useTranslation();
  const { brand } = useSiteContext();
  const history = useHistory();
  const {
    isLoading: isFlightStatusLoading,
    hasError: hasFlightStatusError,
    isLoggedIn,
  } = useFlightStatus();
  const { login, isFail, isLoading, hasError } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  if (isFlightStatusLoading) {
    return <LoadingDots containerStyle={loadingDotsStyles} />;
  }

  if (isLoggedIn) {
    return <Redirect to={routes.MOBILE_TRAVEL_PLAN} />;
  }

  const onSuccessfulLogin = () => {
    history.push(routes.MOBILE_TRAVEL_PLAN);
  };

  const handleLogin = ({ [EMAIL]: email, [ORDER_NUMBER]: orderNumber }) => {
    login(email, orderNumber, onSuccessfulLogin);
  };

  return (
    <PageBlock className={blockStyles} withPaddingY>
      <Stack align="center" spacing={8}>
        {(isFail || hasError || hasFlightStatusError) && (
          <Notification
            className={contentStyles}
            dataTestId="login-notification"
            variant="critical"
          >
            <DangerousHtmlContent
              content={
                hasError || hasFlightStatusError
                  ? t('MobileTravelPlan.Login.Generic.Error')
                  : t('MobileTravelPlan.Login.InvalidEmailOrOrderNumber.Error')
              }
            />
          </Notification>
        )}
        <Card className={contentStyles}>
          <form noValidate onSubmit={handleSubmit(handleLogin)}>
            <Stack>
              <Inline align="center">
                <Pictogram brand={brand.code} id="login-phone" />
                <Heading as="h1" level={4}>
                  {t('MobileTravelPlan.Login.Heading')}
                </Heading>
              </Inline>
              <TextInput
                data-testid="email"
                errors={errors}
                isRequired
                label={t('MobileTravelPlan.Login.Email')}
                register={register(EMAIL, {
                  required: t('MobileTravelPlan.Login.Required.Email.Hint'),
                  pattern: {
                    value: EMAIL_CHECK,
                    message: t('MobileTravelPlan.Login.Invalid.Email.Hint'),
                  },
                })}
                type="email"
              />
              <TextInput
                autocapitalize="characters"
                data-testid="order-number"
                errors={errors}
                isRequired
                label={t('MobileTravelPlan.Login.OrderNumber')}
                register={register(ORDER_NUMBER, {
                  required: t('MobileTravelPlan.Login.Required.OrderNumber.Hint'),
                  pattern: {
                    value: ORDER_NUMBER_CHECK,
                    message: t('MobileTravelPlan.Login.Invalid.OrderNumber.Hint'),
                  },
                })}
              />
              <Button
                data-testid="login-button"
                isDisabled={isLoading}
                type="submit"
                variant="primary"
              >
                {isLoading ? <LoadingDots /> : t('MobileTravelPlan.Login.SubmitButton')}
              </Button>
            </Stack>
          </form>
        </Card>
      </Stack>
      {isDev && (
        <DevBar>
          <Collection>
            <DebugCommonComponents />
          </Collection>
        </DevBar>
      )}
    </PageBlock>
  );
};

export default MtpLoginPage;
