import { useRef } from 'react';
import { constants, isPartner } from '@eti/utils';
import { css } from '@eti/styles';
import Cookies from 'js-cookie';
import type { Theme } from '../utils/theming';
import { themes, whitelabels } from '../utils/theming';

const selectStyles = css`
  border: 1px solid #307be8;
  border-radius: 3px;
  height: 36px;
  padding-inline: 6px;
`;

const { brands } = constants;

const getStoredMockedTheme = (): string => {
  const mockedTheme = JSON.parse(Cookies.get('mockedTheme') ?? '{}');
  if (isPartner(mockedTheme?.partner)) {
    return mockedTheme.partner;
  }

  return mockedTheme.brand?.code.toLowerCase() ?? brands.GOTOGATE;
};

const whitelabelPartners: string[] = Object.values(whitelabels).map(
  (theme) => theme && theme.partner,
);

const etraveliBrands: Theme[] = Object.values(themes).reduce((acc, theme) => {
  if (!whitelabelPartners.includes(theme.partner)) {
    return [...acc, theme];
  }
  return acc;
}, [] as Theme[]);

const DebugThemeSelect = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const defaultSelectValue = getStoredMockedTheme();

  const handleChange = () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const selectedValue = formData.get('theme');

      if (typeof selectedValue === 'string') {
        const themeConfiguration = themes[selectedValue];

        formRef.current.selectedTheme.value = JSON.stringify(themeConfiguration);
        formRef.current?.submit();
      }
    }
  };

  return (
    <form action="/debug.siteTheme.mock.config" method="POST" onChange={handleChange} ref={formRef}>
      <select className={selectStyles} defaultValue={defaultSelectValue} name="theme">
        <optgroup label="Partners / White labels">
          {whitelabelPartners.map((partner) => (
            <option key={partner} value={partner}>
              {partner.charAt(0).toUpperCase() + partner.slice(1)}
            </option>
          ))}
        </optgroup>
        <optgroup label="Brands">
          {etraveliBrands.map(({ brand }) => (
            <option key={brand.code} value={brand.code.toLowerCase()}>
              {brand.name}
            </option>
          ))}
        </optgroup>
      </select>
      <input name="selectedTheme" type="hidden" />
    </form>
  );
};

export default DebugThemeSelect;
