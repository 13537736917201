import { useSiteContext, useTranslation } from '@eti/providers';
import { constants } from '@eti/utils';
import SubPage from './SubPage';

const { ADR } = constants.brands;

const TrustedChoicePage = () => {
  const { t } = useTranslation();
  const context = useSiteContext();

  const isPanelHidden = context.brand.code === ADR;
  const image = !isPanelHidden ? 'content/cookies' : undefined;

  return (
    <SubPage
      image={image}
      pageTitle={t('PageTitle.rf.trusted-choice.action')}
      textKey="TrustedChoice.HtmlContent"
    />
  );
};

export default TrustedChoicePage;
