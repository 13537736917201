export const concatenateCoverGeniusTexts = _ref => {
  var _ipidPolicy$url, _terms$url;
  let {
    ipidPolicy,
    terms,
    disclaimer,
    isInUSOrUKMarket = false
  } = _ref;
  const ipidPolicyUrl = (_ipidPolicy$url = ipidPolicy === null || ipidPolicy === void 0 ? void 0 : ipidPolicy.url) !== null && _ipidPolicy$url !== void 0 ? _ipidPolicy$url : '';
  const termsUrl = (_terms$url = terms === null || terms === void 0 ? void 0 : terms.url) !== null && _terms$url !== void 0 ? _terms$url : '';
  const ipidPolicyLink = ipidPolicyUrl && `<a href=${ipidPolicyUrl} target="_blank" class="link">${ipidPolicy === null || ipidPolicy === void 0 ? void 0 : ipidPolicy.text}</a>`;
  const termsLink = termsUrl && `<a href=${termsUrl} target="_blank" class="link">${terms === null || terms === void 0 ? void 0 : terms.text}</a>`;
  const disclaimerLink = disclaimer && `<p class="terms-disclaimer"> ${disclaimer} </p>`;
  const termDisclaimerIpidContent = [ipidPolicyLink, termsLink];
  if (isInUSOrUKMarket) {
    termDisclaimerIpidContent.unshift(disclaimerLink);
  } else {
    termDisclaimerIpidContent.push(disclaimerLink);
  }
  return termDisclaimerIpidContent.filter(Boolean).join('');
};