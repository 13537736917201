import { css, mediaQueries } from '@eti/styles';
import { vars } from '@etg/wings';
export const wrapperStyles = css`
  border-block: 1px solid ${vars.colors.divider};
  display: flex;
  flex-flow: column wrap;
  margin-inline-end: -16px;
  padding: 0;

  ul > *:not(:last-child) {
    margin-bottom: 8px;
  }

  @media ${mediaQueries.small.only} {
    margin-inline: -16px;
  }
`;
export const clickableArea = css`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 16px;
  z-index: 1;
`;
export const fontSize = css`
  & li:not(:last-child) {
    margin-bottom: 8px;
  }

  @media ${mediaQueries.medium.up} {
    font-size: 0.875rem;
  }
`;
export const toggleIconStyles = open => css`
  transform: rotateX(${open ? '180deg' : '0deg'});
  transition: transform 0.2s ease-in-out;
`;
export const contentText = iconContent => css`
  margin-bottom: 16px;
  padding-inline: 24px 16px;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ul {
    li {
      list-style-position: inside;
      padding-inline-start: 12px;
      &::before {
        left: 0;
        margin-right: 8px;
        position: absolute;
      }
    }
  }

  & a {
    color: ${vars.colors.links.default};
    position: relative;

    &.link {
      display: block;
      margin-bottom: 0;
      width: fit-content;
    }

    + p {
      margin-top: 16px;
    }

    &::after {
      bottom: 6px;
      content: ${iconContent !== null && iconContent !== void 0 ? iconContent : ''};
      display: inline-block;
      height: 16px;
      margin-inline-start: 4px;
      position: relative;
      vertical-align: bottom;
      width: 16px;
    }
  }
`;
export const readMoreContentStyles = css`
  display: grid;
  overflow: hidden;
`;
export const readMoreLabelStyles = css`
  font-weight: 700;
`;
export const standardGrid = css`
  display: grid;

  .text {
    grid-area: 1 / 1 / 2 / 2;
  }

  .children {
    display: contents;
  }
`;