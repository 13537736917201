import { SelectBox, Text, textVariants } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import type { Option } from '../models';

const selectedOptionStyles = css`
  background-color: #f5f5f5;
  padding: 6px 8px;
`;
interface LanguageSettingProps {
  onChange: (option: Option) => void;
  value: Option;
  primaryOptions: Option[];
  additionalOptions: Option[];
}

const LocaleSelect = ({
  onChange,
  value,
  primaryOptions,
  additionalOptions,
}: LanguageSettingProps) => {
  const { t } = useTranslation();
  const label = t('Settings.Language.Label');

  return (
    <SelectBox
      data-testid="settings-language-dropdown"
      label={label}
      name="LocaleSelect"
      onChange={onChange}
      placeholder={label}
      value={value}
    >
      {primaryOptions.length > 0 && (
        <>
          <Text as="div" className={selectedOptionStyles} variant={textVariants._12}>
            {t('Settings.Language.Suggested.Options')}
          </Text>
          <ul>
            {primaryOptions?.map((option) => (
              <SelectBox.Option
                key={option.value}
                label={option.label}
                lang={option.value}
                value={option}
              />
            ))}
          </ul>
        </>
      )}
      {additionalOptions.length > 0 && (
        <>
          <Text
            aria-hidden="true"
            as="div"
            className={selectedOptionStyles}
            variant={textVariants._12}
          >
            {t('Settings.Language.All.Options')}
          </Text>
          <ul>
            {additionalOptions.map((option) => (
              <SelectBox.Option
                key={option.value}
                label={option.label}
                lang={option.value}
                value={option}
              />
            ))}
          </ul>
        </>
      )}
    </SelectBox>
  );
};

export default LocaleSelect;
