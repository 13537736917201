import { css, cx } from '@eti/styles';
import { Button, Card, Heading, Inline, LinkButton, PageBlock, Stack, vars } from '@etg/wings';
import { DangerousHtmlContent, Notification, LoadingDots } from '@eti/components';
import { useTranslation, useDirection } from '@eti/providers';
import { AirplaneLanding, AirplaneTakeoff, ArrowSquareOut, CaretDown } from '@phosphor-icons/react';
import { Collection, DevBar } from '@eti/debug';
import { useHistory, Redirect } from 'react-router-dom';
import useFlightStatus from '../hooks/useFlightStatus';
import { isDev } from '../../../common/debug/utils';
import { DebugCommonComponents } from '../../../common/debug/components';
import { routes } from '../../../constants/routesAndApisConstants';

const blockStyles = css`
  background-color: ${vars.colors.pageBackdrop};
  height: 100%;
  padding-top: 0;
`;

const stackStyles = css`
  margin: 0 auto;
  max-width: 344px;
`;

const regularSpanStyles = css`
  font-weight: 500;
`;

const inlineOverviewStyles = css`
  justify-content: space-between;
`;

const statusIndicatorStyles = (arrivalRealTime, departureRealTime, flightStatus) => css`
  align-self: center;
  background-color: ${{
    Scheduled: departureRealTime
      ? vars.colors.signal.caution.light
      : vars.colors.signal.positive.light,
    Active: arrivalRealTime ? vars.colors.signal.caution.light : vars.colors.signal.positive.light,
    Redirected: vars.colors.signal.caution.light,
    Landed: vars.colors.signal.positive.light,
  }[flightStatus] || vars.colors.signal.critical.light};
  border-radius: 5px;
  height: 10px;
  margin: 0 4px;
  width: 10px;
`;

const inlineAlignItemsStyles = css`
  align-items: center;
  justify-content: space-evenly;
`;

const inlineAirportStyles = css`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

const terminalInfoStyles = css`
  background-color: #e9f5ff;
  border-radius: 15px;
  font-size: 0.75rem;
  margin: 17px 40px;
  max-width: 92px;
  min-height: 108px;
  padding: 16px;
  width: 100%;

  @media (max-width: 320px) {
    margin: 17px 20px;
  }
`;

const flightNumberStyles = css`
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  width: 33.33%;
`;

const airportHeadingsStyles = css`
  font-size: 2.25rem;
  font-weight: 700;
`;

const textCenterStyles = css`
  min-width: 50%;
  text-align: center;
`;

const textFontWeightStyles = css`
  font-weight: 700;
`;

const textInfoMarginStyles = css`
  margin-top: 10px;
`;

const borderStyles = css`
  align-self: center;
  border: 0.5px solid #a6a6a6;
  height: 114px;
`;

const currentTimeInfoStyles = css`
  font-size: 1.125rem;
  font-weight: 700;
`;

const oldTimeInfoStyles = css`
  color: #707070;
  font-size: 1rem;
  font-weight: 700;
  text-decoration-line: line-through;
`;

const linkStyles = css`
  display: inline-block;
  margin: 16px 0;
`;

const innerWrapperStyles = css`
  align-items: center;
  display: flex;
  gap: 5px;
`;

const statusAlignmentStyles = css`
  span {
    display: flex;
    justify-content: center;
  }
`;

const iconStyles = css`
  transform: rotate(90deg);

  [dir='rtl'] & {
    transform: rotate(-90deg);
  }
`;

const passengerContainerStyles = css`
  div {
    margin-bottom: 16px;
  }

  padding: 20px 16px 5px;
`;

const getContainerStyle = css`
  align-items: center;
  color: ${vars.colors.links.default};
  display: flex;
  font-size: 2.5rem;
  height: 100%;
  justify-content: center;
`;

const regularFontSizeStyles = css`
  font-size: 0.875rem;
`;

const currentStatusStyles = css`
  font-weight: 700;
  line-height: normal;
`;

const mainCardSectionStyles = css`
  padding: 24px 0;
`;

const departureAircraftStyles = css`
  padding-left: 55px;
  width: 33.33%;
`;

const arrivalAircraftStyles = css`
  padding-right: 55px;
  width: 33.33%;
`;

const airportNameStyles = css`
  h5 {
    padding: 0 7px;
  }
`;

const defaultStatus = 'Scheduled';

const MtpFlightStatusPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { status, isLoading, hasError } = useFlightStatus();
  const { isRTL } = useDirection();

  if (isLoading) {
    return <LoadingDots containerStyle={getContainerStyle} />;
  }

  if (
    !history.location.state ||
    history.location.state.index === undefined ||
    status === undefined
  ) {
    return <Redirect to={routes.MOBILE_TRAVEL_PLAN} />;
  }

  const { index } = history.location.state;
  const flight = status?.flights[index];
  const { time: timeDeparture, realTime: realTimeDeparture } = flight.statuses[0].departure;
  const { time: timeArrival, realTime: realTimeArrival } = flight.statuses[0].arrival;
  const getStatus = (flightStatus, departureRealTime) => {
    if (flightStatus === defaultStatus) {
      return departureRealTime
        ? t('MobileTravelPlan.FlightStatus.Status.Delayed')
        : t('MobileTravelPlan.FlightStatus.Status.OnTime');
    }

    return t(`MobileTravelPlan.FlightStatus.Status.${flightStatus}`);
  };
  const currentStatus = getStatus(flight.statuses[0].status || defaultStatus, realTimeDeparture);
  const regularTimeInfoStyles = (useStrikethroughStyles) => {
    return useStrikethroughStyles ? oldTimeInfoStyles : currentTimeInfoStyles;
  };
  const renderRegularTime = (
    delayedTimeOne,
    delayedTimeTwo,
    regularTime,
    useStrikethroughStyles,
    testId,
  ) =>
    !delayedTimeOne || delayedTimeTwo ? (
      <Heading
        className={cx(textCenterStyles, regularTimeInfoStyles(useStrikethroughStyles))}
        data-testid={testId}
        level={5}
      >
        {regularTime}
      </Heading>
    ) : (
      <div className={textCenterStyles} />
    );

  const getHeadingDataTestId = (delayedTimeId, regularTimeId) => {
    return realTimeDeparture ? delayedTimeId : regularTimeId;
  };
  const delayedTimeInfoStyles =
    realTimeDeparture || realTimeArrival ? currentTimeInfoStyles : oldTimeInfoStyles;
  const renderDelayedTime = (delayedTime, regularTime, delayedTimeId, regularTimeId) =>
    realTimeDeparture || realTimeArrival ? (
      <Heading
        className={cx(textCenterStyles, delayedTimeInfoStyles)}
        data-testid={getHeadingDataTestId(delayedTimeId, regularTimeId)}
        level={5}
      >
        {delayedTime || regularTime}
      </Heading>
    ) : (
      <div className={textCenterStyles} />
    );

  return (
    <PageBlock className={blockStyles} withPaddingY>
      <Stack className={stackStyles} spacing={0}>
        <LinkButton
          className={linkStyles}
          data-testid="back-button"
          onClick={() => {
            history.push(routes.MOBILE_TRAVEL_PLAN);
          }}
        >
          <div className={innerWrapperStyles}>
            <CaretDown className={iconStyles} size="1.2em" variant="light" />
            {t('Button.Text.Back')}
          </div>
        </LinkButton>
        {hasError && (
          <Notification data-testid="login-notification" variant="critical">
            <DangerousHtmlContent content={t('MobileTravelPlan.Login.Generic.Error')} />
          </Notification>
        )}
        <Card>
          <Card.Section>
            <Inline className={inlineOverviewStyles}>
              <Heading className={regularFontSizeStyles} level={4}>
                {flight.statuses[0].date}
              </Heading>
              <Heading className={regularFontSizeStyles} level={4}>
                <span className={regularSpanStyles} data-testid="booking-no-title">
                  {t('MobileTravelPlan.FlightStatus.BookingNo')}
                </span>{' '}
                {flight.statuses[0].bookingNr}
              </Heading>
            </Inline>
          </Card.Section>
          <Card.Section className={mainCardSectionStyles}>
            <Inline className={inlineAlignItemsStyles} spacing={0}>
              <AirplaneTakeoff
                className={departureAircraftStyles}
                color={vars.colors.decoration.light}
                mirrored={isRTL}
                size={30}
                weight="fill"
              />
              <div className={flightNumberStyles}>{flight.statuses[0].flightNumber}</div>
              <AirplaneLanding
                className={arrivalAircraftStyles}
                color={vars.colors.decoration.light}
                mirrored={isRTL}
                size={30}
                weight="fill"
              />
            </Inline>
            <Inline className={inlineAirportStyles} spacing={0}>
              <div
                className={cx(airportHeadingsStyles, textCenterStyles)}
                data-testid="departure-airport"
              >
                {flight.statuses[0].departure.airport.code}
              </div>
              <div
                className={cx(airportHeadingsStyles, textCenterStyles)}
                data-testid="arrival-airport"
              >
                {flight.statuses[0].arrival.airport.code}
              </div>
            </Inline>
            <Inline className={cx(inlineAirportStyles, airportNameStyles)} spacing={0}>
              <Heading className={textCenterStyles} level={5}>
                {flight.statuses[0].departure.airport.name}
              </Heading>
              <Heading className={cx(textCenterStyles, airportNameStyles)} level={5}>
                {flight.statuses[0].arrival.airport.name}
              </Heading>
            </Inline>
            <Inline className={inlineAirportStyles} spacing={0}>
              {renderRegularTime(
                realTimeArrival,
                realTimeDeparture,
                timeDeparture,
                realTimeDeparture,
                'departure-time',
              )}
              {renderRegularTime(
                realTimeDeparture,
                realTimeArrival,
                timeArrival,
                realTimeArrival,
                'arrival-time',
              )}
            </Inline>
            <Inline className={inlineAirportStyles} spacing={0}>
              {renderDelayedTime(
                realTimeDeparture,
                timeDeparture,
                'departure-real-time',
                'departure-time',
              )}
              {renderDelayedTime(realTimeArrival, timeArrival, 'arrival-real-time', 'arrival-time')}
            </Inline>
            <Inline className={inlineAirportStyles} spacing={0}>
              <div className={terminalInfoStyles}>
                <Heading
                  className={textCenterStyles}
                  data-testid="terminal-title-arrival"
                  level={5}
                >
                  {t('MobileTravelPlan.FlightStatus.Terminal')}
                </Heading>
                <Heading
                  className={cx(textCenterStyles, textFontWeightStyles)}
                  data-testid="terminal-arrival-id"
                  level={5}
                >
                  {flight.statuses[0].departure.terminal || '-'}
                </Heading>
                <Heading className={cx(textCenterStyles, textInfoMarginStyles)} level={5}>
                  {t('MobileTravelPlan.FlightStatus.Gate')}
                </Heading>
                <Heading
                  className={cx(textCenterStyles, textFontWeightStyles)}
                  data-testid="gate-id"
                  level={5}
                >
                  {flight.statuses[0].departure.gate || '-'}
                </Heading>
              </div>
              <div className={borderStyles} />
              <div className={terminalInfoStyles}>
                <Heading
                  className={textCenterStyles}
                  data-testid="terminal-title-departure"
                  level={5}
                >
                  {t('MobileTravelPlan.FlightStatus.Terminal')}
                </Heading>
                <Heading
                  className={cx(textCenterStyles, textFontWeightStyles)}
                  data-testid="terminal-departure-id"
                  level={5}
                >
                  {flight.statuses[0].departure.terminal || '-'}
                </Heading>
                <Heading
                  className={cx(textCenterStyles, textInfoMarginStyles)}
                  data-testid="baggage-title"
                  level={5}
                >
                  {t('MobileTravelPlan.FlightStatus.Baggage')}
                </Heading>
                <Heading
                  className={cx(textCenterStyles, textFontWeightStyles)}
                  data-testid="baggage-id"
                  level={5}
                >
                  {flight.statuses[0].departure.baggage || '-'}
                </Heading>
              </div>
            </Inline>
            <Inline className={inlineAirportStyles}>
              <Heading
                className={cx(textCenterStyles, statusAlignmentStyles)}
                data-testid="status-title"
                level={5}
              >
                {t('MobileTravelPlan.FlightStatus.Status')}{' '}
                <div
                  className={statusIndicatorStyles(
                    realTimeArrival,
                    realTimeDeparture,
                    flight.statuses[0].status || defaultStatus,
                  )}
                />{' '}
                <div className={cx(regularFontSizeStyles, currentStatusStyles)}>
                  {currentStatus}
                </div>
              </Heading>
            </Inline>
          </Card.Section>
          {flight.travelers && (
            <Card.Section className={passengerContainerStyles}>
              {flight.travelers.map((traveler) => (
                <Stack
                  className={inlineAirportStyles}
                  key={`traveler-${traveler.name}`}
                  spacing={4}
                >
                  <Heading className={textFontWeightStyles} level={5}>
                    {traveler.name}
                  </Heading>
                  <Heading level={5}>
                    {traveler.baggageAllowance ?? t('MobileTravelPlan.FlightStatus.NoBaggage')}
                  </Heading>
                </Stack>
              ))}
            </Card.Section>
          )}
          {status?.postBookingEnabled && (
            <Card.Section>
              <Stack className={inlineAirportStyles} spacing={8}>
                <Heading level={5}>{t('MobileTravelPlan.FlightStatus.Text')}</Heading>
                <Button
                  data-testid="travel-store-btn"
                  iconPrefix={<ArrowSquareOut weight="bold" />}
                  onClick={() => {
                    window.open('/mobiletravelplan.postbooking.action');
                  }}
                  type="submit"
                  variant="primary"
                >
                  {t('MobileTravelPlan.FlightStatus.Button')}
                </Button>
              </Stack>
            </Card.Section>
          )}
        </Card>
      </Stack>
      {isDev && (
        <DevBar>
          <Collection>
            <DebugCommonComponents />
          </Collection>
        </DevBar>
      )}
    </PageBlock>
  );
};

export default MtpFlightStatusPage;
