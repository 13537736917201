export const titleLogoByFilename: Record<string, string> = {
  logo_AA: 'Aura',
  logo_AG: 'Argencard',
  logo_AX: 'American Express',
  logo_BC: 'BC Card',
  logo_BT: 'Bancontact',
  logo_CA: 'MasterCard',
  logo_CB: 'Carte Bleue Visa',
  logo_CD: 'Cabal',
  logo_CE: 'CB',
  logo_CF: 'CMR Falabella',
  logo_CI: 'Cordial',
  logo_CL: 'Cabal',
  logo_CO: 'Cordobesa',
  logo_CS: 'Cencosud',
  logo_DC: 'Diners Club International',
  logo_DK: 'Dankort',
  logo_DS: 'Discover',
  logo_EC: 'Eurocard',
  logo_ED: 'Elo',
  logo_EO: 'Elo',
  logo_HC: 'Hipercard',
  logo_HD: 'Hipercard',
  logo_HN: 'Hyundai Card',
  logo_JC: 'JCB',
  logo_KB: 'KB Kookmin Card',
  logo_KH: 'Hana Card',
  logo_LC: 'Lotte Card',
  logo_LI: 'Lider',
  logo_MA: 'Magna',
  logo_MD: 'MasterCard Debit',
  logo_MO: 'Maestro',
  logo_MR: 'MNP',
  logo_NC: 'NH Card',
  logo_NJ: 'Naranja',
  logo_NT: 'Nativa Nacion',
  logo_OA: 'OCA Card',
  logo_PO: 'Presto',
  logo_RD: 'Rupay',
  logo_RU: 'Rupay',
  logo_SC: 'Samsung Card',
  logo_SH: 'Shinhan Card',
  logo_TS: 'Tarjeta Shopping',
  logo_VD: 'Visa Debit',
  logo_VE: 'Visa Electron',
  logo_VI: 'Visa',
  logo_VID: 'Visa Debit',
  logo_VIE: 'Visa Electron',
  logo_WC: 'Woori Card',
};

export default titleLogoByFilename;
