import type { ChangeEvent } from 'react';
import { Inline, Stack, Text, textVariants, vars } from '@etg/wings';
import { css, cx, resetButtonStyle } from '@eti/styles';
import type { Formatter, Normalizer, WrappedFieldInputProps } from 'redux-form';
import { Field as ReduxFormField } from 'redux-form';
import { PlusCircle, MinusCircle } from '@phosphor-icons/react';
import { changeValueWithOperand } from '../../utils/passengers';
import { passengerFields } from '../../constants/formFields';

const rowStyles = css`
  border-top: 1px solid ${vars.colors.divider};
  font-size: 1rem;
  min-height: 52px;
  padding: 16px;

  &:last-child {
    border-bottom: 1px solid ${vars.colors.divider};
  }
`;

const controlWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  padding-inline-end: 100px;
  position: relative;
`;

const positioningStyles = css`
  align-items: center;
  height: 100%;
  inset-inline-end: 0;
  position: absolute;
  top: 1px;
`;

const getButtonStyles = (isDisabled: boolean = false) => css`
  color: ${isDisabled ? '#e5e5e5' : vars.colors.links.default};
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  display: flex;
  position: relative;

  &::after {
    border-color: ${vars.colors.focusRing};
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    content: '';
    height: 28px;
    left: -2px;
    opacity: 0;
    position: absolute;
    top: -2px;
    transition: opacity 0.2s ease-in-out;
    width: 28px;
  }

  &:focus-visible::after {
    opacity: 1;
  }

  &:active {
    transform: scale(${isDisabled ? '1' : '0.9'});
  }
`;

const hiddenInputStyles = css`
  clip: rect(0 0 0 0);
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
  z-index: -1;
`;

const currentValueStyles = css`
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  height: 100%;
  justify-content: center;
  width: 24px;
`;

interface ControlConstraints {
  add: { isDisabled: boolean };
  subtract: { isDisabled: boolean };
}

interface PassengerRowComponentProps {
  description?: string;
  input: WrappedFieldInputProps;
  label: string;
  name: string;
  passengerIndex: number;
  toggleConstraints?: ControlConstraints;
}

const PassengerRowComponent = ({
  description,
  input,
  label,
  name,
  passengerIndex,
  toggleConstraints,
}: PassengerRowComponentProps) => {
  const handleOnChange = (operand: string) => () => {
    const newValue = changeValueWithOperand(input.value, operand);

    input.onChange(newValue);
  };

  const fieldId = name === passengerFields.CHILDREN_AGES ? `${name}-${passengerIndex}` : name;
  const inputId = `${fieldId}-passengers-input`;

  return (
    <Stack className={rowStyles}>
      <div className={controlWrapperStyles}>
        <label
          aria-describedby={description ? `passenger-${name}-description` : undefined}
          htmlFor={inputId}
        >
          {label}
        </label>
        <Inline className={positioningStyles} spacing={4}>
          <button
            aria-disabled={toggleConstraints?.subtract.isDisabled}
            className={cx(
              resetButtonStyle,
              getButtonStyles(toggleConstraints?.subtract.isDisabled),
            )}
            data-testid={`${fieldId}-passengers-subtract`}
            onClick={handleOnChange('subtract')}
            type="button"
          >
            <MinusCircle color="currentColor" size="24px" />
          </button>
          <input
            className={hiddenInputStyles}
            id={inputId}
            readOnly
            tabIndex={-1}
            type="number"
            value={input.value}
          />
          <div className={currentValueStyles} data-testid={`${fieldId}-passengers-currentValue`}>
            {input.value}
          </div>
          <button
            aria-disabled={toggleConstraints?.add.isDisabled}
            className={cx(resetButtonStyle, getButtonStyles(toggleConstraints?.add.isDisabled))}
            data-testid={`${fieldId}-passengers-add`}
            onClick={handleOnChange('add')}
            type="button"
          >
            <PlusCircle color="currentColor" size="24px" />
          </button>
        </Inline>
      </div>
      {description && (
        <Text
          as="p"
          id={`passenger-${name}-description`}
          style={{ color: '#737373' }}
          variant={textVariants._14}
        >
          {description}
        </Text>
      )}
    </Stack>
  );
};

interface PassengerRowProps {
  description?: string;
  format?: Formatter;
  label: string;
  name: string;
  normalize?: Normalizer;
  onChange?: (event: ChangeEvent<HTMLButtonElement>, newValue: number) => void;
  passengerIndex?: number;
  toggleConstraints?: ControlConstraints;
}

const PassengerRow = ({ format, name, normalize, onChange, ...rest }: PassengerRowProps) => {
  return (
    <ReduxFormField
      component={PassengerRowComponent as any}
      format={format}
      name={name}
      normalize={normalize}
      onChange={onChange as any}
      props={{ name, ...rest } as any}
    />
  );
};

export default PassengerRow;
