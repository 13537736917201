import type { RouterChildContext } from 'react-router-dom';
import type { Route } from '@eti/providers';
import type { Brand, SelfServiceRebookingTraveler } from '@eti/schema-types';
import { AgeType } from '@eti/schema-types';
import { routes } from '../../constants/routesAndApisConstants';
import type {
  AvailableExtraProductType,
  BuySpecificationType,
} from '../../pages/order-details/models';
import type {
  TravelerDetailsQuerySelectedTripType,
  TravelerDetailsQueryTravelerDetailsType,
} from '../types/queries/getSelectedTripOnTravelerDetailsPageQuery/models';
import type {
  BookingStepQuerySelectedTripType,
  BookingStepQueryTravelerDetailsType,
} from '../types/queries/getBookingStepPageDataQuery/models';
import type { TransformedBookingStepCountryType } from '../types/transformations/models';
import type { ExtraProduct } from './models';

export const redirectToActualUrl = (
  brand: Brand,
  history: RouterChildContext['router']['history'],
  actualUrl: string,
  orderBrandCode?: string | null,
  orderBrandName?: string | null,
) => {
  if (brand.code.toLowerCase() === orderBrandCode?.toLowerCase()) {
    window.location.assign(actualUrl);
  } else {
    history.push(routes.NEW_BRAND_REDIRECTION, {
      brand: {
        code: orderBrandCode,
        name: orderBrandName,
        url: actualUrl,
      },
      redirectionType: 'order-login',
    });
  }
};

export const getDomesticInfo = (searchContextRoutes: (Route | null)[] | undefined | null) => {
  const domesticRoutes = searchContextRoutes?.filter(
    (route) => route?.originCountry === route?.destinationCountry,
  );
  const isDomestic = searchContextRoutes?.length === domesticRoutes?.length;
  const domesticRoute = isDomestic && domesticRoutes ? domesticRoutes[0] : null;

  return {
    countryCode: domesticRoute?.originCountryCode,
    isDomestic,
  };
};

type sortAccordingToArrayPropsType =
  | {
      buySpecification: BuySpecificationType;
      code: string;
      description: string;
      name: string;
      numberOfIncludedTravelers: number;
      shortDescription: string;
    }
  | AvailableExtraProductType;

export const sortAccordingToArray =
  (array: string[]) =>
  (
    a: ExtraProduct | sortAccordingToArrayPropsType,
    b: ExtraProduct | sortAccordingToArrayPropsType,
  ) => {
    const aCode = 'code' in a ? a.code.toLowerCase() : '';
    const bCode = 'code' in b ? b.code.toLowerCase() : '';

    if (array.indexOf(aCode) === -1) {
      return 1;
    }
    if (array.indexOf(bCode) === -1) {
      return -1;
    }
    return array.indexOf(aCode) - array.indexOf(bCode);
  };

type SelectedTripFromTDPageQueryType = TravelerDetailsQuerySelectedTripType;
type FrequentFlyerCardTypesType =
  NonNullable<SelectedTripFromTDPageQueryType>['frequentFlyerCardTypes'];

export const getFrequentFlyerCardTypes = (frequentFlyerCards: FrequentFlyerCardTypesType = []) => {
  return (
    frequentFlyerCards?.map((frequentFlyerCardType) => ({
      label: frequentFlyerCardType?.name ?? '',
      value: frequentFlyerCardType?.id ?? '',
    })) || []
  );
};

export const transformTravelersData = (
  selectedTrip: TravelerDetailsQuerySelectedTripType | BookingStepQuerySelectedTripType,
  travelerDetails: TravelerDetailsQueryTravelerDetailsType | BookingStepQueryTravelerDetailsType,
  countries: (TransformedBookingStepCountryType | null)[],
  selfServiceRebookingTravelers: (SelfServiceRebookingTraveler | null)[] = [],
) => {
  const selectedTripTravelers = selectedTrip?.travelers || [];
  const frequentFlyerCardTypes = getFrequentFlyerCardTypes(
    selectedTrip?.frequentFlyerCardTypes ?? [],
  );

  return selectedTripTravelers.map((selectedTripTraveler) => {
    const travelerDetailsTraveler = travelerDetails?.travelers?.find(
      (traveler) => selectedTripTraveler?.id === traveler?.id,
    );

    const { shouldShowTravelDocumentDateOfBirth, ...restTravelDocument } = {
      ...travelerDetailsTraveler?.travelDocument,
    };

    const ssrTraveler = selfServiceRebookingTravelers?.find(
      (selfServiceRebookingTraveler) =>
        selectedTripTraveler?.id === selfServiceRebookingTraveler?.id,
    );

    return {
      age: selectedTripTraveler?.age,
      ageType: selectedTripTraveler?.ageType || AgeType.Adt,
      birthDate: selectedTripTraveler?.birthDate,
      contactDetails: selectedTripTraveler.contactDetails,
      countries,
      dateOfBirth: {
        shouldShowFormInput: Boolean(shouldShowTravelDocumentDateOfBirth),
        hasValueFromSelfServiceRebookingOrder: Boolean(ssrTraveler?.birthDate),
      },
      firstName: selectedTripTraveler?.firstName,
      frequentFlyerCardTypes,
      gender: selectedTripTraveler?.gender,
      id: travelerDetailsTraveler?.id,
      lastName: selectedTripTraveler?.lastName,
      nationality:
        'nationality' in selectedTripTraveler
          ? (selectedTripTraveler?.nationality as string)
          : undefined,
      shouldShowFrequentFlyerCard: Boolean(travelerDetailsTraveler?.shouldShowFrequentFlyerCard),
      showDateOfBirth: shouldShowTravelDocumentDateOfBirth ?? false,
      travelDocument: restTravelDocument,
    };
  });
};

export const getBrandedBanners = async (brand: string, language: string) => {
  const loadBanner = async (deviceType: string) => {
    try {
      const banner = await import(
        `@eti/assets/images/banner/${brand}/${deviceType}_${language}.webp`
      );
      return banner.default;
    } catch {
      const fallbackBanner = await import(
        `@eti/assets/images/banner/${brand}/${deviceType}_en.webp`
      );
      return fallbackBanner.default;
    }
  };

  return await Promise.all([loadBanner('mobile'), loadBanner('tablet'), loadBanner('desktop')])
    .then(([mobile, tablet, desktop]) => {
      return { mobile, tablet, desktop };
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load banners for ${brand}: ${error}`);
      return null;
    });
};
