import createValidator from 'revalidate/lib/createValidator';
import { differenceInYears, isValid, parse } from 'date-fns';
import { date } from '@eti/utils';
import { INVALID_INFANT_BIRTH_DATE } from '../constants/defaultErrorMessages';
const isValidInfantBirthDate = function (inBoundDate, format) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  return createValidator(message => input => {
    if (!input) {
      return null;
    }
    const birthDate = parse(input, format, new Date());
    const validBirthDate = isValid(birthDate);
    if (!validBirthDate) {
      return message || INVALID_INFANT_BIRTH_DATE;
    }
    const parsedInBoundDate = date.convertToDate(inBoundDate);
    const validInBoundDate = isValid(parsedInBoundDate);
    if (!validInBoundDate) {
      return null;
    }
    return differenceInYears(parsedInBoundDate, birthDate) >= 2 ? message || INVALID_INFANT_BIRTH_DATE : null;
  }, INVALID_INFANT_BIRTH_DATE);
};
export default isValidInfantBirthDate;