import { TRAVEL_INSURANCE_COVER_GENIUS } from '../products';
import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import TravelInsuranceCoverGenius from './components/TravelInsuranceCoverGenius';
const {
  name: formSection
} = TRAVEL_INSURANCE_COVER_GENIUS;
export const travelInsuranceCoverGenius = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  ...TRAVEL_INSURANCE_COVER_GENIUS
};
export default TravelInsuranceCoverGenius;