import { MAX_PASSENGERS, MIN_ADULTS, MIN_CHILDREN, MIN_INFANTS } from '../constants/passengers';
import type { PassengerConstraints, Passengers } from '../models';

export const changeValueWithOperand = (value: number, operand: string) =>
  operand === 'add' ? value + 1 : value - 1;

export const isValidAdultSelection = (
  newNumberOfAdults: number,
  passengers: Passengers,
  constraints?: PassengerConstraints,
) => {
  const maxNumberOfSeats = constraints?.maxNumberOfTravelers;
  const { children, infants } = passengers;
  const isValidMinMaxCheck =
    newNumberOfAdults >= MIN_ADULTS && newNumberOfAdults + children + infants <= MAX_PASSENGERS;

  return maxNumberOfSeats
    ? isValidMinMaxCheck && newNumberOfAdults + children <= maxNumberOfSeats
    : isValidMinMaxCheck;
};

export const isValidChildSelection = (
  newNumberOfChildren: number,
  passengers: Passengers,
  constraints?: PassengerConstraints,
) => {
  const maxNumberOfSeats = constraints?.maxNumberOfTravelers;
  const { adults, infants } = passengers;
  const isValidMinMaxCheck =
    newNumberOfChildren >= MIN_CHILDREN && newNumberOfChildren + adults + infants <= MAX_PASSENGERS;

  return maxNumberOfSeats
    ? isValidMinMaxCheck && newNumberOfChildren + adults <= maxNumberOfSeats
    : isValidMinMaxCheck;
};

export const isValidInfantSelection = (newNumberOfInfants: number, passengers: Passengers) => {
  const { adults, children } = passengers;

  return (
    newNumberOfInfants <= adults &&
    newNumberOfInfants >= MIN_INFANTS &&
    newNumberOfInfants + adults + children <= MAX_PASSENGERS
  );
};

export const getAdultsToggleConstraints = (
  passengers: Passengers,
  constraints?: PassengerConstraints,
) => {
  const { adults, children, infants } = passengers;

  return {
    add: {
      isDisabled:
        adults + children === constraints?.maxNumberOfTravelers ||
        adults + children + infants === MAX_PASSENGERS,
    },
    subtract: { isDisabled: passengers.adults === 1 },
  };
};

export const getChildrenToggleConstraints = (
  passengers: Passengers,
  constraints?: PassengerConstraints,
) => {
  const { adults, children, infants } = passengers;

  return {
    add: {
      isDisabled:
        adults + children === constraints?.maxNumberOfTravelers ||
        adults + children + infants === MAX_PASSENGERS,
    },
    subtract: { isDisabled: children === 0 },
  };
};

export const getInfantsToggleConstraints = (passengers: Passengers) => {
  const { adults, children, infants } = passengers;

  return {
    add: { isDisabled: infants === adults || adults + children + infants === MAX_PASSENGERS },
    subtract: { isDisabled: passengers.infants === 0 },
  };
};
