import { resolveSearchUrl } from '../widgets/search-form/utils/searchRedirect';
import {
  NUMBER_OF_STOPS,
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  SHORT_STOPOVER,
  STOPOVER_TYPES,
  TWO_NIGHTS_STOPOVER,
} from '../constants/searchFiltersConstants';
import { getFormattedSearchData } from './search';

export const handleOnSubmit =
  (onSearch, travelerTexts, currentSearchData, setSearchData, searchRedirectConfig) =>
  async (values) => {
    if (searchRedirectConfig) {
      const redirectUrl = await resolveSearchUrl(searchRedirectConfig, values);

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }

    const selectedStopovers = {
      [SHORT_STOPOVER]: values.shortStopover,
      [ONE_DAY_STOPOVER]: values.oneDayStopover,
      [ONE_NIGHT_STOPOVER]: values.oneNightStopover,
      [TWO_NIGHTS_STOPOVER]: values.twoNightsStopover,
    };

    setSearchData({
      ...getFormattedSearchData(values, travelerTexts),
      filters: {
        ...currentSearchData?.filters,
        ...(values.directFlight ? { [NUMBER_OF_STOPS]: '0' } : {}),
        ...(values.stopoverEnabled
          ? {
              [STOPOVER_TYPES]: Object.keys(selectedStopovers).filter(
                (stopover) => selectedStopovers[stopover],
              ),
            }
          : {}),
      },
    });

    sessionStorage.removeItem('hideTripReminder');
    sessionStorage.removeItem('selectedTripSelectionKey');
    onSearch(true);
  };
