import { etiColors } from '@eti/styles';
import type { ComponentProps, ReactNode } from 'react';
import Option from './Option';

interface NoOptionsMessageProps {
  children: ReactNode;
  innerProps: ComponentProps<typeof Option>;
}

const NoOptionsMessage = ({ children, innerProps }: NoOptionsMessageProps) => (
  <Option
    {...innerProps}
    label={<span style={{ color: etiColors.grayScale[9] }}>{children}</span>}
  />
);

export default NoOptionsMessage;
