import { format, type Locale as LocaleDateFns } from 'date-fns';
import { type Formatters } from 'react-day-picker';

export type Locale = LocaleDateFns;

const importLocale = async (language: string, code: string): Promise<Locale> => {
  const module = await import('date-fns/locale');

  if (language === 'no') {
    return module.nb as Locale;
  }

  if (module[language as keyof typeof module]) {
    return module[language as keyof typeof module] as Locale;
  }
  const localeKey = `${language}${code}`;
  return (module[localeKey as keyof typeof module] as Locale) ?? module.enGB;
};

const mapLocale = async (locale: string): Promise<Locale> => {
  const [language, code] = locale.split('_');
  return await importLocale(language, code);
};

const loadLocale = async (locale: string) => {
  try {
    return await mapLocale(locale);
  } catch {
    return undefined;
  }
};

export const getLocale = async (locale: string) => {
  return await loadLocale(locale);
};

const getDateFormatByLocale = (localeCode?: string) => {
  switch (localeCode) {
    case 'et':
    case 'pt-BR': {
      return 'cccccc';
    }
    case 'vi': {
      return 'ccccc';
    }
    default: {
      return 'eee';
    }
  }
};

export const formatWeekdayName: Formatters['formatWeekdayName'] = (date, options) => {
  const dateFormat = getDateFormatByLocale(options?.locale?.code);
  return format(date, dateFormat, options);
};
