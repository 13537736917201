import { date as dateUtils } from '@eti/utils';
import { startOfDay } from 'date-fns';
import {
  ONE_WAY,
  MULTI_STOP,
  OPEN_JAW_SINGLE_ORIGIN,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_DOUBLE,
} from '../constants/tripTypesConstants';
import { getTravelerPrices, getTravelerPricesTotal } from './travelers';

const multiCityTripTypes = [
  MULTI_STOP,
  OPEN_JAW_SINGLE_ORIGIN,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_DOUBLE,
];
const getFirstBound = (trip) => trip?.bounds[0];

export const getOrigin = (trip) => {
  if (multiCityTripTypes.includes(trip.type)) {
    return trip.bounds.map((bound) => bound.segments[0].origin);
  }

  return getFirstBound(trip).segments[0].origin;
};

export const getDestination = (trip) => {
  if (multiCityTripTypes.includes(trip.type)) {
    return trip.bounds.map((bound) => bound.segments[bound.segments.length - 1].destination);
  }

  const firstBound = getFirstBound(trip);
  return firstBound.segments[firstBound.segments.length - 1].destination;
};

export const getDepartureDate = (trip) => {
  const departureTimestamp = getFirstBound(trip).segments[0].departuredAt;
  return startOfDay(dateUtils.convertToDate(departureTimestamp));
};

export const getReturnDate = (trip) => {
  if (trip.type === ONE_WAY) {
    return null;
  }

  const lastBound = trip.bounds[trip.bounds.length - 1];
  const departureTimestamp = lastBound.segments[0].departuredAt;

  return startOfDay(dateUtils.convertToDate(departureTimestamp));
};

export const getTotalPrice = (trip) => {
  const { travelers, travelerPrices } = trip;
  return getTravelerPricesTotal(getTravelerPrices(travelers, travelerPrices));
};
