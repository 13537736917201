import { type RefObject, useEffect } from 'react';

/**
 *  @param elementRef A React.Ref attached to something that will be rendered on
 *  the page _only_ when the thing we want to close is rendered (could be the thing
 *  itself, or a child to that thing).
 *  @param callback The function to invoke when “Escape” is pressed.
 *  @param focusRef A React.Ref attached to the element that should receive focus
 *  when `elementRef` is closed.
 */
export const useCloseOnEsc = (
  elementRef: RefObject<HTMLElement>,
  callback: () => void,
  focusRef: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (document.body.contains(elementRef.current) && event.key === 'Escape') {
        callback();
        focusRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [callback, focusRef, elementRef]);
};
