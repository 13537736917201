import type { GetSettingsQuery } from '@eti/schema-types';
import { SettingsLocaleType } from '@eti/schema-types';

type AvailableLocales = GetSettingsQuery['settings']['regions'][number]['availableLocales'];

export const getRegionByCode = (
  regions: GetSettingsQuery['settings']['regions'],
  regionCode: string,
) => {
  return regions.find((region) => region.code === regionCode) ?? regions[0];
};

export const getLocaleName = (
  allLocales: AvailableLocales,
  locale: Omit<AvailableLocales[number], 'availableCurrencies'>,
) => {
  const hasUniqueName = allLocales.filter((loc) => loc.name === locale.name).length === 1;

  return hasUniqueName ? locale.name : `${locale.name} (${locale.region.name})`;
};

export const getRegionLocaleByCode = (
  region: GetSettingsQuery['settings']['regions'][number],
  localeCode: string,
) =>
  region.availableLocales.find((locale) => locale.code === localeCode) ??
  region.availableLocales[0];

export const getPrimaryLocaleOptions = (
  selectedRegion: GetSettingsQuery['settings']['selectedRegion'],
) => {
  return selectedRegion.availableLocales
    .filter((locale) => locale.type === SettingsLocaleType.Primary)
    .map((locale) => ({
      label: getLocaleName(selectedRegion.availableLocales, locale),
      value: locale.code,
    }));
};

export const getAdditionalLocaleOptions = (
  selectedRegion: GetSettingsQuery['settings']['selectedRegion'],
) => {
  return selectedRegion.availableLocales
    .filter((locale) => locale.type === SettingsLocaleType.Additional)
    .map((locale) => ({
      label: getLocaleName(selectedRegion.availableLocales, locale),
      value: locale.code,
    }));
};
