import groupArray from 'group-array';
import flatMapDeep from 'lodash/flatMapDeep';
import countBy from 'lodash/countBy';
import { date } from '@eti/utils';
import {
  CHANGE_OF_AIRPORT,
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  OVERNIGHT_STAY,
  SELF_TRANSFER,
  SHORT_STOPOVER,
  STOP,
  TRANSFER_TO_AIRPORT,
  TRANSFER_TO_BUS,
  TRANSFER_TO_TRAIN,
  TWO_NIGHTS_STOPOVER,
} from '../constants/eventSegmentTypes';
import { populateListWithId } from './idGenerator';

const getProductTravelers = (travelers) =>
  travelers.reduce(
    (obj, { pieces, price, travelerId, maxWeight, weightUnit }) => ({
      ...obj,
      [travelerId]: {
        id: travelerId,
        price: (price?.price?.value ?? 0) + (obj[travelerId]?.price?.price?.value ?? 0),
        ...(pieces > 0 && { quantity: pieces }),
        maxWeight,
        weightUnit,
      },
    }),
    {},
  );

export const getSegments = (order) =>
  flatMapDeep(order.trips[0].bounds, (bound) =>
    bound.segments.map((segment) => ({
      ...segment,
      boundId: bound.boundId,
    })),
  );

export const getBounds = (order) => order.trips[0].bounds;

const numberOfUnitsFor = {
  BuySpecificationCartTravelerBaggage: ({ travelerBaggageTripPrices }) =>
    travelerBaggageTripPrices.filter(
      (el, i, arr) => arr.findIndex((obj) => obj.travelerId === el.travelerId) === i,
    ).length,
  BuySpecificationCartTraveler: ({ travelerPrices }) =>
    travelerPrices ? travelerPrices.length : 0,
  BuySpecificationCartTravelerChoice: ({ travelerChoicePrices }) =>
    travelerChoicePrices ? travelerChoicePrices.length : 0,
  BuySpecificationCartTravelerSeatMap: ({ travelerSeatMapPrices }) =>
    travelerSeatMapPrices ? travelerSeatMapPrices.length : 0,
  BuySpecificationCartTravelerCabinBaggage: ({ travelerCabinBaggagePrices }) =>
    travelerCabinBaggagePrices ? travelerCabinBaggagePrices.length : 0,
  [null]: () => 1,
};

export const getExtraProducts = (extraProducts) => {
  return flatMapDeep(extraProducts, (extraProduct) => {
    const travelers = [
      extraProduct.buySpecification?.travelerBaggageTripPrices,
      extraProduct.buySpecification?.travelerPrices,
      extraProduct.buySpecification?.travelerChoicePrices,
      extraProduct.buySpecification?.travelerSeatMapPrices,
    ].find((x) => Array.isArray(x));
    const getNumberOfUnits = numberOfUnitsFor[extraProduct?.buySpecification?.__typename];
    const numberOfUnits = getNumberOfUnits ? getNumberOfUnits(extraProduct?.buySpecification) : 0;
    return {
      id: extraProduct.id,
      code: extraProduct.code,
      name: extraProduct.name,
      numberOfUnits,
      productSummaryAlternativeName: extraProduct.productSummaryAlternativeName,
      totalPrice: extraProduct?.price?.price?.value ?? 0,
      ...(travelers && {
        travelers: getProductTravelers(travelers),
      }),
      productSpecificTexts: extraProduct.productSpecificTexts,
    };
  });
};

export const getTravelersWithExtraProducts = (travelers = [], extraProducts = []) =>
  flatMapDeep(travelers, (traveler) => ({
    ...traveler,
    id: String(traveler.id),
    products: extraProducts
      .filter((product) => product.travelers && product.travelers[traveler.id])
      .map((product) => ({
        id: String(product.id),
        name: product.name,
        ...(product.travelers[traveler.id].quantity && {
          quantity: product.travelers[traveler.id].quantity,
        }),
        ...(product.travelers[traveler.id].maxWeight && {
          maxWeight: product.travelers[traveler.id].maxWeight,
        }),
        ...(product.travelers[traveler.id].weightUnit && {
          weightUnit: product.travelers[traveler.id].weightUnit,
        }),
      })),
  }));

export const getTravelersDisplayPrices = (travelers = [], travelerPrices = []) => {
  const travelerGroups = groupArray(
    travelers.map((traveler, index) => {
      const price =
        travelerPrices.find((travelerPrice) => String(travelerPrice.travelerId) === traveler.id) ||
        {};
      return {
        ...traveler,
        id: String(traveler.id || index),
        markup: price.price?.markup?.value ?? null,
        carrierPriceBreakdowns: price.carrierPriceBreakdowns || [],
        price: price.price?.price?.value ?? 0,
        vat: price.price?.vat?.value ?? 0,
        taxesAndFees: price.taxesAndFees || {},
      };
    }),
    'ageType',
  );
  return Object.entries(travelerGroups).map(([ageType, items]) => ({
    ageType,
    markup: items[0].markup,
    price: items[0].price - (items[0].vat + items[0].markup),
    quantity: items.length,
    totalPrice: items[0].price,
    totalPriceSum: items.reduce((total, { price }) => total + price, 0),
    vat: items[0].vat,
    carrierPriceBreakdowns: items[0].carrierPriceBreakdowns,
    taxesAndFees: items[0].taxesAndFees,
  }));
};

export const getOrigin = (bounds = []) => {
  const bound = bounds[0];

  if (!bound) {
    return null;
  }

  return bound.segments[0].origin;
};

export const getDestination = (bounds = []) => {
  const bound = bounds[0];

  if (!bound) {
    return null;
  }

  return bound.segments[bound.segments.length - 1].destination;
};

export const getDestinationCode = (routes = []) =>
  routes.length > 0 && routes[0].destinationCode ? routes[0].destinationCode.toLowerCase() : null;

export const getNumberOfTravelers = (travelers) => {
  const { ADT, CHD, INF } = countBy(travelers, ({ ageType }) => ageType);
  return {
    nrOfAdults: ADT || 0,
    nrOfChildren: CHD || 0,
    nrOfInfants: INF || 0,
  };
};

export const getAgesOfChildren = (childAges) =>
  childAges ? childAges.filter((age) => age >= 2) : [];

export const getAgesOfInfants = (childAges) =>
  childAges ? childAges.filter((age) => age < 2) : [];

export const getGASegmentItems = (segments) =>
  segments.map(
    ({
      cabinClassName,
      fareBasis,
      bookingClass,
      flightNumber,
      origin,
      destination,
      departuredAt,
    }) => ({
      travel_class: cabinClassName,
      fare_product: fareBasis,
      booking_code: bookingClass,
      flight_number: flightNumber,
      origin: origin.code,
      destination: destination.code,
      start_date: date.format(departuredAt, 'yyyy-MM-dd'),
    }),
  );

export const getAirTripType = (trip) => {
  if (trip.type === 'ONE_WAY') {
    return 'OneWay';
  }

  if (trip.type === 'RETURN') {
    return 'RoundTrip';
  }

  return 'Other';
};

export const IBE_CLIENT_TOP_MENU_HELP = 'IBE_CLIENT_TOP_MENU_HELP';
export const IBE_CLIENT_TOP_MENU = 'IBE_CLIENT_TOP_MENU';

const createMenus = (menu) => {
  const supportMenu = [];
  const mainMenu = [];

  menu.forEach((item) =>
    item.menuType === IBE_CLIENT_TOP_MENU_HELP ? supportMenu.push(item) : mainMenu.push(item),
  );

  return [mainMenu, supportMenu];
};

const getMenusWithSubMenu = (menu) =>
  menu.filter((item) => item.subMenu?.length > 0 && item.menuType !== IBE_CLIENT_TOP_MENU_HELP);

export const formatMenuItems = (menu = []) => {
  const menuPopulatedWithIds = populateListWithId(menu);

  const [mainMenu, supportMenu] = createMenus(menuPopulatedWithIds);
  const menuWithSubMenu = getMenusWithSubMenu(menuPopulatedWithIds);

  return {
    mainMenu,
    menuWithSubMenu,
    supportMenu,
  };
};

export const getStopoverType = (segments) => {
  const eventSegments = segments
    .filter((segment) => segment.__typename === 'EventSegment')
    .filter((segment) =>
      segment.types?.some(
        (segmentType) =>
          segmentType?.type === SHORT_STOPOVER ||
          segmentType?.type === ONE_DAY_STOPOVER ||
          segmentType?.type === ONE_NIGHT_STOPOVER ||
          segmentType?.type === TWO_NIGHTS_STOPOVER,
      ),
    );

  let stopoverType = null;

  if (eventSegments.length > 0) {
    eventSegments.forEach((stopover) => {
      stopover.types.forEach((segmentType) => {
        if (segmentType?.type === TWO_NIGHTS_STOPOVER) {
          stopoverType = TWO_NIGHTS_STOPOVER;
          return;
        }
        if (segmentType?.type === ONE_NIGHT_STOPOVER) {
          stopoverType = ONE_NIGHT_STOPOVER;
          return;
        }
        if (segmentType?.type === ONE_DAY_STOPOVER) {
          stopoverType = ONE_DAY_STOPOVER;
          return;
        }
        if (segmentType?.type === SHORT_STOPOVER) {
          stopoverType = SHORT_STOPOVER;
        }
      });
    });
  }

  return stopoverType;
};

export const eventSegmentTitlesKeys = {
  [CHANGE_OF_AIRPORT]: 'TripDetails.Details.ChangeOfAirport',
  [ONE_DAY_STOPOVER]: 'TripDetails.Details.StopoverInRome.OneDayStopover.Title',
  [ONE_NIGHT_STOPOVER]: 'TripDetails.Details.StopoverInRome.OneNightStopover.Title',
  [OVERNIGHT_STAY]: 'TripDetails.Details.Overnight',
  [SELF_TRANSFER]: 'SelfTransfer.Result.Header.Text',
  [SHORT_STOPOVER]: 'TripDetails.Details.StopoverInRome.ShortStopover.Title',
  [STOP]: 'TripDetails.Stop.Label',
  [TRANSFER_TO_AIRPORT]: 'TripDetails.Details.TransferToAirport',
  [TRANSFER_TO_BUS]: 'TripDetails.Details.TransferToBus',
  [TRANSFER_TO_TRAIN]: 'TripDetails.Details.TransferToTrain',
  [TWO_NIGHTS_STOPOVER]: 'TripDetails.Details.StopoverInRome.TwoNightsInRome.Title',
};
