import { createValidator, composeValidators, isAlphaNumeric, isRequired, isNumeric, hasLengthLessThan } from '@eti/validation';
import { ALPHA_NUMERIC_VALIDATION_MESSAGE, INVALID_DOCUMENT_VALIDATION_MESSAGE, REQUIRED_DOCUMENT_VALIDATION_MESSAGE, RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT, RUSSIAN_PASSPORT_NUMBER_INPUT, BIRTH_CERTIFICATE_NUMBER_INPUT, MILITARY_ID_NUMBER_INPUT, INTERNATIONAL_PASSPORT_NUMBER_INPUT, FOREIGN_PASSPORT_NUMBER_INPUT, FOREIGN_DOCUMENT_NUMBER_INPUT, REQUIRED_CPF_VALIDATION_MESSAGE, INVALID_CPF_VALIDATION_MESSAGE, CPF } from './constants';
const MAX_THIRTY_CHARACTERS = 31;
const isValidInternationalRussianPassportNumber = text => composeValidators(isRequired({
  message: text.passportNumberIsRequired
}), isNumeric({
  message: text.passportNumberIsInvalid
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /^\d{9}$/.test(value) ? undefined : message, text.passportNumberIsInvalid));
const isValidRussianPassportNumber = text => composeValidators(isRequired({
  message: text.passportNumberIsRequired
}), isNumeric({
  message: text.passportNumberIsInvalid
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /^\d{10}$/.test(value) ? undefined : message, text.passportNumberIsInvalid));
const isValidCPFNumber = text => composeValidators(isRequired({
  message: REQUIRED_CPF_VALIDATION_MESSAGE
}), isNumeric({
  message: INVALID_CPF_VALIDATION_MESSAGE
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /^\d{11}$/.test(value) ? undefined : message, INVALID_CPF_VALIDATION_MESSAGE));
const isValidBirthCertificateNumber = text => composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /^.{6,14}$/.test(value) ? undefined : message, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidMilitaryId = text => composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /^.{8,14}$/.test(value) ? undefined : message, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidInternationalPassport = text => composeValidators(isRequired({
  message: text.passportNumberIsRequired
}), isAlphaNumeric({
  message: text.passportNumberIsInvalid
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /[a-zA-Z0-9]{8}/.test(value) ? undefined : message, text.passportNumberIsInvalid));
const isValidForeignDocument = text => composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /[0-9]/.test(value) ? undefined : message, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidForeignPassport = text => composeValidators(isRequired({
  message: text.passportNumberIsRequired
}), isAlphaNumeric({
  message: text.passportNumberIsInvalid
}), hasLengthLessThan(MAX_THIRTY_CHARACTERS)({
  message: text.travelDocumentNumberMaxChar
}), createValidator(message => value => /[a-zA-Z0-9]/.test(value) ? undefined : message, text.passportNumberIsInvalid));
export const rules = (id, text) => {
  return {
    [`${CPF}-${id}`]: isValidCPFNumber(text)(`${CPF}-${id}`),
    [`${RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`]: isValidInternationalRussianPassportNumber(text)(`${RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${RUSSIAN_PASSPORT_NUMBER_INPUT}-${id}`]: isValidRussianPassportNumber(text)(`${RUSSIAN_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${BIRTH_CERTIFICATE_NUMBER_INPUT}-${id}`]: isValidBirthCertificateNumber(text)(`${BIRTH_CERTIFICATE_NUMBER_INPUT}-${id}`),
    [`${MILITARY_ID_NUMBER_INPUT}-${id}`]: isValidMilitaryId(text)(`${MILITARY_ID_NUMBER_INPUT}-${id}`),
    [`${INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`]: isValidInternationalPassport(text)(`${INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`]: isValidForeignPassport(text)(`${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`]: isValidForeignDocument(text)(`${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`)
  };
};