import PropTypes from 'prop-types';
import Media from 'react-media';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import { useEffect, useRef, useState, useCallback } from 'react';
import {
  useSiteContext,
  useProperty,
  useHeightContext,
  useSearch,
  useTranslation,
} from '@eti/providers';
import { vars, Inline, breakpoints, SiteAuxiliaryButton } from '@etg/wings';
import { useScroll } from '@eti/utils';
import { css } from '@eti/styles';
import { useLocation } from 'react-router-dom';
import { List } from '@phosphor-icons/react';
import { formatMenuItems } from '../../utils/format';
import { useLanguage } from '../../common/localization/custom-hooks/language';
import { routes } from '../../constants/routesAndApisConstants';
import SettingsGraphqlContainer from '../../common/settings/components/SettingsGraphqlContainer';
import LanguageSelectionButton from '../../common/localization/components/LanguageSelectionButton';
import MyBookingsButton from '../../common/authentication/components/MyBookingsButton';
import DesktopMenu from './site_menu/DesktopMenu';
import MobileMenu from './site_menu/MobileMenu';
import SupportMenu from './site_menu/SupportMenu';
import SiteLogo from './SiteLogo';
import { SiteHeaderV2 } from './SiteHeaderV2';

const headerStyles = (hasHeaderTakeOverBanner, isSearchBarOpen, notificationHeight) => css`
  background: ${vars.colors.header.base};
  box-shadow: ${isSearchBarOpen ? 'none' : vars.header.shadow};
  position: ${hasHeaderTakeOverBanner ? 'sticky' : 'fixed'};
  top: ${notificationHeight !== 0 ? `${notificationHeight}px` : '0'};
  transition: transform 0.2s ease-in-out;
  width: 100%;
  z-index: 8;

  @media (min-width: ${breakpoints._768}) {
    min-height: 68px;
  }

  &.nav-up:not(:has(.mobile-menu-open)) {
    transform: translateY(-100%);
  }

  @media print {
    display: none;
  }
`;

const myBookingsButtonStyles = css`
  &:focus-visible {
    outline-color: ${vars.colors.header.content};
  }
`;

const contentStyles = (shouldShowMenu) => css`
  align-items: ${shouldShowMenu ? 'flex-start' : 'center'};
  display: flex;
  gap: 16px;
  justify-content: space-between;
  margin: 0 auto;
  min-height: 57px;
  padding: ${shouldShowMenu ? ' 16px 8px 8px' : '8px'};

  @media (min-width: ${breakpoints._768}) {
    align-items: center;
    max-width: 1170px;
    min-height: 68px;
    padding: 0 16px;
  }
`;

const SiteHeader = ({
  baseUrl,
  hasHeaderTakeOverBanner,
  headerAndInfoHeight,
  isHeaderNotificationVisible,
  isLogoUrlRemoved,
  mainMenuItems,
  menuState = {},
  menuTitleText,
  notificationHeight = 0,
  setHeaderHeight: setHeaderHeightCallback,
  siteName,
}) => {
  const { p } = useProperty();
  const { brand } = useSiteContext();
  const { headerHeightContext, handleHeaderHeightContext } = useHeightContext();
  const { lessThanTwoAvailableLanguages } = useLanguage();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const siteLogoRef = useRef(null);
  const isOrderDetailsPage = window.location.pathname.includes(routes.ORDER_DETAILS);
  const { t } = useTranslation();

  const { isScrollingDown, isScrollingUp, pageYOffset } = useScroll({
    maxScreenWidth: !isOrderDetailsPage ? 479 : null,
    throttleTime: 100,
    useCaptionOnScrollListener: true,
  });
  const isSettingsEnabled =
    p('IbeClient.Settings.Selection.Country.Enabled') ||
    p('IbeClient.Settings.Selection.Language.Enabled') ||
    p('IbeClient.Settings.Selection.Currency.Enabled');

  const setHeaderHeight = useCallback(() => {
    if (isFunction(setHeaderHeightCallback) && headerRef?.current) {
      setHeaderHeightCallback(headerRef.current.getBoundingClientRect().height);
    }
  }, [setHeaderHeightCallback]);

  const handleResize = throttle(() => {
    setHeaderHeight();
  }, 300);

  const toggleMobileMenu = () => setIsMobileMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);

  useEffect(() => {
    if (headerRef?.current) {
      const { height: currentHeaderHeight } = headerRef.current.getBoundingClientRect();
      const tooLittleScrollToHide = pageYOffset <= currentHeaderHeight;

      if (tooLittleScrollToHide) {
        if (headerHeightContext !== headerAndInfoHeight) {
          headerRef.current.classList.remove('nav-up');
          handleHeaderHeightContext(headerAndInfoHeight);
        }
        return;
      }

      if (isScrollingDown) {
        if (!hasHeaderTakeOverBanner && headerHeightContext !== 0) {
          const newHeight = isHeaderNotificationVisible ? notificationHeight : 0;
          headerRef.current.classList.add('nav-up');
          handleHeaderHeightContext(newHeight);
        }
      } else if (isScrollingUp || isMobileMenuOpen) {
        if (headerHeightContext !== headerAndInfoHeight) {
          headerRef.current.classList.remove('nav-up');
          handleHeaderHeightContext(headerAndInfoHeight);
        }
      }
    }
  }, [
    handleHeaderHeightContext,
    hasHeaderTakeOverBanner,
    headerAndInfoHeight,
    headerHeightContext,
    isHeaderNotificationVisible,
    isMobileMenuOpen,
    isScrollingDown,
    isScrollingUp,
    notificationHeight,
    pageYOffset,
  ]);

  useEffect(() => {
    if (
      headerRef?.current &&
      headerHeightContext !== headerAndInfoHeight &&
      ![...headerRef.current.classList].includes('nav-up')
    ) {
      handleHeaderHeightContext(headerAndInfoHeight);
    }
  }, [headerAndInfoHeight, headerHeightContext, handleHeaderHeightContext]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const logoHeight = entries[0].contentRect.height;
      if (logoHeight > 0) {
        setHeaderHeight();
      }
    });
    if (siteLogoRef?.current) {
      resizeObserver.observe(siteLogoRef?.current);
    }
    return () => resizeObserver.disconnect();
  }, [siteLogoRef, setHeaderHeight]);

  const url = isLogoUrlRemoved ? null : baseUrl;

  const { pathname } = useLocation();
  const isResultPage = pathname.includes(routes.RESULT);
  const isCFCPage = pathname.includes(routes.CARRIER_FLIGHT_CONFIRMATION);

  const { mainMenu, menuWithSubMenu, supportMenu } = formatMenuItems(mainMenuItems);

  const { shouldShowStepIndicator, shouldShowMenu } = menuState;
  const shouldShowMainMenu = shouldShowMenu && mainMenu.length > 0 && !isCFCPage;

  const shouldShowSupportMenu =
    supportMenu.length > 0 && p('IbeClient.Header.SupportMenu.Enabled') && !isCFCPage;
  const menusWithSubMenu = shouldShowMenu ? menuWithSubMenu.concat(supportMenu) : supportMenu;
  const shouldShowMultiLanguage = p('Site.MultiLanguage.Enabled') && !lessThanTwoAvailableLanguages;

  const shouldShowMobileMenu =
    (menusWithSubMenu.length > 0 || shouldShowMultiLanguage) && !isCFCPage;
  const { currentSearchData, isSearchFormOpen, setIsSearchFormOpen } = useSearch();

  const isAlternateSearchBarEnabled = p('Result.AlternateSearchBar.Enabled');
  const shouldShowMyBookingsButton = p('IbeClient.MyBookings.Header.Button.Enabled') && !isCFCPage;

  const settingsOrLanguageButton = isSettingsEnabled ? (
    <SettingsGraphqlContainer componentType="Header" />
  ) : (
    shouldShowMultiLanguage && <LanguageSelectionButton />
  );

  useEffect(() => {
    if (isResultPage && isAlternateSearchBarEnabled) {
      setHeaderHeight();
    }
  }, [isResultPage, isAlternateSearchBarEnabled, setHeaderHeight]);

  return (
    <header
      className={headerStyles(hasHeaderTakeOverBanner, isSearchFormOpen, notificationHeight)}
      data-testid="siteHeader"
      id="site-header"
      ref={headerRef}
      role="banner"
    >
      {isResultPage && isAlternateSearchBarEnabled && has(currentSearchData, 'origin') ? (
        <SiteHeaderV2
          baseUrl={url}
          brand={brand.code}
          isSettingsEnabled={isSettingsEnabled}
          ref={siteLogoRef}
          setIsSearchBarOpen={setIsSearchFormOpen}
          shouldShowMultiLanguage={shouldShowMultiLanguage}
          shouldShowSupportMenu={shouldShowSupportMenu}
          siteName={siteName}
          supportMenu={supportMenu}
        />
      ) : (
        <div className={contentStyles(shouldShowMenu)}>
          <Inline collapseBelow="768px" noWrap spacing={{ [breakpoints._768]: 32 }}>
            <SiteLogo baseUrl={url} brand={brand.code} ref={siteLogoRef} siteName={siteName} />
            {shouldShowMainMenu && <DesktopMenu menu={mainMenu} />}
          </Inline>
          {shouldShowStepIndicator && <div id="step-indicator" />}
          <Inline alignY="center" noWrap>
            {!isCFCPage && (
              <Media query={`(min-width: ${breakpoints._768})`}>
                {(matches) =>
                  matches && (
                    <Inline align="end" alignY="center" noWrap>
                      {settingsOrLanguageButton}
                      {shouldShowSupportMenu && <SupportMenu menu={supportMenu} />}
                    </Inline>
                  )
                }
              </Media>
            )}
            {shouldShowMyBookingsButton && <MyBookingsButton className={myBookingsButtonStyles} />}
            {shouldShowMobileMenu && (
              <Media query="(max-width: 767px)">
                {(matches) =>
                  matches && (
                    <>
                      <SiteAuxiliaryButton
                        aria-controls="mobile menu"
                        data-testid="mobile-menu-button"
                        hideLabel
                        iconPrefix={<List aria-hidden />}
                        onClick={toggleMobileMenu}
                        withBorder={false}
                      >
                        {t('SiteMenu.OpenMenu.Label')}
                      </SiteAuxiliaryButton>
                      <MobileMenu
                        isMobileMenuOpen={isMobileMenuOpen}
                        menu={menusWithSubMenu}
                        menuTitleText={menuTitleText}
                        toggleMobileMenu={toggleMobileMenu}
                      />
                    </>
                  )
                }
              </Media>
            )}
          </Inline>
        </div>
      )}
    </header>
  );
};

SiteHeader.propTypes = {
  baseUrl: PropTypes.string,
  hasHeaderTakeOverBanner: PropTypes.bool,
  headerAndInfoHeight: PropTypes.number,
  isHeaderNotificationVisible: PropTypes.bool,
  isLogoUrlRemoved: PropTypes.bool,
  mainMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          path: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  ),
  menuState: PropTypes.shape({
    shouldShowMenu: PropTypes.bool,
    shouldShowStepIndicator: PropTypes.bool,
  }),
  menuTitleText: PropTypes.string,
  notificationHeight: PropTypes.number,
  setHeaderHeight: PropTypes.func,
  siteName: PropTypes.string,
};

export default SiteHeader;
