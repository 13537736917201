import { css, etiColors } from '@eti/styles';
import type { ComponentPropsWithoutRef, ReactNode, Ref } from 'react';

const getOptionBackgroundColor = (isFocused?: boolean, isSelected?: boolean) => {
  if (isSelected) {
    return etiColors.info.solid;
  }

  return isFocused ? etiColors.info.light : '#fff';
};

const wrapperStyles = (isFocused?: boolean, isSelected?: boolean) => css`
  background-color: ${getOptionBackgroundColor(isFocused, isSelected)};
  color: ${isSelected ? '#fff' : etiColors.black};
  cursor: pointer;
  padding: 1rem;
`;

interface OptionProps {
  innerProps?: Exclude<ComponentPropsWithoutRef<'div'>, 'className' | 'data-testid'>;
  innerRef?: () => Ref<HTMLDivElement>;
  isFocused?: boolean;
  isSelected?: boolean;
  label: ReactNode;
}

const Option = ({ label, innerProps, innerRef, isFocused, isSelected }: OptionProps) => (
  <div
    className={wrapperStyles(isFocused, isSelected)}
    data-dropdownstate={isSelected ? 'selected' : null}
    data-testid="etiDropdownOption"
    ref={innerRef}
    {...innerProps}
  >
    {label}
  </div>
);

export default Option;
