import { useState, type FormEvent } from 'react';
import {
  Button,
  Dialog,
  Inline,
  SiteAuxiliaryButton,
  Stack,
  Text,
  textVariants,
  vars,
} from '@etg/wings';
import { useProperty, useSnowplowTracking, useTranslation } from '@eti/providers';
import type { GetSettingsQuery } from '@eti/schema-types';
import { css } from '@eti/styles';
import { Globe } from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';
import { routes } from '../../../constants/routesAndApisConstants';
import { screenReadersOnlyStyles } from '../../../utils/styleUtils';
import type { Option } from '../models';
import {
  getAdditionalLocaleOptions,
  getLocaleName,
  getPrimaryLocaleOptions,
  getRegionByCode,
  getRegionLocaleByCode,
} from '../utils';
import CurrencySelect from './CurrencySelect';
import LocaleSelect from './LocaleSelect';
import RegionSelect from './RegionSelect';

const settingsDialogStyles = css`
  [data-settingsdialog],
  [data-settingsdialog] > div {
    overflow: visible;
  }
`;
const settingsCountryCurrencyInfoStyles = css`
  background-color: ${vars.colors.signal.information.tint};
  border-radius: 3px;
  padding: 16px;
`;

const triggerButtonHeaderStyles = css`
  &:focus-visible {
    outline-color: ${vars.colors.header.content};
  }
`;

const trackingActions = {
  CLICK: 'Click',
  SELECT: 'Select',
} as const;

const trackingLabel = {
  CHANGE_REGIONAL_SETTINGS: 'Change Regional Settings',
  COUNTRY: 'Country',
  LANGUAGE: 'Language',
  CURRENCY: 'Currency',
  SAVE: 'Save Regional Settings',
} as const;

const routesWhereLanguageSelectionIsDisabled: (typeof routes)[keyof typeof routes][] = [
  routes.CONTACT,
  routes.ORDER_DETAILS,
  routes.ORDER_DETAILS_CHECK_IN,
  routes.ORDER_DETAILS_CONTACT_US,
  routes.ORDER_DETAILS_EXTRA_PRODUCTS,
  routes.ORDER_DETAILS_MANAGE_TRIP,
  routes.ORDER_DETAILS_TRAVEL_INFORMATION,
  routes.ORDER_LIST,
  routes.CHANGE_TRIP,
  routes.RYANAIR_ORDER_AUTHENTICATION,
] as const;

const useCurrentPage = () => {
  const location = useLocation();
  const page = location.pathname.replace('/', '');
  if (page === '') {
    return { page: 'start' };
  }
  return { page };
};

interface SettingsProps {
  onSubmit: (
    selectedLocaleOption: Option,
    selectedRegionOption: Option,
    selectedCurrencyOption: Option,
  ) => void;
  iconOnly?: boolean;
  settings: GetSettingsQuery['settings'];
  componentType?: 'Header' | 'Footer' | 'Menu';
}
const Settings = ({ settings, onSubmit, iconOnly, componentType }: SettingsProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const { pathname } = useLocation();
  const { isSnowplowTrackingEnabled, sendTrackEvent, updateSnowplowContextData } =
    useSnowplowTracking();
  const { page } = useCurrentPage();
  const isStartPage = pathname === routes.START || pathname === '/';

  const isCountryEnabled = p('IbeClient.Settings.Selection.Country.Enabled') && isStartPage;
  const isLocaleEnabled =
    p('IbeClient.Settings.Selection.Language.Enabled') &&
    !routesWhereLanguageSelectionIsDisabled.some((route) => pathname.includes(route));
  const isCurrencyEnabled = p('IbeClient.Settings.Selection.Currency.Enabled') && isStartPage;

  const shouldShowInfo = !isCountryEnabled || !isCurrencyEnabled || !isLocaleEnabled;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectedRegionOption, setSelectedRegionOption] = useState<Option>({
    label: settings.selectedRegion.name,
    value: settings.selectedRegion.code,
  });
  const [selectedLocaleOption, setSelectedLocaleOption] = useState<Option>(() => {
    const region = getRegionByCode(settings.regions, settings.selectedRegion.code);

    return {
      label: getLocaleName(
        region.availableLocales,
        getRegionLocaleByCode(region, settings.selectedLocale.code),
      ),
      value: settings.selectedLocale.code,
    };
  });
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState<Option>({
    label: settings.selectedCurrency.name,
    value: settings.selectedCurrency.code,
  });

  const handleSettingsButtonClick = () => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({
        label: trackingLabel.CHANGE_REGIONAL_SETTINGS,
        category: page,
        action: trackingActions.CLICK,
        property: componentType,
      });
    }
    setIsDialogOpen(true);
  };

  const dismissDialog = () => setIsDialogOpen(false);

  const selectedRegion = getRegionByCode(settings.regions, selectedRegionOption.value)!;

  const selectedAvailableCurrencies = getRegionLocaleByCode(
    selectedRegion,
    selectedLocaleOption.value,
  );

  const currencyOptions = selectedAvailableCurrencies.availableCurrencies.map((currency) => ({
    label: `${currency.name} (${currency.code})`,
    value: currency.code,
  }));

  const handleSelectedLocale = (option: Option) => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({
        label: trackingLabel.LANGUAGE,
        category: page,
        action: trackingActions.SELECT,
        property: option.value,
      });
    }

    setSelectedLocaleOption(option);
    const newCurrencyOptions =
      selectedRegion.availableLocales.find((locale) => locale.code === option.value)
        ?.availableCurrencies ?? selectedRegion.availableLocales[0].availableCurrencies;

    if (!newCurrencyOptions.some((currency) => selectedCurrencyOption.value === currency.code)) {
      const newSelectedCurrency = {
        label: newCurrencyOptions[0].name,
        value: newCurrencyOptions[0].code,
      };
      setSelectedCurrencyOption(newSelectedCurrency);
    }
  };

  const handleSelectedRegion = (option: Option) => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({
        label: trackingLabel.COUNTRY,
        category: page,
        action: trackingActions.SELECT,
        property: option.value,
      });
    }

    setSelectedRegionOption(option);
    const region = getRegionByCode(settings.regions, option.value);

    const selectedIsAvailable = region.availableLocales.some(
      (language) => selectedLocaleOption.value === language.code,
    );

    const regionLocale = getRegionLocaleByCode(
      region,
      selectedIsAvailable ? selectedLocaleOption.value : region.availableLocales[0].code,
    );

    const newSelectedLocale = {
      label: getLocaleName(region.availableLocales, regionLocale),
      value: regionLocale.code,
    };
    setSelectedLocaleOption(newSelectedLocale);

    const newCurrencyOptions =
      region.availableLocales.find((locale) => locale.code === option.value)?.availableCurrencies ??
      regionLocale.availableCurrencies;

    if (!newCurrencyOptions.some((currency) => selectedCurrencyOption.value === currency.code)) {
      const newSelectedCurrency = {
        label: newCurrencyOptions[0].name,
        value: newCurrencyOptions[0].code,
      };
      setSelectedCurrencyOption(newSelectedCurrency);
    }
  };

  const handleSelectedCurrency = (option: Option) => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({
        label: trackingLabel.CURRENCY,
        category: page,
        action: trackingActions.SELECT,
        property: option.value,
      });
    }
    setSelectedCurrencyOption(option);
  };

  const primaryLocaleOptions = getPrimaryLocaleOptions(selectedRegion);

  const additionalLocaleOptions = getAdditionalLocaleOptions(selectedRegion);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isSnowplowTrackingEnabled) {
      updateSnowplowContextData({
        siteData: {
          siteCountry: selectedRegionOption.value,
          siteCurrency: selectedCurrencyOption.value,
          siteLanguage: selectedLocaleOption.value,
        },
      });
      sendTrackEvent({
        label: trackingLabel.SAVE,
        category: page,
        action: trackingActions.CLICK,
      });
    }
    onSubmit(selectedLocaleOption, selectedRegionOption, selectedCurrencyOption);
  };

  return (
    <>
      <SiteAuxiliaryButton
        className={componentType === 'Header' ? triggerButtonHeaderStyles : undefined}
        data-testid="language-selection-button"
        hideLabel={iconOnly}
        iconPrefix={<Globe aria-hidden />}
        onClick={handleSettingsButtonClick}
        withBorder={componentType !== 'Header'}
      >
        <span className={screenReadersOnlyStyles}>
          {t('ScreenReader.Settings.Button.AriaLabel.Text')}
        </span>
        <span data-testid="language-label">{settings.selectedLocale.name}</span>
      </SiteAuxiliaryButton>
      <Dialog
        className={settingsDialogStyles}
        closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
        data-settingsdialog
        data-testid="settings-dialog"
        isOpen={isDialogOpen}
        onDismiss={dismissDialog}
        title={t('Settings.Dialog.Title')}
      >
        <form onSubmit={handleSubmit}>
          <Stack>
            <Text>{t('Settings.Description.Text')}</Text>
            {isCountryEnabled && (
              <RegionSelect
                onChange={handleSelectedRegion}
                regions={settings.regions}
                value={selectedRegionOption}
              />
            )}
            {isLocaleEnabled && (
              <LocaleSelect
                additionalOptions={additionalLocaleOptions}
                onChange={handleSelectedLocale}
                primaryOptions={primaryLocaleOptions}
                value={selectedLocaleOption}
              />
            )}
            {isCurrencyEnabled && (
              <CurrencySelect
                onChange={handleSelectedCurrency}
                options={currencyOptions}
                value={selectedCurrencyOption}
              />
            )}
            {shouldShowInfo && (
              <Stack className={settingsCountryCurrencyInfoStyles} data-testid="settings-info">
                {!isCountryEnabled && (
                  <Stack spacing={8}>
                    <Text as="span" data-testid="settings-info-country">
                      {t('Settings.Region.Label')}
                    </Text>
                    <Text variant={textVariants._18_EMPHASIZED}>{selectedRegionOption.label}</Text>
                  </Stack>
                )}
                {!isLocaleEnabled && (
                  <Stack spacing={8}>
                    <Text as="span" data-testid="settings-info-language">
                      {t('Settings.Language.Label')}
                    </Text>
                    <Text variant={textVariants._18_EMPHASIZED}>{selectedLocaleOption.label}</Text>
                  </Stack>
                )}
                {!isCurrencyEnabled && (
                  <Stack spacing={8}>
                    <Text as="span" data-testid="settings-info-currency">
                      {t('Settings.Currency.Label')}
                    </Text>
                    <Text variant={textVariants._18_EMPHASIZED}>
                      {selectedCurrencyOption.label}
                    </Text>
                  </Stack>
                )}
              </Stack>
            )}
            <Inline align="end">
              <Button data-testid="settings-save-button" type="submit" variant="primary">
                {t('Settings.Save.Button')}
              </Button>
            </Inline>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default Settings;
