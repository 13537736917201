import { format } from 'date-fns';

type Range = { from?: Date; to?: Date };

const getButtonForDate = (date?: Date): HTMLButtonElement | null => {
  if (!date || Number.isNaN(date.getTime())) {
    return null;
  }

  const div = document.querySelector<HTMLDivElement>(
    `.rdp-tbody button:not(.rdp-day_disabled) > div[data-day="${format(date, 'yyyy-MM-dd')}"]`,
  );

  return div?.closest<HTMLButtonElement>('button') || null;
};

export const getFirstAndLastSelectableDateButtons = ({
  from,
  to,
}: Range): {
  departureDate: HTMLButtonElement | null;
  returnDate: HTMLButtonElement | null;
} => {
  const firstButton = document.querySelector<HTMLButtonElement>(
    '.rdp-tbody button:not(.rdp-day_disabled)',
  );

  const departureDate = getButtonForDate(from) || firstButton;
  const returnDate = getButtonForDate(to) || departureDate || firstButton;

  return { departureDate, returnDate };
};
