import type { ReactNode } from 'react';
import { breakpoints, Stack, Input } from '@etg/wings';
import { ConnectedSelectBox } from '@eti/form';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { cabinClasses } from '../../../constants/cabinClassesConstants';
import type { FormConstraints } from '../models';
import { CABIN_CLASS } from '../constants/formFields';
import Passengers from './passengers/Passengers';

const { BUSINESS, ECONOMY, FIRST, PREMIUM_ECONOMY } = cabinClasses;
const isInLargeViewport = () => window.matchMedia(`(min-width: ${breakpoints._768})`).matches;
const formatValueForStore = (value: { value: string; label: string }) => value?.value;

const inputGroupStyles = css`
  flex: 1;
  position: relative;
  width: calc(50% - 24px);
`;

const cabinClassStyles = css`
  flex: 1;
`;

const Wrapper = ({ children }: { children: ReactNode }) =>
  isInLargeViewport() ? (
    <Input.Group className={inputGroupStyles}>{children}</Input.Group>
  ) : (
    <Stack spacing={24}>{children}</Stack>
  );

interface PassengerAndCabinClassProps extends FormConstraints {}

const PassengerAndCabinClass = ({ formConstraints }: PassengerAndCabinClassProps) => {
  const { t } = useTranslation();

  const cabinClassOptions = [
    { value: ECONOMY, label: t('Air.Search.Cabin.Button.Economy') },
    { value: PREMIUM_ECONOMY, label: t('Air.Search.Cabin.Button.PremiumEconomy') },
    { value: BUSINESS, label: t('Air.Search.Cabin.Button.Business') },
    { value: FIRST, label: t('Air.Search.Cabin.Button.Supersonic') },
  ];

  return (
    <Wrapper>
      <Passengers constraints={formConstraints?.passengers} />
      <ConnectedSelectBox
        className={cabinClassStyles}
        data-testid="searchForm-cabinClasses-dropdown"
        label={t('Searchform.BookingClass.Label')}
        name={CABIN_CLASS}
        normalize={formatValueForStore}
        options={cabinClassOptions}
        placeholder={t('Air.Search.Cabin.Placeholder')}
      />
    </Wrapper>
  );
};

export default PassengerAndCabinClass;
