import { format, isBefore, isSameDay, parseISO } from 'date-fns';
import type { DayContentProps } from 'react-day-picker';
import { css, cx, keyframes } from '@eti/styles';
import type { ReactElement } from 'react';
import type { BestPricePerDay } from '@eti/schema-types';

const priceCategories: Record<
  string,
  { priceLabel: string | ReactElement; backgroundColor: string }
> = {
  LOW: { priceLabel: '$', backgroundColor: '#88dd92' },
  HIGH: { priceLabel: '$$$', backgroundColor: '#ffc7c7' },
  MEDIUM: { priceLabel: '$$', backgroundColor: '#fcf3d8' },
  NOT_AVAILABLE: {
    priceLabel: '$$',
    backgroundColor: '#fcf3d8',
  },
};

const shine = keyframes`
  0% {
      inset-inline-start : -33px;
  }
  100% {
   inset-inline-start: 33px;
  }
`;

const loadingStyles = css`
  background-color: #e5e5e5;
  height: 16px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    animation: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: ${shine};
    background-image: linear-gradient(to right, #e5e5e500 0%, #efededcc, #e5e5e500 100%);
    background-repeat: repeat-x;
    content: '';
    height: 100%;
    position: absolute;
    transform: skewX(-45deg);
    width: 20px;
  }
`;

const getPriceStyle = (category?: string) => css`
  ${category ? `background-color: ${priceCategories[category].backgroundColor}` : ''};

  align-items: center;
  border-radius: 2px;
  color: #000;
  display: flex;
  font-size: 0.6rem;
  height: 16px;
  justify-content: center;
  padding: 1px 0;
  text-align: center;
  width: 100%;
`;

const containerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  padding: 0 4px 4px;
  position: relative;
  width: 100%;
`;

const dayNumberStyles = css`
  padding: 5.5px 0 2px;
  text-align: center;
`;

export interface PriceCalendarProps extends DayContentProps {
  priceCalendarData?: BestPricePerDay[];
  loading: boolean;
}

const CustomDayWithPriceIndicator = ({
  date,
  priceCalendarData,
  activeModifiers,
  loading,
}: PriceCalendarProps) => {
  const priceData = priceCalendarData?.find((d) => isSameDay(parseISO(d.date), date));

  const lastDayOfPriceData = priceCalendarData?.[priceCalendarData?.length - 1];

  const shouldShowPriceIndicator =
    !activeModifiers.disabled &&
    lastDayOfPriceData?.date &&
    !isBefore(parseISO(lastDayOfPriceData?.date), date);

  return (
    <div
      aria-label={date.toDateString()}
      className={containerStyles}
      data-day={format(date, 'yyyy-MM-dd')}
    >
      <div className={dayNumberStyles}>{date.getDate()}</div>
      {shouldShowPriceIndicator ? (
        <div
          className={getPriceStyle(priceData?.priceCategory ?? 'NOT_AVAILABLE')}
          data-testid="price-calendar-indicator"
        >
          {priceCategories[priceData?.priceCategory ?? 'NOT_AVAILABLE'].priceLabel}
        </div>
      ) : (
        <div
          className={cx(getPriceStyle(), {
            [loadingStyles]: !activeModifiers.disabled && loading,
          })}
          data-testid="price-calendar-loading-indicator"
        >
          {'   '}
        </div>
      )}
    </div>
  );
};

export default CustomDayWithPriceIndicator;
