export const MAX_CHARS = 50;

export const isRequired = (requiredMessage?: string) => ({
  required: requiredMessage || 'Validation.Required',
});

export const isValidEmail = (patternMessage?: string) => ({
  pattern: { value: /^\S+@\S+\.\S+$/, message: patternMessage || 'Validation.Mail.Required' },
});

export const isValidOrderNumber = (orderNumberMaxLength: number, message?: string) => ({
  pattern: {
    value: new RegExp(
      `^([a-zA-Z0-9]{6}(?:[a-zA-Z0-9]{${orderNumberMaxLength - 6}})?|^\\d{4}-\\d{3}-\\d{3})$`,
    ),
    message: message || 'Validation.OrderNumber.Invalid',
  },
});
