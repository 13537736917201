import { useEffect, useState } from 'react';
import { useTranslation, useSiteContext } from '@eti/providers';
import { css, cx, etiColors, mediaQueries } from '@eti/styles';
import { Column, Columns, Heading, Stack } from '@etg/wings';
import ReceiptMobileAppBannerLinks from './ReceiptMobileAppBannerLinks';

const containerStyle = css`
  display: none;

  @media ${mediaQueries.medium.up} {
    border-top: 1px dotted ${etiColors.grayScale[8]};
    display: inherit;
    padding: 16px 0;
  }
`;

const qRCodeImageStyles = css`
  height: 96px;
  width: 96px;
`;

const qrCodeTextStyles = css`
  font-size: 0.875rem;
  text-align: center;
`;

const qrCodeHeadingStyles = css`
  & > span {
    text-align: center;
  }
`;
interface fetchQRCodeProps {
  code: string;
}

const fetchQRCode = async (brand: fetchQRCodeProps) => {
  try {
    const appQRCode = await import(`@eti/assets/images/qr-codes/${brand.code}-app-qrcode.png`);
    return appQRCode.default;
  } catch {
    const defaultImage = await import(`@eti/assets/images/qr-codes/mytrip-app-qrcode.png`);
    return defaultImage.default;
  }
};

const ReceiptMobileAppBanner = ({ className = '' }) => {
  const { brand } = useSiteContext();
  const { t } = useTranslation();
  const [appQRCode, setAppQRCode] = useState('');

  useEffect(() => {
    fetchQRCode(brand)
      .then((appQRCodeImage) => setAppQRCode(appQRCodeImage))
      .catch(() => setAppQRCode(''));
  }, [brand]);

  return (
    <Stack className={cx(containerStyle, className)} data-testid="orderPage-mobileApp-banner">
      <Heading level={2}>{t('Order.DownloadApp.Header')}</Heading>
      <Columns>
        <Column width="4/6">
          <ReceiptMobileAppBannerLinks />
        </Column>
        <Column width="2/6">
          <Stack align="center">
            <Heading as="span" className={qrCodeHeadingStyles} level={4}>
              {t('Order.DownloadApp.ScanQr.Label')}
            </Heading>
            <img
              alt="qr-code-icon"
              className={qRCodeImageStyles}
              data-testid="qr-code-image-wrapper"
              src={appQRCode}
            />
            <p className={qrCodeTextStyles}>{t('Order.DownloadApp.ScanQr.Text')}</p>
          </Stack>
        </Column>
      </Columns>
    </Stack>
  );
};

export default ReceiptMobileAppBanner;
