import { useEffect, useState } from 'react';
import type { KeyboardEvent } from 'react';
import { breakpoints, Button, PageBlock, vars } from '@etg/wings';
import { DangerousHtmlContent } from '@eti/components';
import { useTranslation } from '@eti/providers';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { Info, X } from '@phosphor-icons/react';
import Cookies from 'js-cookie';

const acceptButtonStyle = css`
  align-self: center;
  margin: 0 auto;
  max-width: 100%;
  min-width: 10rem;

  @media (min-width: ${breakpoints._768}) {
    margin-left: 26px;
    min-width: 220px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 26px;
    }
  }
`;

const cookieBannerStyle = css`
  backdrop-filter: blur(2px);
  background-color: hsl(207deg 100% 96% / 90%);
  bottom: 0;
  color: ${vars.colors.text};
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999999;

  @media print {
    display: none;
  }
`;

const closeButtonStyles = css`
  border-radius: 3px;
  display: flex;
  inset-block-start: 6px;
  inset-inline-end: 0;
  position: absolute;
  z-index: 2;

  &:focus-visible {
    outline: 2px solid ${vars.colors.focusRing};
  }
`;

const closeButtonIconStyles = css`
  fill: ${vars.colors.text};

  &:hover {
    fill: #262626;
  }
`;

const contentStyles = css`
  display: flex;
`;

const headingStyle = css`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.25rem;

  @media (min-width: ${breakpoints._970}) {
    font-size: 1.125rem;
  }
`;

const infoIconStyle = css`
  flex-shrink: 0;
  height: 26px;
  margin-right: 10px;
  width: 26px;

  [dir='rtl'] & {
    margin-left: 10px;
    margin-right: 0;
  }

  @media (min-width: ${breakpoints._970}) {
    height: 30px;
    margin-right: 20px;
    width: 30px;

    [dir='rtl'] & {
      margin-left: 20px;
      margin-right: 0;
    }
  }
`;

const textStyles = css`
  font-size: 0.875rem;
  margin-bottom: 0.75rem;

  @media (min-width: ${breakpoints._768}) {
    margin-bottom: 0;
  }
`;

const wrapperStyles = css`
  padding-bottom: 10px;
  padding-right: 32px;
  padding-top: 10px;
  position: relative;

  [dir='rtl'] & {
    padding-left: 32px;
    padding-right: 10px;
  }

  @media (min-width: ${breakpoints._768}) {
    display: flex;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-top: 20px;

    [dir='rtl'] & {
      padding-left: 40px;
      padding-right: 15px;
    }
  }
`;

export const CONSENT_COOKIE_NAME = 'ibe.acceptedCookie';

const shouldShowBanner = () => {
  if (typeof document === 'undefined') {
    return null;
  }
  return !Cookies.get(CONSENT_COOKIE_NAME);
};

const giveConsent = () => {
  Cookies.set(CONSENT_COOKIE_NAME, 'true', { expires: 180, secure: true });
};

interface CookieBannerProps {
  className: string;
  dataTestId: string;
  id: string;
}

const CookieBanner = ({ className, id, dataTestId }: CookieBannerProps) => {
  const [showBanner, setShowBanner] = useState(shouldShowBanner);
  const { t } = useTranslation();

  const acceptButtonText = t('CookieBanner.ButtonLabel');
  const acceptButtonLabel = t('ScreenReader.CookieBanner.IAcceptButton.AriaLabel.Text');
  const closeButtonLabel = t('ScreenReader.CookieBanner.CloseDialogButton.AriaLabel.Text');
  const dialogLabel = t('ScreenReader.CookieBanner.Dialog.AriaLabel.Text');
  const text = t('CookieBanner.Text');
  const title = t('CookieBanner.Title');

  const focusOnFirstElement = () => {
    const element =
      document.getElementById('skip-to-main-content-link') ?? document.getElementById('site-logo');
    element?.focus();
  };

  const handleAccept = () => {
    focusOnFirstElement();
    setShowBanner(false);
    giveConsent();
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault();
      focusOnFirstElement();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        handleAccept();
      }
    });
  });

  return showBanner ? (
    <PageBlock
      aria-label={dialogLabel}
      className={cx('etiCookieBanner', cookieBannerStyle, className)}
      data-testid={dataTestId}
      id={id}
      role="region"
      tabIndex={-1}
    >
      <div className={wrapperStyles}>
        <div className={contentStyles}>
          <Info
            aria-hidden
            className={infoIconStyle}
            color={vars.colors.signal.information.light}
            id="info-circle"
            weight="fill"
          />
          <div>
            <h2 className={cx(headingStyle, 'cookieBanner__title')} id="cookieBanner-title">
              {title}
            </h2>
            <DangerousHtmlContent
              className={textStyles}
              content={text}
              id="cookieBanner-description"
            />
          </div>
        </div>
        <Button
          aria-label={acceptButtonLabel}
          className={acceptButtonStyle}
          data-testid="cookieBanner-confirmButton"
          onClick={handleAccept}
          type="button"
          variant="primary"
        >
          <span>{acceptButtonText}</span>
        </Button>
        <button
          aria-label={closeButtonLabel}
          className={cx(resetButtonStyle, closeButtonStyles)}
          data-testid="cookieBanner-closeButton"
          id="cookieBanner-closeButton"
          onClick={handleAccept}
          onKeyDown={handleOnKeyDown}
          type="button"
        >
          <X aria-hidden className={closeButtonIconStyles} size="24px" />
        </button>
      </div>
    </PageBlock>
  ) : null;
};

export default CookieBanner;
