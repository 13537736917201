import { useEffect, type RefObject } from 'react';

/**
 *  @param elementRef A React.Ref attached to a wrapper element that serves as
 *  the boundary for when to trigger `callback`.
 *  @param callback The function to invoke when focus is moved outside `elementRef`.
 */
export const useCloseOnFocusOut = (elementRef: RefObject<HTMLElement>, callback: () => void) => {
  useEffect(() => {
    const refElement = elementRef.current;
    const handleFocusOut = (event: FocusEvent) => {
      const { relatedTarget } = event;
      if (relatedTarget instanceof Node) {
        if (!refElement?.contains(relatedTarget)) {
          callback();
        }
      }
    };

    refElement?.addEventListener('focusout', handleFocusOut);

    return () => {
      refElement?.removeEventListener('focusout', handleFocusOut);
    };
  }, [callback, elementRef]);
};
