import { SIMPLE_VISA } from '../products';
import getIcon from './utils/icon';
import { getPrice, getSelected, getTravelers } from './utils/selection';
import * as simpleVisaTransformer from './utils/simpleVisaTransformer';
import getGraphQlSelection, { getDBFGraphQlSelection } from './utils/getGraphQlSelection';
import SimpleVisaContainer from './common/SimpleVisaContainer';
const {
  name: formSection
} = SIMPLE_VISA;
export const simpleVisa = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getTravelers: getTravelers.bind(null, formSection),
  getGraphQlSelection,
  getDBFGraphQlSelection,
  simpleVisaTransformer,
  ...SIMPLE_VISA
};
export default SimpleVisaContainer;