import { formValueSelector } from 'redux-form';
import { SIMPLE_VISA } from '../../products';
const {
  name: formSection
} = SIMPLE_VISA;
const getGraphQlSelection = _ref => {
  var _formData$value;
  let {
    form,
    state
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  if (!(formData !== null && formData !== void 0 && (_formData$value = formData.value) !== null && _formData$value !== void 0 && _formData$value.value) || !formData.travelers) {
    return null;
  }
  const selectionTravelerChoice = Object.values(formData.travelers).filter(_ref2 => {
    let {
      selected
    } = _ref2;
    return selected;
  }).map(_ref3 => {
    let {
      choice
    } = _ref3;
    return {
      choiceId: choice.value,
      travelerId: choice.id
    };
  });
  if (selectionTravelerChoice.length === 0) {
    return null;
  }
  return {
    productId: String(SIMPLE_VISA.id),
    selectionTravelerChoice
  };
};
export const getDBFGraphQlSelection = _ref4 => {
  var _formData$value2, _formData$value3, _formData$value4;
  let {
    form,
    state
  } = _ref4;
  const formData = formValueSelector(form)(state, formSection);
  if ((formData === null || formData === void 0 || (_formData$value2 = formData.value) === null || _formData$value2 === void 0 ? void 0 : _formData$value2.value) === false || (formData === null || formData === void 0 || (_formData$value3 = formData.value) === null || _formData$value3 === void 0 ? void 0 : _formData$value3.value) === 'false') {
    return {
      productId: String(SIMPLE_VISA.id),
      selectionTravelerChoice: []
    };
  }
  if (!(formData !== null && formData !== void 0 && (_formData$value4 = formData.value) !== null && _formData$value4 !== void 0 && _formData$value4.value) || !formData.travelers) {
    return null;
  }
  const selectionTravelerChoice = Object.values(formData.travelers).map(_ref5 => {
    let {
      choice
    } = _ref5;
    return {
      choiceId: choice.value,
      travelerId: choice.id
    };
  });
  if (selectionTravelerChoice.length === 0) {
    return null;
  }
  return {
    productId: String(SIMPLE_VISA.id),
    selectionTravelerChoice
  };
};
export default getGraphQlSelection;