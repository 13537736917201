import { Inline } from '@etg/wings';
import { useProperty } from '@eti/providers';
import { isDesktopViewport } from '../../utils/view';

const HeaderTakeOverBanner = () => {
  const { p } = useProperty();
  const hasValidImageSrc =
    (isDesktopViewport() && Boolean(p('StartPage.HeaderTakeOver.ImageDesktop'))) ||
    (!isDesktopViewport() && Boolean(p('StartPage.HeaderTakeOver.ImageMobile')));
  return (
    <>
      {hasValidImageSrc && (
        <Inline align="center" data-testid="header-takeOver-banner">
          <a href={p('StartPage.HeaderTakeOver.Url')} rel="noreferrer" target="_blank">
            <img
              alt="Partner campaign"
              src={
                isDesktopViewport()
                  ? p('StartPage.HeaderTakeOver.ImageDesktop')
                  : p('StartPage.HeaderTakeOver.ImageMobile')
              }
            />
          </a>
        </Inline>
      )}
    </>
  );
};

export default HeaderTakeOverBanner;
