import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import possibleTypes from '../../possibleTypes.json';
const GRAPHQL_ENTRY_POINT = '/graphql';
const GRAPHQL_CREDENTIALS = 'same-origin';
export const suffixUrlWithOperationName = (url, operationName) => {
  return operationName ? `${url}/${operationName}` : url.toString();
};
const unauthorizedLink = (handleUser, handleUnauthorizedDialog) => {
  return onError(e => {
    var _e$graphQLErrors;
    // eslint-disable-next-line no-console
    console.error(e);
    if ((_e$graphQLErrors = e.graphQLErrors) !== null && _e$graphQLErrors !== void 0 && _e$graphQLErrors.length && e.graphQLErrors[0].message === 'UNAUTHORIZED') {
      handleUser(null);
      handleUnauthorizedDialog(true);
    }
  });
};
const errorLoggingLink = onError(_ref => {
  let {
    graphQLErrors,
    networkError,
    operation
  } = _ref;
  const errorPayload = {
    type: 'GRAPHQL_ERROR',
    timestamp: new Date().toISOString(),
    operation: {
      operationName: operation.operationName,
      variables: operation.variables
    },
    networkError: {},
    graphQLErrors: []
  };
  if (graphQLErrors) {
    errorPayload.graphQLErrors.push(...graphQLErrors);
  }
  if (networkError) {
    errorPayload.networkError = networkError;
  }
  if (errorPayload.graphQLErrors.length > 0 || errorPayload.networkError) {
    errorPayload.timestamp = new Date().toISOString();
    window.postMessage(errorPayload, window.location.origin);
  }
});
const emailChallengedLink = (handleUser, handleEmailChallengedDialog) => {
  return onError(e => {
    var _e$graphQLErrors2, _e$graphQLErrors3;
    // eslint-disable-next-line no-console
    console.error(e, (_e$graphQLErrors2 = e.graphQLErrors) !== null && _e$graphQLErrors2 !== void 0 && _e$graphQLErrors2.length ? e.graphQLErrors[0].extensions : null);
    if ((_e$graphQLErrors3 = e.graphQLErrors) !== null && _e$graphQLErrors3 !== void 0 && _e$graphQLErrors3.length && e.graphQLErrors[0].message === 'EMAIL_CHALLENGE_REQUIRED') {
      handleUser(null);
      handleEmailChallengedDialog(e.graphQLErrors[0].extensions || null);
    }
  });
};
export const createApolloClient = (handleUser, handleUnauthorizedDialog, handleEmailChallengedDialog) => {
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes
    }),
    link: from([errorLoggingLink, unauthorizedLink(handleUser, handleUnauthorizedDialog), emailChallengedLink(handleUser, handleEmailChallengedDialog), new HttpLink({
      uri: GRAPHQL_ENTRY_POINT,
      credentials: GRAPHQL_CREDENTIALS,
      fetch: async (url, options) => {
        const {
          operationName
        } = options !== null && options !== void 0 && options.body ? JSON.parse(options === null || options === void 0 ? void 0 : options.body) : null;
        const headers = {
          ...(options === null || options === void 0 ? void 0 : options.headers),
          'X-ETG-GraphQL-Operation': operationName
        };
        const result = await fetch(suffixUrlWithOperationName(url, operationName), {
          ...options,
          headers
        });
        if (window.spa_variables.debugEnabled) {
          fetch('/ibemgr/ibe-client-debug-data').then(r => r.json()).then(json => {
            window.spa_variables.debugData = json;
            document.dispatchEvent(new CustomEvent('debugDataLoaded', {
              detail: json
            }));
          }).catch(e => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
        }
        return result;
      }
    })])
  });
};