import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { CANCEL_FOR_ANY_REASON } from '../products';
import CancelForAnyReason from './components/CancelForAnyReason';
import getIcon from './utils/icon';
const formSection = CANCEL_FOR_ANY_REASON.name;
export const cancelForAnyReason = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  ...CANCEL_FOR_ANY_REASON
};
export default CancelForAnyReason;