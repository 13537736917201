import { createValidator } from 'revalidate';
import { parse, isValid, compareAsc } from 'date-fns';
import { INVALID_EXPIRY_DATE } from '../constants/defaultErrorMessages';
const isFutureDate = function (format) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  return createValidator(message => input => {
    if (!input) {
      return null;
    }
    const inputDate = parse(input, format, new Date());
    const validInputDate = isValid(inputDate);
    if (!validInputDate) {
      return message || INVALID_EXPIRY_DATE;
    }
    const now = new Date();
    const isDateInTheFuture = compareAsc(inputDate, now) === 1;
    return isDateInTheFuture ? null : message || INVALID_EXPIRY_DATE;
  }, INVALID_EXPIRY_DATE);
};
export default isFutureDate;