import { FLEXIBLE_TICKET } from '../products';
import { getNumberOfUnits, getPrice, getSelected, getSelectedForTraveler } from './utils/selection';
import getIcon from './utils/icon';
import FlexibleTicketContainer from './containers/FlexibleTicketContainer';
import getGraphQlSelection, { getDBFGraphQlSelection } from './utils/getGraphQlSelection';
const formSection = FLEXIBLE_TICKET.name;
export const flexibleTicket = {
  getGraphQlSelection,
  getDBFGraphQlSelection,
  formSection,
  getIcon,
  getNumberOfUnits: getNumberOfUnits.bind(null, formSection),
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getSelectedForTraveler: getSelectedForTraveler.bind(null, formSection),
  ...FLEXIBLE_TICKET
};
export default FlexibleTicketContainer;