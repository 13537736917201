const confirmation = {
  focus: '#009700',
  solid: '#10a700',
  washed: '#aed8b2',
  light: '#f0fff1',
  opaque: '#10a60026'
};
const critical = {
  focus: '#c10f1d',
  solid: '#d00',
  washed: '#e2bcbc',
  light: '#ffe7e7',
  opaque: '#dc010026'
};
const info = {
  focus: '#2576C1',
  solid: '#3586d1',
  washed: '#bed2df',
  light: '#e9f5ff',
  opaque: '#3486d126'
};
const warning = {
  focus: '#df9d55',
  solid: '#ec8a0b',
  washed: '#e5d9aa',
  light: '#fcf3d8',
  opaque: '#ffb24e26'
};
const focus = {
  solid: '#3586d1',
  light: '#ebf3fa'
};
const grayScale = {
  10: '#666',
  9: '#888',
  8: '#aaa',
  7: '#bbb',
  6: '#ccc',
  5: '#eee',
  4.5: '#e9e9e9',
  4: '#f0f0f0',
  3: '#f4f4f4',
  2: '#f8f8f8',
  1: '#fcfcfc'
};
const black = '#333';
const darkBlack = '#000';
const white = '#fff';
const lightBlue = '#f3fcff';
export default {
  black,
  confirmation,
  critical,
  darkBlack,
  info,
  grayScale,
  warning,
  white,
  lightBlue,
  focus
};