import type { Dispatch } from 'redux';
import { useEffect } from 'react';
import { Inline, Input, breakpoints } from '@etg/wings';
import { Checkbox } from '@eti/form';
import { useSearch, useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { connect } from 'react-redux';
import { change as changeFormValue, formValueSelector } from 'redux-form';
import { useMediaQuery } from 'usehooks-ts';
import { resetAllFilters } from '../../../pages/result/components/search-filters/utils/searchFilters';
import {
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  SHORT_STOPOVER,
  STOPOVER_ENABLED,
  TWO_NIGHTS_STOPOVER,
} from '../constants/formFieldNames';
import {
  STOPOVER_TYPES,
  SHORT_STOPOVER as SHORT_STOPOVER_SEARCH_DATA,
  ONE_DAY_STOPOVER as ONE_DAY_STOPOVER_SEARCH_DATA,
  ONE_NIGHT_STOPOVER as ONE_NIGHT_STOPOVER_SEARCH_DATA,
  TWO_NIGHTS_STOPOVER as TWO_NIGHTS_STOPOVER_SEARCH_DATA,
} from '../../../constants/searchFiltersConstants';
import { StyledStack } from './styles/searchFormStyles';

const fieldSetStyles = css`
  border: none;
`;

const legendStyles = css`
  margin-block-end: 16px;

  @media (max-width: ${breakpoints._768}) {
    margin-inline: auto;
  }
`;

type StopoverType =
  | typeof SHORT_STOPOVER_SEARCH_DATA
  | typeof ONE_DAY_STOPOVER_SEARCH_DATA
  | typeof ONE_NIGHT_STOPOVER_SEARCH_DATA
  | typeof TWO_NIGHTS_STOPOVER_SEARCH_DATA;

type StopoverSelectProps = {
  isTopStopoverControlSelected: boolean;
  updateAllStopovers: (isStopoverEnabled: boolean) => void;
  updateStopover: (stopover: string, isSelected: boolean) => void;
  isShortStopoverSelected: boolean;
  isOneDayStopoverSelected: boolean;
  isOneNightStopoverSelected: boolean;
  isTwoNightsStopoverSelected: boolean;
};

const StopoverSelect = ({
  isTopStopoverControlSelected,
  updateAllStopovers,
  updateStopover,
  isShortStopoverSelected,
  isOneDayStopoverSelected,
  isOneNightStopoverSelected,
  isTwoNightsStopoverSelected,
}: StopoverSelectProps) => {
  const { t } = useTranslation();
  const isWiderViewport = useMediaQuery(`(min-width: ${breakpoints._768})`);
  const { currentSearchData, setSearchData } = useSearch();
  const stopoversCollection = [
    isShortStopoverSelected,
    isOneDayStopoverSelected,
    isOneNightStopoverSelected,
    isTwoNightsStopoverSelected,
  ];

  const updateStopoverSearchData = (stopover: StopoverType) => {
    if (!setSearchData) {
      return;
    }

    const stopoverTypes = currentSearchData?.filters?.[STOPOVER_TYPES];
    const isStopoverEnabled = stopoverTypes?.includes(stopover);
    const filteredStopovers = stopoverTypes?.filter((item) => item !== stopover);
    const updatedStopovers = Array.isArray(stopoverTypes)
      ? [...stopoverTypes, stopover]
      : [stopover];

    if (!filteredStopovers?.length) {
      setSearchData({
        filters: {
          ...currentSearchData?.filters,
          [STOPOVER_TYPES]: null,
        },
      });
      return;
    }

    setSearchData({
      filters: {
        ...currentSearchData?.filters,
        [STOPOVER_TYPES]: isStopoverEnabled ? filteredStopovers : updatedStopovers,
      },
    });
  };

  const updateAllStopoversSearchData = () => {
    if (!setSearchData) {
      return;
    }

    if (isTopStopoverControlSelected) {
      setSearchData({ filters: { ...resetAllFilters(currentSearchData?.filters) } });
      return;
    }

    setSearchData({
      filters: {
        ...currentSearchData?.filters,
        [STOPOVER_TYPES]: [
          SHORT_STOPOVER_SEARCH_DATA,
          ONE_DAY_STOPOVER_SEARCH_DATA,
          ONE_NIGHT_STOPOVER_SEARCH_DATA,
          TWO_NIGHTS_STOPOVER_SEARCH_DATA,
        ],
      },
    });
  };

  useEffect(() => {
    if (stopoversCollection.every((stopover) => !stopover)) {
      updateStopover(STOPOVER_ENABLED, false);
    }
  });

  return (
    <>
      <Inline align={isWiderViewport ? 'start' : 'center'} alignY="top" spacing={8}>
        <Checkbox
          data-testid={`${STOPOVER_ENABLED}-input`}
          id="stopoverCheckbox"
          name={STOPOVER_ENABLED}
          onChange={() => {
            updateAllStopovers(!isTopStopoverControlSelected);
            updateAllStopoversSearchData();
          }}
        />
        <Input.Label htmlFor="stopoverCheckbox">{t('Searchform.Stopover.City')}</Input.Label>
      </Inline>
      {isTopStopoverControlSelected ? (
        <StyledStack
          align={isWiderViewport ? 'start' : 'center'}
          as="fieldset"
          className={fieldSetStyles}
        >
          <legend className={legendStyles}>{t('Searchform.Stopover.LengthOfStay.Text')}</legend>
          <Inline align="start" alignY="top" collapseBelow="768px" spacing={16}>
            <Inline spacing={8}>
              <Checkbox
                data-testid={`${SHORT_STOPOVER}-input`}
                id="shortStopCheckboxSearchForm"
                name={SHORT_STOPOVER}
                onChange={() => {
                  updateStopover(SHORT_STOPOVER, isShortStopoverSelected);
                  updateStopoverSearchData(SHORT_STOPOVER_SEARCH_DATA);
                }}
              />
              <Input.Label htmlFor="shortStopCheckboxSearchForm">
                {t('Searchform.Stopover.ShortStop')}
              </Input.Label>
            </Inline>

            <Inline spacing={8}>
              <Checkbox
                data-testid={`${ONE_DAY_STOPOVER}-input`}
                id="oneDayStopCheckboxSearchForm"
                name={ONE_DAY_STOPOVER}
                onChange={() => {
                  updateStopover(ONE_DAY_STOPOVER, isOneDayStopoverSelected);
                  updateStopoverSearchData(ONE_DAY_STOPOVER_SEARCH_DATA);
                }}
              />
              <Input.Label htmlFor="oneDayStopCheckboxSearchForm">
                {t('Searchform.Stopover.OneDayStop')}
              </Input.Label>
            </Inline>

            <Inline spacing={8}>
              <Checkbox
                data-testid={`${ONE_NIGHT_STOPOVER}-input`}
                id="oneNightStopCheckboxSearchForm"
                name={ONE_NIGHT_STOPOVER}
                onChange={() => {
                  updateStopover(ONE_NIGHT_STOPOVER, isOneNightStopoverSelected);
                  updateStopoverSearchData(ONE_NIGHT_STOPOVER_SEARCH_DATA);
                }}
              />
              <Input.Label htmlFor="oneNightStopCheckboxSearchForm">
                {t('Searchform.Stopover.OneNightStop')}
              </Input.Label>
            </Inline>

            <Inline spacing={8}>
              <Checkbox
                data-testid={`${TWO_NIGHTS_STOPOVER}-input`}
                id="twoNightsStopCheckboxSearchForm"
                name={TWO_NIGHTS_STOPOVER}
                onChange={() => {
                  updateStopover(TWO_NIGHTS_STOPOVER, isTwoNightsStopoverSelected);
                  updateStopoverSearchData(TWO_NIGHTS_STOPOVER_SEARCH_DATA);
                }}
              />
              <Input.Label htmlFor="twoNightsStopCheckboxSearchForm">
                {t('Searchform.Stopover.TwoNightsStop')}
              </Input.Label>
            </Inline>
          </Inline>
        </StyledStack>
      ) : null}
    </>
  );
};

type ActionsType = {
  updateAllStopovers: (isStopoverEnabled: boolean) => void;
  updateStopover: (stopover: string, isSelected: boolean) => void;
};

type StateType = {
  isTopStopoverControlSelected: boolean;
  isShortStopoverSelected: boolean;
  isOneDayStopoverSelected: boolean;
  isOneNightStopoverSelected: boolean;
  isTwoNightsStopoverSelected: boolean;
};

const mapStateToProps = (state: StateType, { form }: { form: string }) => {
  const myFormValueSelector = formValueSelector(form);
  return {
    isTopStopoverControlSelected: myFormValueSelector(state, STOPOVER_ENABLED),
    isShortStopoverSelected: myFormValueSelector(state, SHORT_STOPOVER),
    isOneDayStopoverSelected: myFormValueSelector(state, ONE_DAY_STOPOVER),
    isOneNightStopoverSelected: myFormValueSelector(state, ONE_NIGHT_STOPOVER),
    isTwoNightsStopoverSelected: myFormValueSelector(state, TWO_NIGHTS_STOPOVER),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { form }: { form: string }) => ({
  updateAllStopovers: (isStopoverEnabled: boolean) => {
    dispatch(changeFormValue(form, SHORT_STOPOVER, isStopoverEnabled));
    dispatch(changeFormValue(form, ONE_DAY_STOPOVER, isStopoverEnabled));
    dispatch(changeFormValue(form, ONE_NIGHT_STOPOVER, isStopoverEnabled));
    dispatch(changeFormValue(form, TWO_NIGHTS_STOPOVER, isStopoverEnabled));
  },
  updateStopover: (stopover: string, isSelected: boolean) => {
    dispatch(changeFormValue(form, stopover, isSelected));
  },
});

const mergeProps = (state: StateType, actions: ActionsType, props: Object) => {
  return {
    ...state,
    ...actions,
    ...props,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StopoverSelect);
