import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { CABIN_UPGRADE } from '../products';
import CabinUpgrade from './components/CabinUpgrade';
const formSection = CABIN_UPGRADE.name;
export const cabinUpgrade = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...CABIN_UPGRADE
};
export default CabinUpgrade;