import { css, cx, etiColors } from '@eti/styles';
import type { ComponentPropsWithoutRef, ReactNode, Ref } from 'react';

const getBorderColor = (isError?: boolean, isFocused?: boolean, menuIsOpen?: boolean) => {
  if (isFocused || menuIsOpen) {
    return etiColors.info.solid;
  }

  if (isError) {
    return etiColors.critical.solid;
  }

  return etiColors.grayScale[7];
};

const controlStyles = (
  isDisabled?: boolean,
  isError?: boolean,
  isFocused?: boolean,
  menuIsOpen?: boolean,
) => css`
  align-items: center;
  background-color: ${isDisabled ? etiColors.grayScale[2] : `#fff`};
  border-color: ${getBorderColor(isError, isFocused, menuIsOpen)};
  border-radius: ${menuIsOpen ? '3px 3px 0 0;' : '3px'};
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  height: 3rem;
  justify-content: space-between;
  min-height: 38px;
  outline: 0;
  padding: 0 1rem;
  position: relative;
  transition: all 100ms;
`;

interface ControlProps {
  children: ReactNode;
  className?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  innerRef: Ref<HTMLDivElement>;
  innerProps: Exclude<ComponentPropsWithoutRef<'div'>, 'className'>;
  menuIsOpen?: boolean;
  selectProps: {
    controlProps: {
      isError?: boolean;
    };
  };
}

const Control = ({
  children,
  className,
  isDisabled,
  isFocused,
  innerRef,
  innerProps,
  menuIsOpen,
  selectProps,
}: ControlProps) => (
  <div
    className={cx(
      controlStyles(isDisabled, selectProps?.controlProps?.isError, isFocused, menuIsOpen),
      className,
    )}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </div>
);

export default Control;
