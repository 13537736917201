import { Route, useLocation } from 'react-router-dom';
import type { ElementType } from 'react';
import { useEffect } from 'react';
import { useAuth } from '@eti/authentication';
import { usePageViewDataLayerEvent } from '../../data-layer/dataLayer';

interface ContactUsRouteProps {
  component: ElementType;
  setIsMenuVisible: (pathname: { type: string }) => void;
  exact?: boolean;
  path: string;
  contactUsOrderListComponent: ElementType;
  props: any;
}

const ContactUsRoute = ({
  component: Component,
  contactUsOrderListComponent: ContactUsOrderListComponent,
  setIsMenuVisible,
  props,
  ...rest
}: ContactUsRouteProps) => {
  const location = useLocation();
  const { user } = useAuth();

  usePageViewDataLayerEvent();
  useEffect(() => {
    setIsMenuVisible({ type: location.pathname });
  }, [setIsMenuVisible, location.pathname]);

  return (
    <Route
      {...rest}
      render={() => {
        if (user) {
          return <ContactUsOrderListComponent />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default ContactUsRoute;
