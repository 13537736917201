import { addYears, differenceInCalendarDays, getYear, toDate } from 'date-fns';
import { parseISO } from 'date-fns/parseISO';
import { format as formatDate } from 'date-fns/format';
export { default as createAutoCorrectedDatePipe } from './createAutoCorrectedDatePipe';

// TODO: check related jira https://jira.etraveli.net/browse/WEB-3016
const getLocale = languageCode => {
  switch (languageCode) {
    case 'no':
      return 'nb';
    case 'zh':
      return 'zhCN';
    case 'ar':
      return 'arSA';
    default:
      return languageCode;
  }
};
export const getLazyLocale = async options => {
  let locale;
  if (options.locale && options.locale !== 'en') {
    try {
      const tempLocale = getLocale(options.locale);
      const locales = await import(`date-fns/locale`);
      locale = locales[tempLocale];
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
  return locale;
};
export const createFormatter = async function (options) {
  if (options === void 0) {
    options = {};
  }
  const locale = await getLazyLocale(options);
  const formatter = {
    format: (date, string, opts) => formatDate(date, string, {
      ...opts,
      locale
    })
  };
  global.etiDateFormatter = formatter;
  return formatter;
};
const isValidDate = date => !Number.isNaN(date.getTime());
export const convertToDate = dateValue => typeof dateValue === 'string' ? parseISO(dateValue) : toDate(dateValue);
export const format = function (dateValue) {
  const date = convertToDate(dateValue);
  if (!isValidDate(date)) {
    return 'Invalid Date';
  }
  for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }
  return global.etiDateFormatter ? global.etiDateFormatter.format(date, ...rest) : formatDate(date, ...rest);
};
export const durationInDays = (fromDate, untilDate) => differenceInCalendarDays(untilDate, fromDate);
export const getExpiryDateConstraints = function (baseDate, passportExpiryMaxFutureYears) {
  if (passportExpiryMaxFutureYears === void 0) {
    passportExpiryMaxFutureYears = 10;
  }
  return {
    minYear: getYear(baseDate),
    maxYear: getYear(addYears(baseDate, passportExpiryMaxFutureYears)),
    minMonth: 0
  };
};
export const getBirthDateConstraints = baseDate => ({
  minYear: 1900,
  maxYear: getYear(baseDate),
  minMonth: 1
});