import { formValueSelector } from 'redux-form';
import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { FAST_TRACK } from '../products';
import FastTrack from './components/FastTrack';
import { withParseAvailability } from './components/utils';
const formSection = FAST_TRACK.name;
const getAvailability = form => state => {
  var _formData$cartBreakDo;
  const formData = formValueSelector(form)(state, formSection);
  return (_formData$cartBreakDo = formData.cartBreakDown) !== null && _formData$cartBreakDo !== void 0 ? _formData$cartBreakDo : null;
};
export const fastTrack = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getAvailability,
  withParseAvailability,
  ...FAST_TRACK
};
export default FastTrack;