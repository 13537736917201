import { Fragment, useRef, useState } from 'react';
import { breakpoints, SiteAuxiliaryButton, vars } from '@etg/wings';
import type { SubMenuItem } from '@eti/schema-types';
import { css } from '@eti/styles';
import { Question } from '@phosphor-icons/react';
import { observeClickOutside } from 'react-ref-observe';
import { useMediaQuery } from 'usehooks-ts';
import { populateListWithId } from '../../../utils/idGenerator';
import { useCloseOnEsc } from '../../../utils/useCloseOnEsc';
import { useCloseOnFocusOut } from '../../../utils/useCloseOnFocusOut';
import { useNavigateOnArrowKeys } from '../../../utils/useNavigateOnArrowKeys';
import DropdownMenu from './DropdownMenu';

const buttonWrapperStyles = css`
  position: relative;
`;

const buttonStyles = css`
  &:focus-visible {
    outline-color: ${vars.colors.header.content};
  }

  @media print {
    display: none;
  }
`;

interface SupportMenuProps {
  menu: {
    id: string;
    menuType: string;
    name: string;
    path: string | null;
    subMenu: SubMenuItem[];
  }[];
}

const SupportMenu = ({ menu }: SupportMenuProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<string | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLUListElement | null>(null);
  const isWiderViewport = useMediaQuery(`(min-width: ${breakpoints._970})`);

  const closeDropdown = () => {
    setIsSubMenuOpen(null);
  };

  observeClickOutside([wrapperRef], closeDropdown);
  useCloseOnEsc(containerRef, closeDropdown, buttonRef);
  useCloseOnFocusOut(wrapperRef, closeDropdown);

  const toggleSubMenu = (id: string) => {
    if (isSubMenuOpen === null) {
      setIsSubMenuOpen(id);
    } else {
      closeDropdown();
    }
  };

  const { handleKeyDown } = useNavigateOnArrowKeys({
    buttonRef,
    containerRef,
    isSubMenuOpen: Boolean(isSubMenuOpen),
  });

  return (
    <div data-testid="support-menu" ref={wrapperRef}>
      {menu.map(({ id, name, subMenu }) => (
        <Fragment key={id}>
          {subMenu?.length > 0 && (
            <div className={buttonWrapperStyles}>
              <SiteAuxiliaryButton
                aria-controls="support-menu"
                aria-expanded={isSubMenuOpen !== null}
                className={buttonStyles}
                data-testid={`menu-button-${name}`}
                hideLabel={!isWiderViewport}
                iconPrefix={<Question aria-hidden />}
                onClick={() => toggleSubMenu(id)}
                onKeyDown={handleKeyDown}
                ref={buttonRef}
                withBorder={false}
              >
                {name}
              </SiteAuxiliaryButton>
              {isSubMenuOpen === id && (
                <DropdownMenu
                  id="support-menu"
                  menu={populateListWithId(subMenu)}
                  onKeyDown={handleKeyDown}
                  ref={containerRef}
                />
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SupportMenu;
