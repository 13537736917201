import type { CaptionProps } from 'react-day-picker';
import { css } from '@eti/styles';
import { date } from '@eti/utils';
import { useDateFormat } from '@eti/providers';
import { Text, vars } from '@etg/wings';
import { format } from 'date-fns';

const captionStyles = css`
  padding-block: 8px;
`;

const captionBorderStyles = css`
  border-block-start: 1px solid ${vars.colors.divider};
  padding-block-start: 16px;
  text-align: center;
`;
const captionWrapperStyles = css`
  text-align: center;
`;

const CustomCaption = ({ displayIndex, displayMonth }: CaptionProps) => {
  const dateFormat = useDateFormat();

  const id = `daypicker-custom-caption-${format(displayMonth, 'yyyy-MM')}`;

  return (
    <div className={displayIndex === 0 ? captionWrapperStyles : captionBorderStyles} id={id}>
      <Text className={captionStyles}>{date.format(displayMonth, dateFormat.longMonthYear)}</Text>
    </div>
  );
};
export default CustomCaption;
