import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const CancellationProtectionPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="content/cookies"
      pageTitle={t('PageTitle.rf.cancellation-protection.action')}
      textKey="CancellationProtection.HtmlContent"
    />
  );
};

export default CancellationProtectionPage;
