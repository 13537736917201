import { string } from '@eti/utils';
import { getTime } from 'date-fns';
import { useQuery } from '@apollo/client';
export const useImperativeQuery = (query, options) => {
  const {
    refetch
  } = useQuery(query, {
    skip: true,
    ...options
  });
  return _ref => {
    let {
      variables
    } = _ref;
    return refetch(variables);
  };
};
const setTravelerName = (travelerNames, defaultTravelerLabel, travelerIndex, travelerId) => {
  const travelerWithName = travelerNames.find(traveler => traveler.travelerId === travelerId);
  return travelerWithName ? travelerWithName.fullName : string.insertArgument(defaultTravelerLabel, travelerIndex);
};
export const getTravelers = function (order, travelerNames, travelerLabel) {
  if (travelerNames === void 0) {
    travelerNames = [];
  }
  const travelers = order === null || order === void 0 ? void 0 : order.travelers;
  return travelers ? travelers.map((_ref2, index) => {
    let {
      ageType,
      id,
      showDateOfBirth,
      name
    } = _ref2;
    return {
      ageType,
      id,
      name: name !== null && name !== void 0 ? name : setTravelerName(travelerNames, travelerLabel, index + 1, id),
      dateOfBirthReadOnly: showDateOfBirth
    };
  }) : [];
};
export const getProvinces = sellSpecification => {
  if (!(sellSpecification !== null && sellSpecification !== void 0 && sellSpecification.availableChoices) || sellSpecification.availableChoices.length === 0) {
    return [];
  }
  return sellSpecification.availableChoices.map(province => province ? {
    value: province.id,
    label: province.name
  } : null).filter(option => option != null);
};
export const getSelectionVariables = (province, dateOfBirth, id) => {
  var _province$value;
  return {
    travelerId: Number(id),
    birthDate: Number(getTime(new Date(dateOfBirth))),
    provinceId: Number((_province$value = province === null || province === void 0 ? void 0 : province.value) !== null && _province$value !== void 0 ? _province$value : null)
  };
};
export const getTravelerPrice = (data, id) => {
  var _pricePerTravelers, _data$ancillaryItem, _travelerData$price$p, _travelerData$price;
  const pricePerTravelers = (_pricePerTravelers = data === null || data === void 0 || (_data$ancillaryItem = data.ancillaryItem) === null || _data$ancillaryItem === void 0 || (_data$ancillaryItem = _data$ancillaryItem.sellSpecification) === null || _data$ancillaryItem === void 0 ? void 0 : _data$ancillaryItem.pricePerTravelers) !== null && _pricePerTravelers !== void 0 ? _pricePerTravelers : [];
  const travelerData = pricePerTravelers.find(_ref3 => {
    let {
      travelerId
    } = _ref3;
    return travelerId === id;
  });
  return (_travelerData$price$p = travelerData === null || travelerData === void 0 || (_travelerData$price = travelerData.price) === null || _travelerData$price === void 0 || (_travelerData$price = _travelerData$price.price) === null || _travelerData$price === void 0 ? void 0 : _travelerData$price.value) !== null && _travelerData$price$p !== void 0 ? _travelerData$price$p : null;
};
export const handleFetchPrice = (query, productId) => async (province, dateOfBirth, id) => {
  if (!query) {
    return null;
  }
  const {
    data,
    error
  } = await query({
    variables: {
      selection: getSelectionVariables(province, dateOfBirth, id),
      productId
    }
  });
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(error);
      } else {
        resolve(getTravelerPrice(data, id));
      }
    }, 300);
  });
};