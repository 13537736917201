import type { quickSortPricesType } from '../../constants/quickSortConstants';
import { quickSortDropdownMapping } from '../../constants/quickSortConstants';
import type { cabinClasses } from '../../constants/cabinClassesConstants';
import {
  AIRLINES,
  CHECKED_BAGGAGE_INCLUDED,
  EXCLUDE_SELF_TRANSFER,
  NUMBER_OF_STOPS,
  PRICE,
  STOPOVER_TYPES,
  TRAVEL_TIME,
  TRIP_0_ARRIVAL_TIME,
  TRIP_0_DEPARTURE_TIME,
  TRIP_1_ARRIVAL_TIME,
  TRIP_1_DEPARTURE_TIME,
  TRIP_2_ARRIVAL_TIME,
  TRIP_2_DEPARTURE_TIME,
  TRIP_3_ARRIVAL_TIME,
  TRIP_3_DEPARTURE_TIME,
  TRIP_4_ARRIVAL_TIME,
  TRIP_4_DEPARTURE_TIME,
} from '../../constants/searchFiltersConstants';
import { tripTypes } from '../../constants/tripTypesConstants';

interface Route {
  departureAt: number;
  departureDate: string;
  destination?: string;
  destinationCity?: string;
  destinationCityCode?: string;
  destinationCode?: string;
  destinationContinent?: string;
  destinationContinentCode?: string;
  destinationCountry?: string;
  destinationCountryCode?: string;
  destinationName?: string;
  origin?: string;
  originCity?: string;
  originCityCode?: string;
  originCode?: string;
  originContinent?: string;
  originContinentCode?: string;
  originCountry?: string;
  originCountryCode?: string;
  originName?: string;
}

export interface SearchParameters {
  adults: number;
  cabinClass?: keyof typeof cabinClasses;
  childAges: number[];
  destination?: string;
  direct: boolean;
  endDate: string;
  journeyType: keyof typeof tripTypes;
  origin?: string;
  routes: Route[];
  startDate: string;
  travelers: string;
  flyRome?: boolean;
}

const formatSearchParameters = (params: SearchParameters) => ({
  cabinClass: params.cabinClass,
  date: {
    departure: params.startDate,
    return:
      params.journeyType === tripTypes.MULTI_STOP
        ? params?.routes[params?.routes.length - 1].departureDate
        : params.endDate,
  },
  destination: {
    city: params.destination,
  },
  origin: {
    city: params.origin,
  },
  routes: params.routes,
  shouldOnlyShowDirectFlights: params.direct,
  travelers: {
    adults: params.adults,
    childAges: params.childAges,
    summary: params.travelers,
  },
  tripType: params.journeyType,
  flyRome: params.flyRome,
});

const getDefaultValues = (
  searchParameters: SearchParameters,
  sortOrderValue: keyof typeof quickSortPricesType,
) => ({
  filters: {
    [AIRLINES]: null,
    [CHECKED_BAGGAGE_INCLUDED]: null,
    [EXCLUDE_SELF_TRANSFER]: null,
    [NUMBER_OF_STOPS]: searchParameters?.direct ? '0' : null,
    [PRICE]: null,
    [STOPOVER_TYPES]: null,
    [TRAVEL_TIME]: null,
    [TRIP_0_ARRIVAL_TIME]: null,
    [TRIP_0_DEPARTURE_TIME]: null,
    [TRIP_1_ARRIVAL_TIME]: null,
    [TRIP_1_DEPARTURE_TIME]: null,
    [TRIP_2_ARRIVAL_TIME]: null,
    [TRIP_2_DEPARTURE_TIME]: null,
    [TRIP_3_ARRIVAL_TIME]: null,
    [TRIP_3_DEPARTURE_TIME]: null,
    [TRIP_4_ARRIVAL_TIME]: null,
    [TRIP_4_DEPARTURE_TIME]: null,
  },
  selectedSortType: sortOrderValue,
  flyRome: searchParameters.flyRome,
});

export const setInitialSearchContextData = (
  searchParameters: SearchParameters,
  sortOrderValue: keyof typeof quickSortDropdownMapping = 'Promotion',
) => {
  const storedSessionData = JSON.parse(sessionStorage.getItem('currentSearchData') || '{}');
  const data = searchParameters?.origin
    ? formatSearchParameters(searchParameters)
    : storedSessionData;

  const defaultSortOrderValue = quickSortDropdownMapping[sortOrderValue];
  const dataWithDefaultSorting = {
    ...data,
    ...getDefaultValues(searchParameters, defaultSortOrderValue),
  };

  sessionStorage.setItem('currentSearchData', JSON.stringify(dataWithDefaultSorting));
  return dataWithDefaultSorting;
};
