import type { ChangeEvent } from 'react';
import { useTranslation } from '@eti/providers';
import { passengerFields } from '../../constants/formFields';
import type { Passengers } from '../../models';
import { getInfantsToggleConstraints, isValidInfantSelection } from '../../utils/passengers';
import PassengerRow from './PassengerRow';

interface InfantPassengersProps {
  selectedPassengers: Passengers;
}

const InfantPassengers = ({ selectedPassengers }: InfantPassengersProps) => {
  const { t } = useTranslation();
  const toggleConstraints = getInfantsToggleConstraints(selectedPassengers);

  const onChange = (event: ChangeEvent<HTMLButtonElement>, newValue: number) => {
    const changeIsValid = isValidInfantSelection(newValue, selectedPassengers);

    if (!changeIsValid) {
      event.preventDefault();
    }
  };

  return (
    <PassengerRow
      description={t('Searchform.Passengers.Infants.Information')}
      label={t('Air.Search.Infants')}
      name={passengerFields.INFANTS}
      onChange={onChange}
      toggleConstraints={toggleConstraints}
    />
  );
};

export default InfantPassengers;
