import { Input, Stack } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import type { ChangeEvent, ComponentPropsWithoutRef, Ref } from 'react';
import { useCallback, useMemo } from 'react';
import type { RegisterOptions } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useControllerWithWarnings } from '../../custom-hooks/useControllerWithWarnings';

const capitalize = (value: string) =>
  value[0] ? value[0].toLocaleUpperCase() + value.slice(1) : value;

interface FormInputProps extends ComponentPropsWithoutRef<'input'> {
  className?: string;
  description?: string;
  label?: string;
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  labelRef?: Ref<HTMLLabelElement>;
  validations?: RegisterOptions;
  warningValidation?: (value: string) => boolean | string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  normalize?: (value: string) => string;
  shouldForceCapitalize?: boolean;
}

const FormInput = ({
  className,
  description,
  label,
  name,
  placeholder,
  isRequired,
  labelRef,
  normalize,
  validations,
  onChange,
  shouldForceCapitalize = false,
  warningValidation,
  ...rest
}: FormInputProps) => {
  const { t, nonDebugT } = useTranslation();
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error, warning },
  } = useControllerWithWarnings({ name, control, rules: validations, warningValidation });

  const labelId = `${name}-label`;
  const errorId = `${name}-error`;
  const descriptionId = `${name}-description`;
  const resolvedLabel = useMemo(
    () => (isRequired ? nonDebugT('Input.Field.Required', label) : label),
    [isRequired, label, nonDebugT],
  );

  const errorMessage = useMemo(() => (error?.message ? t(error.message) : undefined), [error, t]);
  const warningMessage = useMemo(() => (warning ? t(warning) : undefined), [warning, t]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let normalizedValue = e.target.value;
      if (shouldForceCapitalize) {
        normalizedValue = capitalize(normalizedValue);
      }
      if (normalize) {
        normalizedValue = normalize(normalizedValue);
      }

      field.onChange(normalizedValue);
      onChange?.(e);
    },
    [field, normalize, onChange, shouldForceCapitalize],
  );

  return (
    <Stack className={className} spacing={4}>
      <Input.Label htmlFor={name} id={labelId} ref={labelRef}>
        {resolvedLabel}
      </Input.Label>
      <Input
        {...field}
        aria-labelledby={`${labelId} ${errorId}`}
        descriptionId={descriptionId}
        errorId={errorId}
        hasError={Boolean(error)}
        hasWarning={Boolean(warningMessage)}
        id={name}
        onChange={handleOnChange}
        placeholder={placeholder}
        {...rest}
      />
      <Input.Meta
        description={description}
        descriptionId={descriptionId}
        error={errorMessage}
        errorId={errorId}
        hasError={Boolean(error)}
        hasWarning={Boolean(warningMessage)}
        warning={warningMessage}
      />
    </Stack>
  );
};

export default FormInput;
