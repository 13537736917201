import { formValueSelector } from 'redux-form';
const getGraphQlSelectionManuLife = (productId, formSection) => _ref => {
  var _formData$value, _formData$province;
  let {
    state,
    form
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  if (!formData || !((_formData$value = formData.value) !== null && _formData$value !== void 0 && _formData$value.value) || !((_formData$province = formData.province) !== null && _formData$province !== void 0 && _formData$province.value) || !formData.travelers) {
    return null;
  }
  const selectionTravelerChoice = Object.values(formData.travelers).flatMap(traveler => {
    if (!traveler.selected) {
      return [];
    }
    return {
      choiceId: formData.province.value,
      travelerId: traveler.id
    };
  });
  if (!selectionTravelerChoice.length) {
    return null;
  }
  return {
    productId,
    selectionTravelerChoice
  };
};
export const getDBFGraphQlSelectionManuLife = (productId, formSection) => _ref2 => {
  var _formData$value2, _formData$province2;
  let {
    state,
    form
  } = _ref2;
  const formData = formValueSelector(form)(state, formSection);
  if (!formData || ((_formData$value2 = formData.value) === null || _formData$value2 === void 0 ? void 0 : _formData$value2.value) == null || !((_formData$province2 = formData.province) !== null && _formData$province2 !== void 0 && _formData$province2.value) || !formData.travelers) {
    return null;
  }
  const selectionTravelerChoice = Object.values(formData.travelers).flatMap(traveler => {
    if (!traveler.selected) {
      return [];
    }
    return {
      choiceId: formData.province.value,
      travelerId: traveler.id
    };
  });
  if (!selectionTravelerChoice.length) {
    return {
      productId,
      selectionTravelerChoice: []
    };
  }
  return {
    productId,
    selectionTravelerChoice
  };
};
export default getGraphQlSelectionManuLife;