import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { CLIMATE_COMPENSATION } from '../products';
import ClimateCompensationNotification from './components/ClimateCompensationNotification';
import getIcon from './utils/icon';
const formSection = CLIMATE_COMPENSATION.name;
export const climateCompensation = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  ...CLIMATE_COMPENSATION
};
export { ClimateCompensationNotification };