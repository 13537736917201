import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { QPASS } from '../products';
import QPass from './components/QPass';
const formSection = QPASS.name;
export const qPass = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  ...QPASS
};
export default QPass;