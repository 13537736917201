import type { ChangeEvent } from 'react';
import { useTranslation } from '@eti/providers';
import type { MapDispatchToProps } from 'react-redux';
import { connect } from 'react-redux';
import type { FormAction } from 'redux-form';
import { change } from 'redux-form';
import { SEARCH } from '../../../../constants/formNamesConstants';
import { PASSENGERS_FORM_SECTION, passengerFields } from '../../constants/formFields';
import type { Passengers, PassengerConstraints } from '../../models';
import { getAdultsToggleConstraints, isValidAdultSelection } from '../../utils/passengers';
import PassengerRow from './PassengerRow';

interface ConnectedProps {
  constraints?: PassengerConstraints;
  selectedPassengers: Passengers;
}

interface ConnectedDispatch {
  setAmountOfInfants: (newAmountOfInfants: number) => FormAction;
}

interface AdultPassengersProps extends ConnectedProps, ConnectedDispatch {}

const mapDispatchToProps: MapDispatchToProps<ConnectedDispatch, ConnectedProps> = (dispatch) => ({
  setAmountOfInfants: (newAmountOfInfants: number) =>
    dispatch(
      change(SEARCH, `${PASSENGERS_FORM_SECTION}.${passengerFields.INFANTS}`, newAmountOfInfants),
    ),
});

const AdultPassengers = ({
  constraints,
  selectedPassengers,
  setAmountOfInfants,
}: AdultPassengersProps) => {
  const { t } = useTranslation();
  const toggleConstraints = getAdultsToggleConstraints(selectedPassengers, constraints);

  const onChange = (event: ChangeEvent<HTMLButtonElement>, newValue: number) => {
    const changeIsValid = isValidAdultSelection(newValue, selectedPassengers, constraints);

    if (!changeIsValid) {
      event.preventDefault();
    } else {
      const newAmountOfInfants = Math.min(newValue, selectedPassengers.infants);

      setAmountOfInfants(newAmountOfInfants);
    }
  };

  return (
    <PassengerRow
      label={t('Air.Search.Adults')}
      name={passengerFields.ADULTS}
      onChange={onChange}
      toggleConstraints={toggleConstraints}
    />
  );
};

export default connect(null, mapDispatchToProps)(AdultPassengers);
