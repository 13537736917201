import { useEffect, useState } from 'react';
import { useTranslation } from '@eti/providers';
import { COUNTRY_CODES } from '../../../utils/constants';
import { CPF, PASSPORT, DOCUMENT, RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT, RUSSIAN_PASSPORT_NUMBER_INPUT, BIRTH_CERTIFICATE_NUMBER_INPUT, MILITARY_ID_NUMBER_INPUT, INTERNATIONAL_PASSPORT_NUMBER_INPUT, FOREIGN_PASSPORT_NUMBER_INPUT, FOREIGN_DOCUMENT_NUMBER_INPUT } from './constants';
export const useDocumentTypes = (code, documentCountryCode, shouldShowTravelDocumentCpfNumber) => {
  const {
    t
  } = useTranslation();
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    let availableDocuments = [];
    switch (`${documentCountryCode}-${code}`) {
      case 'RU-RU':
        availableDocuments = [{
          label: t('Passport.DocumentType.PassportOfTheRussianFederation.Text'),
          type: PASSPORT,
          value: RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT
        }, {
          label: t('Passport.DocumentType.RussianPassport.Text'),
          type: PASSPORT,
          value: RUSSIAN_PASSPORT_NUMBER_INPUT
        }, {
          label: t('Passport.DocumentType.BirthCertificate.Text'),
          type: DOCUMENT,
          value: BIRTH_CERTIFICATE_NUMBER_INPUT
        }, {
          label: t('Passport.DocumentType.MilitaryID.Text'),
          type: DOCUMENT,
          value: MILITARY_ID_NUMBER_INPUT
        }, {
          label: t('Passport.DocumentType.InternationalPassport.Text'),
          type: PASSPORT,
          value: INTERNATIONAL_PASSPORT_NUMBER_INPUT
        }, {
          label: t('Passport.DocumentType.SailorPassport.Text'),
          value: t('Passport.DocumentType.SailorPassport.Text'),
          type: PASSPORT
        }, {
          label: t('Passport.DocumentType.UDL.Text'),
          value: t('Passport.DocumentType.UDL.Text'),
          type: DOCUMENT
        }];
        break;
      case 'BR-BR':
        if (shouldShowTravelDocumentCpfNumber) {
          availableDocuments = [{
            label: t('Passport.DocumentType.CPF.Text'),
            type: DOCUMENT,
            value: CPF
          }];
        }
        availableDocuments = [...availableDocuments, {
          label: t('Passport.DocumentType.ForeignPassport.Text'),
          type: PASSPORT,
          value: t('Passport.DocumentType.ForeignPassport.Text')
        }];
        break;
      default:
        if (code === COUNTRY_CODES.RU) {
          availableDocuments = [{
            label: t('Passport.DocumentType.ForeignPassport.Text'),
            type: PASSPORT,
            value: t('Passport.DocumentType.ForeignPassport.Text')
          }, {
            label: t('Passport.DocumentType.ForeignDocument.Text'),
            type: DOCUMENT,
            value: t('Passport.DocumentType.ForeignDocument.Text')
          }];
        } else if (code === COUNTRY_CODES.BR) {
          availableDocuments = [{
            label: t('Passport.DocumentType.ForeignPassport.Text'),
            type: PASSPORT,
            value: t('Passport.DocumentType.ForeignPassport.Text')
          }];
        } else {
          availableDocuments = [{
            label: t('Passport.DocumentType.InternationalPassport.Text'),
            type: PASSPORT,
            value: INTERNATIONAL_PASSPORT_NUMBER_INPUT
          }];
        }
        break;
    }
    setDocuments(availableDocuments);
  }, [code, documentCountryCode, shouldShowTravelDocumentCpfNumber, t]);
  return documents !== null && documents !== void 0 ? documents : [];
};
export const useValidationRuleName = (id, countryCode, isDocumentTypePassport, selectedDocument) => {
  const [validationRuleName, setValidationRuleName] = useState(null);
  useEffect(() => {
    setValidationRuleName(() => {
      if (isDocumentTypePassport) {
        return `${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`;
      }
      if (countryCode === COUNTRY_CODES.RU && selectedDocument !== null && selectedDocument !== void 0 && selectedDocument.value && selectedDocument.value === RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT && selectedDocument.value === RUSSIAN_PASSPORT_NUMBER_INPUT && selectedDocument.value === BIRTH_CERTIFICATE_NUMBER_INPUT && selectedDocument.value === MILITARY_ID_NUMBER_INPUT && selectedDocument.value === INTERNATIONAL_PASSPORT_NUMBER_INPUT) {
        return `${selectedDocument.value}-${id}`;
      }
      if (countryCode === COUNTRY_CODES.BR && (selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument.value) === CPF) {
        return `${CPF}-${id}`;
      }
      return `${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`;
    });
  }, [isDocumentTypePassport, countryCode, id, selectedDocument]);
  return validationRuleName;
};
export const getValidationRuleName = (id, countryCode, isDocumentTypePassport, selectedDocument) => {
  if (countryCode === COUNTRY_CODES.RU && selectedDocument !== null && selectedDocument !== void 0 && selectedDocument.value && selectedDocument.value === RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT && selectedDocument.value === RUSSIAN_PASSPORT_NUMBER_INPUT && selectedDocument.value === BIRTH_CERTIFICATE_NUMBER_INPUT && selectedDocument.value === MILITARY_ID_NUMBER_INPUT && selectedDocument.value === INTERNATIONAL_PASSPORT_NUMBER_INPUT) {
    return `${selectedDocument.value}-${id}`;
  }
  if (countryCode === COUNTRY_CODES.BR && (selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument.value) === CPF) {
    return `${CPF}-${id}`;
  }
  if (isDocumentTypePassport) {
    return `${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`;
  }
  return `${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`;
};
export const useTravelDocumentHint = (selectedDocument, isDocumentTypePassport) => {
  const {
    t
  } = useTranslation();
  const [travelDocumentHint, setTravelDocumentHint] = useState('');
  useEffect(() => {
    setTravelDocumentHint(() => {
      switch (selectedDocument) {
        case CPF:
          return t('TravelerForm.TravelDocumentNumber.CPF.HintLabel');
        case RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT:
          return t('TravelerForm.TravelDocumentNumber.PassportOfTheRussianFederation.HintLabel');
        case RUSSIAN_PASSPORT_NUMBER_INPUT:
          return t('TravelerForm.TravelDocumentNumber.RussianPassport.HintLabel');
        case BIRTH_CERTIFICATE_NUMBER_INPUT:
          return t('TravelerForm.TravelDocumentNumber.BirthCertificate.HintLabel');
        case MILITARY_ID_NUMBER_INPUT:
          return t('TravelerForm.TravelDocumentNumber.MilitaryId.HintLabel');
        case INTERNATIONAL_PASSPORT_NUMBER_INPUT:
          return t('TravelerForm.TravelDocumentNumber.InternationalPassport.HintLabel');
        default:
          if (isDocumentTypePassport) {
            return t('TravelerForm.TravelDocumentNumber.HintLabel');
          }
          return t('TravelerForm.TravelDocumentNumber.Document.HintLabel');
      }
    });
  }, [selectedDocument, isDocumentTypePassport, travelDocumentHint, t]);
  return travelDocumentHint;
};