import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { SMS } from '../products';
import Sms from './components/Sms';
import getIcon from './utils/icon';
const formSection = SMS.name;
export const sms = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  ...SMS
};
export default Sms;