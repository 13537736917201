import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { SEARCH } from '../../../constants/formNamesConstants';
import { cabinClasses } from '../../../constants/cabinClassesConstants';
import { tripTypes } from '../../../constants/tripTypesConstants';
import {
  CARRIER_CODES,
  DIRECT_FLIGHT,
  MULTI_BOUND,
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  SHORT_STOPOVER,
  SINGLE_BOUND,
  STOPOVER_ENABLED,
  TWO_NIGHTS_STOPOVER,
} from '../constants/formFieldNames';
import {
  CABIN_CLASS,
  PASSENGERS_FORM_SECTION,
  TRIP_TYPE,
  passengerFields,
} from '../constants/formFields';
import { CHILD_AGE_DEFAULT_VALUE, MAX_INFANT_AGE, MAX_PASSENGERS } from '../constants/passengers';
import SearchForm from '../components/SearchForm';

const searchDefaultValue = {
  [CABIN_CLASS]: cabinClasses.ECONOMY,
  [CARRIER_CODES]: [],
  [SHORT_STOPOVER]: false,
  [SINGLE_BOUND]: {},
  [STOPOVER_ENABLED]: false,
  [MULTI_BOUND]: [{}, {}],
  [ONE_DAY_STOPOVER]: false,
  [ONE_NIGHT_STOPOVER]: false,
  [PASSENGERS_FORM_SECTION]: {
    [passengerFields.ADULTS]: 1,
    [passengerFields.CHILDREN]: 0,
    [passengerFields.CHILDREN_AGES]: Array(MAX_PASSENGERS).fill(CHILD_AGE_DEFAULT_VALUE),
    [passengerFields.INFANTS]: 0,
    [passengerFields.INFANTS_AGES]: Array(MAX_PASSENGERS).fill(MAX_INFANT_AGE),
  },
  [TRIP_TYPE]: tripTypes.RETURN,
  [TWO_NIGHTS_STOPOVER]: false,
};

const mapStateToProps = (
  state,
  { search = searchDefaultValue, rebookingData, stopoverEnabled },
) => {
  const bounds = formValueSelector(SEARCH)(state, MULTI_BOUND);

  return {
    selectedTripType: formValueSelector(SEARCH)(state, TRIP_TYPE),
    numberOfBounds: bounds?.length,
    initialValues: {
      [CABIN_CLASS]: search.cabinClass,
      [CARRIER_CODES]: search.carrierCodes,
      [DIRECT_FLIGHT]: search.hasDirectFlight,
      [MULTI_BOUND]: search.multiBound,
      [ONE_DAY_STOPOVER]: (stopoverEnabled && search.flyRome) || search.oneDayStopover,
      [PASSENGERS_FORM_SECTION]: rebookingData
        ? rebookingData.initialValues.passengers
        : search.passengers,
      [SHORT_STOPOVER]: (stopoverEnabled && search.flyRome) || search.shortStopover,
      [SINGLE_BOUND]: search.singleBound,
      [STOPOVER_ENABLED]: (stopoverEnabled && search.flyRome) || search.stopoverEnabled,
      [TRIP_TYPE]: search.tripType,
      [ONE_NIGHT_STOPOVER]: (stopoverEnabled && search.flyRome) || search.oneNightStopover,
      [TWO_NIGHTS_STOPOVER]: (stopoverEnabled && search.flyRome) || search.twoNightsStopover,
    },
  };
};

const withReduxForm = reduxForm({
  form: SEARCH,
  destroyOnUnmount: false,
  enableReinitialize: true,
});

const connectToStore = connect(mapStateToProps, null);

export default compose(connectToStore, withReduxForm)(SearchForm);
