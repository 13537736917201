import { useEffect, useRef } from 'react';
import { breakpoints, Button, Dialog, Inline, Stack } from '@etg/wings';
import { Pictogram } from '@eti/components';
import { useSiteContext, useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { analytics } from '@eti/utils';

const buttonStyles = css`
  @media (min-width: ${breakpoints._970}) {
    min-width: 200px;
  }
`;

interface ErrorDialogProps {
  content: string;
  'data-testid'?: string;
  dataLayerEvent?: {
    event: string;
  };
  isOpen: boolean;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  pictogramId?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  title: string;
}

const ErrorDialog = ({
  content,
  dataLayerEvent,
  'data-testid': dataTestId,
  isOpen,
  onPrimaryAction,
  onSecondaryAction,
  pictogramId = 'generic-error',
  primaryButtonLabel,
  secondaryButtonLabel,
  title,
}: ErrorDialogProps) => {
  const { brand } = useSiteContext();
  const { t } = useTranslation();
  const divFocusRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen && dataLayerEvent) {
      analytics.pushToDataLayer(dataLayerEvent);
    }
  }, [isOpen, dataLayerEvent]);

  return (
    <Dialog
      closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
      data-testid={dataTestId}
      initialFocusRef={!onPrimaryAction ? divFocusRef : undefined}
      isOpen={isOpen}
      title={title}
    >
      <Stack>
        <Inline align="center">
          <Pictogram brand={brand.code} id={pictogramId} />
        </Inline>
        <div ref={divFocusRef}>{content}</div>
        <Inline align="end" collapseBelow={breakpoints._560}>
          {onPrimaryAction && (
            <Button
              className={buttonStyles}
              data-testid="error-modal-primary-button"
              onClick={onPrimaryAction}
              variant="primary"
            >
              {primaryButtonLabel}
            </Button>
          )}
          {onSecondaryAction && (
            <Button
              className={buttonStyles}
              data-testid="error-modal-secondary-button"
              onClick={onSecondaryAction}
            >
              {secondaryButtonLabel}
            </Button>
          )}
        </Inline>
      </Stack>
    </Dialog>
  );
};

export default ErrorDialog;
