import type { ElementType } from 'react';
import { useEffect, useState } from 'react';
import { useAuth } from '@eti/authentication';
import Loading from '../../loading/components/Loading';
import ErrorPage from '../../../pages/status-pages/components/ErrorPage';
import Route from './Route';

interface VerifyUserRouteProps {
  component: ElementType;
  dataLayerPathname?: string;
  exact?: boolean;
  path: string | string[];
  props: any;
  redirectTo?: string;
}

const VerifyUserRoute = ({
  component: Component,
  dataLayerPathname,
  exact,
  path,
  props,
  redirectTo,
}: VerifyUserRouteProps) => {
  const { verifyUser } = useAuth();
  const [isAuthenticationFinished, setIsAuthenticationFinished] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await verifyUser();
        setIsAuthenticationFinished(Boolean(data));
      } catch (e) {
        setIsAuthenticationFinished(false);
      }
    })();
  }, [verifyUser]);

  if (isAuthenticationFinished === null) {
    return <Loading />;
  }

  if (!isAuthenticationFinished) {
    return <ErrorPage />;
  }

  return (
    <Route
      component={Component}
      dataLayerPathname={dataLayerPathname}
      exact={exact}
      path={path}
      props={props}
      redirectTo={redirectTo}
    />
  );
};

export default VerifyUserRoute;
