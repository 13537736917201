import { getPrice, getSelected, getGraphQlSelection, getDBFGraphQlSelection } from '../single-choice/utils/selection';
import { MOBILE_TRAVEL_PLAN } from '../products';
import MobileTravelPlan from './components/MobileTravelPlan';
import getIcon from './utils/icon';
const formSection = MOBILE_TRAVEL_PLAN.name;
export const mobileTravelPlan = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getDBFGraphQlSelection: getDBFGraphQlSelection(formSection, 'none'),
  ...MOBILE_TRAVEL_PLAN
};
export default MobileTravelPlan;