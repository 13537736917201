import { breakpoints, Input, Stack } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import type { BestPricePerDay } from '@eti/schema-types';
import { TripType } from '@eti/schema-types';
import type { RefObject } from 'react';
import { useRef } from 'react';
import { css, cx } from '@eti/styles';
import Calendar from '../calendar/Calendar';
import { useOnClickOutside } from '../../../../common/custom-hooks/useOnClickOutside';
import { DEPARTURE_DATE, RETURN_DATE, SINGLE_BOUND } from '../../constants/formFieldNames';
import ConnectedDateInput from './ConnectedDateInput';
import { elevateFocusedInputStyles, inputStyles } from './styles';

const calendarWrapperStyles = (isMultiStop: boolean) => css`
  margin-top: -1px;
  ${isMultiStop && 'inset-inline-end: 0'};
  min-width: fit-content;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;

  @media (min-width: ${breakpoints._768}) and (max-width: ${breakpoints._970}) {
    min-width: 320px;
  }
`;

interface DesktopDatesProps {
  currentField?: typeof DEPARTURE_DATE | typeof RETURN_DATE | null;
  dateFormat?: string;
  daysFromTodayForValidDepartureDate?: number;
  departureDateName: string;
  departureInputRef: RefObject<HTMLInputElement>;
  handleInputFocus: (field: typeof DEPARTURE_DATE | typeof RETURN_DATE) => void;
  hasReturnDate: boolean;
  hideCalendar: () => void;
  isCalendarVisible: boolean;
  loading?: boolean;
  onDepartureDateChange: (day: string) => void;
  onReturnDateChange: (day: string) => void;
  previousBoundDepartureDate?: Date;
  priceData?: BestPricePerDay[];
  range: { from?: Date; to?: Date };
  returnInputRef: RefObject<HTMLInputElement>;
  selectedTripType?: TripType;
}

const DatesWithCalendarDropDown = ({
  currentField,
  dateFormat,
  daysFromTodayForValidDepartureDate,
  departureDateName,
  departureInputRef,
  handleInputFocus,
  hasReturnDate,
  hideCalendar,
  isCalendarVisible,
  loading,
  onDepartureDateChange,
  onReturnDateChange,
  previousBoundDepartureDate,
  priceData,
  range,
  returnInputRef,
  selectedTripType,
}: DesktopDatesProps) => {
  const { t } = useTranslation();
  const isMultiStop = selectedTripType === TripType.MultiStop;
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const datesWrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    if (isCalendarVisible) {
      hideCalendar();
    }
  };

  useOnClickOutside(datesWrapperRef, handleClickOutside);

  return (
    <div ref={datesWrapperRef}>
      <Stack spacing={4}>
        <Input.Group>
          <Stack spacing={4} style={{ width: '100%' }}>
            <Input.Label htmlFor={departureDateName}>
              {t('Searchform.DepartureDate.Label')}
            </Input.Label>
            <ConnectedDateInput
              className={cx(inputStyles, {
                [elevateFocusedInputStyles]: currentField === DEPARTURE_DATE,
              })}
              dateFormat={dateFormat}
              inputRef={departureInputRef}
              name={departureDateName}
              onFocus={handleInputFocus(DEPARTURE_DATE)}
              placeholder={t('General.Departure')}
              validationRuleName={DEPARTURE_DATE}
            />
          </Stack>

          {hasReturnDate && (
            <Stack spacing={4} style={{ width: '100%' }}>
              <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                {t('Searchform.ReturnDate.Label')}
              </Input.Label>
              <ConnectedDateInput
                className={cx(inputStyles, {
                  [elevateFocusedInputStyles]: currentField === RETURN_DATE,
                })}
                dateFormat={dateFormat}
                inputRef={returnInputRef}
                name={`${SINGLE_BOUND}.${RETURN_DATE}`}
                onFocus={handleInputFocus(RETURN_DATE)}
                placeholder={t('General.Return')}
                validationRuleName={RETURN_DATE}
              />
            </Stack>
          )}
        </Input.Group>
      </Stack>

      {isCalendarVisible && (
        <div className={calendarWrapperStyles(isMultiStop)} ref={calendarWrapperRef}>
          <Calendar
            bestPricesPerDay={priceData}
            currentField={currentField}
            daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
            loading={loading}
            onDepartureDateChange={onDepartureDateChange}
            onReturnDateChange={onReturnDateChange}
            previousBoundDepartureDate={previousBoundDepartureDate}
            range={range}
            selectedTripType={selectedTripType}
          />
        </div>
      )}
    </div>
  );
};

export default DatesWithCalendarDropDown;
