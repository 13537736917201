import { useLazyQuery } from '@apollo/client';
import { breakpoints } from '@etg/wings';
import { useProperty } from '@eti/providers';
import type { BestPricePerDay } from '@eti/schema-types';
import { TripType } from '@eti/schema-types';
import { css } from '@eti/styles';
import { addDays, format, formatISO, startOfMonth } from 'date-fns';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Media from 'react-media';
import scrollToElementBottom from '../../../../utils/scrollToElementBottom';
import { DEPARTURE_DATE, RETURN_DATE } from '../../constants/formFieldNames';
import { GET_PRICES_PER_DAY } from '../../queries';
import { isDepartureDateValid, isReturnDateValid } from '../../utils/calendarDateUtils';
import DatesWithCalendarDropDown from './DatesWithCalendarDropDown';
import DatesWithCalendarDialog from './DatesWithCalendarDialog';

const datesWrapperStyles = css`
  flex: 1;
  position: relative;
`;

export interface DatesProps {
  dateFormat?: string;
  daysFromTodayForValidDepartureDate?: number;
  departureDate?: Date | string;
  departureDateName: string;
  previousBoundDepartureDate?: Date | string;
  returnDate?: Date | string;
  selectedTripType?: TripType;
  setDepartureDate?: (value: string) => void;
  setNextBoundsDates?: (...values: any) => void;
  setReturnDate?: (value: string) => void;
  origin?: string;
  destination?: string;
}

const today = startOfMonth(new Date());
const formatDate = (date: Date) => formatISO(date, { representation: 'date' });
const NUMBER_OF_DAYS = 57;

const Dates = ({
  departureDate,
  departureDateName,
  dateFormat,
  daysFromTodayForValidDepartureDate,
  returnDate,
  selectedTripType,
  setDepartureDate,
  setNextBoundsDates,
  setReturnDate,
  previousBoundDepartureDate,
  origin,
  destination,
}: DatesProps) => {
  const [currentField, setCurrentField] = useState<
    typeof DEPARTURE_DATE | typeof RETURN_DATE | null
  >(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { p } = useProperty();

  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const departureInputRef = useRef<HTMLInputElement>(null);
  const returnInputRef = useRef<HTMLInputElement>(null);
  const mobileCalendarDialogRef = useRef<HTMLDivElement>(null);
  const isPriceCalendarEnabled = p('IbeClient.Price.Calendar.Enabled');
  const isPriceCalendarMobileEnabled = p('IbeClient.Price.Calendar.Mobile.Enabled');
  const hasReturnDate = selectedTripType
    ? [
        TripType.Return,
        TripType.OpenJawDouble,
        TripType.OpenJawSingleDestination,
        TripType.OpenJawSingleOrigin,
      ].includes(selectedTripType)
    : false;

  useLayoutEffect(() => {
    if (calendarWrapperRef.current && isCalendarVisible) {
      const elementRectData = calendarWrapperRef.current.getBoundingClientRect();
      const elementBottomPosition = elementRectData.y + elementRectData.height;
      const shouldScrollElementIntoView = elementBottomPosition >= window.innerHeight;
      if (shouldScrollElementIntoView) {
        scrollToElementBottom(elementBottomPosition);
      }
    }
  }, [isCalendarVisible]);

  useEffect(() => {
    if (currentField === DEPARTURE_DATE && departureInputRef.current) {
      departureInputRef.current.focus();
    }
    if (currentField === RETURN_DATE && returnInputRef.current) {
      returnInputRef.current.focus();
    }
  }, [currentField]);

  const hideCalendar = () => {
    setCurrentField(null);
    setIsCalendarVisible(false);
  };

  useEffect(() => {
    if (hasReturnDate && isDepartureDateValid(departureDate, returnDate) && !isMobile) {
      hideCalendar();
    }
  }, [departureDate, selectedTripType, returnDate, hasReturnDate, isMobile]);

  const [getPriceData, { data, loading }] = useLazyQuery(GET_PRICES_PER_DAY, {
    fetchPolicy: 'no-cache',
  });

  const priceData: BestPricePerDay[] = useMemo(
    () => [
      ...(data?.from0To57Days?.bestPricesPerDay ?? []),
      ...(data?.from57To114Days?.bestPricesPerDay ?? []),
      ...(data?.from114To171Days?.bestPricesPerDay ?? []),
      ...(data?.from171To228Days?.bestPricesPerDay ?? []),
      ...(data?.from228To285Days?.bestPricesPerDay ?? []),
      ...(data?.from285To342Days?.bestPricesPerDay ?? []),
      ...(data?.from342To399Days?.bestPricesPerDay ?? []),
    ],
    [data],
  );

  useEffect(() => {
    if (!isPriceCalendarEnabled || !origin || !destination) {
      return;
    }

    getPriceData({
      variables: {
        origin,
        destination,
        numberOfDays: NUMBER_OF_DAYS,
        startDate1: formatDate(today),
        startDate2: formatDate(addDays(today, NUMBER_OF_DAYS)),
        startDate3: formatDate(addDays(today, NUMBER_OF_DAYS * 2)),
        startDate4: formatDate(addDays(today, NUMBER_OF_DAYS * 3)),
        startDate5: formatDate(addDays(today, NUMBER_OF_DAYS * 4)),
        startDate6: formatDate(addDays(today, NUMBER_OF_DAYS * 5)),
        startDate7: formatDate(addDays(today, NUMBER_OF_DAYS * 6)),
      },
    });
  }, [destination, getPriceData, isPriceCalendarEnabled, origin]);

  const handleInputFocus = (field: typeof DEPARTURE_DATE | typeof RETURN_DATE) => () => {
    setCurrentField(field);
    setIsCalendarVisible(true);

    if (isMobile && departureDate) {
      setTimeout(() => {
        const captionElem = document.getElementById(
          `daypicker-custom-caption-${format(new Date(departureDate), 'yyyy-MM')}`,
        );
        if (captionElem && captionElem.offsetTop > 100) {
          mobileCalendarDialogRef.current?.parentElement?.scrollTo({
            top: Math.max(captionElem.offsetTop - 72, 0),
          });
        }
      }, 1);
    }
  };

  const handleDepartureDateChange = (day: string) => {
    if (setDepartureDate) {
      setDepartureDate(day);
    }

    if (selectedTripType === TripType.MultiStop && setNextBoundsDates) {
      setNextBoundsDates(day);
    }

    if (hasReturnDate && setReturnDate) {
      setReturnDate('');
      setCurrentField(RETURN_DATE);
    }

    if (!hasReturnDate && !isMobile) {
      hideCalendar();
    }
  };

  const handleReturnDateChange = (day: string) => {
    if (hasReturnDate && setReturnDate) {
      setReturnDate(day);
      setCurrentField(DEPARTURE_DATE);

      if (isReturnDateValid(day, departureDate)) {
        if (!isMobile) {
          hideCalendar();
        }
      } else if (setDepartureDate) {
        setReturnDate('');
        setDepartureDate(day);
        setCurrentField(RETURN_DATE);
      }
    }
  };

  const dateRange = {
    from: departureDate instanceof Date ? departureDate : undefined,
    to: returnDate instanceof Date ? returnDate : undefined,
  };

  const previousBoundDeparture =
    previousBoundDepartureDate instanceof Date ? previousBoundDepartureDate : undefined;

  return (
    <div className={datesWrapperStyles}>
      <Media
        onChange={(matches) => isPriceCalendarMobileEnabled && setIsMobile(!matches)}
        query={`(min-width: ${breakpoints._768})`}
      >
        {(matches) =>
          matches || !isPriceCalendarMobileEnabled ? (
            <DatesWithCalendarDropDown
              currentField={currentField}
              dateFormat={dateFormat}
              daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
              departureDateName={departureDateName}
              departureInputRef={departureInputRef}
              handleInputFocus={handleInputFocus}
              hasReturnDate={hasReturnDate}
              hideCalendar={hideCalendar}
              isCalendarVisible={isCalendarVisible}
              loading={loading}
              onDepartureDateChange={handleDepartureDateChange}
              onReturnDateChange={handleReturnDateChange}
              previousBoundDepartureDate={previousBoundDeparture}
              priceData={priceData}
              range={dateRange}
              returnInputRef={returnInputRef}
              selectedTripType={selectedTripType}
            />
          ) : (
            <DatesWithCalendarDialog
              currentField={currentField}
              dateFormat={dateFormat}
              daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
              departureDateName={departureDateName}
              departureInputRef={departureInputRef}
              handleInputFocus={handleInputFocus}
              hasReturnDate={hasReturnDate}
              hideCalendar={hideCalendar}
              isCalendarVisible={isCalendarVisible}
              loading={loading}
              mobileCalendarDialogRef={mobileCalendarDialogRef}
              onDepartureDateChange={handleDepartureDateChange}
              onReturnDateChange={handleReturnDateChange}
              previousBoundDepartureDate={previousBoundDeparture}
              priceData={priceData}
              range={dateRange}
              returnInputRef={returnInputRef}
              selectedTripType={selectedTripType}
            />
          )
        }
      </Media>
    </div>
  );
};

export default Dates;
