import { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue, touch } from 'redux-form';
import isFunction from 'lodash/isFunction';
import { breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { tripTypes } from '../../../constants/tripTypesConstants';
import { validationIcons } from '../utils/validation';
import { ORIGIN, DESTINATION } from '../constants/formFieldNames';
import Location from './Location';
import InputLabel from './InputLabel';
import SwitchDirectionButton from './SwitchDirectionButton';

const inputWrapperStyles = css`
  width: 100%;

  @media (min-width: ${breakpoints._768}) {
    flex: 1;
  }
`;

const inputLabelStyles = css`
  &:first-child {
    margin-top: 0;
  }
`;

const switchDirectionButtonStyles = css`
  height: 48px;
  width: 48px;
`;

const OriginAndDestination = ({
  boundIndex,
  destination,
  destinationName,
  exchangeLocations,
  origin,
  originName,
  selectedTripType,
  shouldOpenDatePassengerFields,
}) => {
  const { t } = useTranslation();
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const onSwitchDirection = () => {
    exchangeLocations(origin, destination);
  };

  const handleDestinationOnBlur = () => {
    if (destination !== null && origin !== null && isFunction(shouldOpenDatePassengerFields)) {
      shouldOpenDatePassengerFields(true);
    }
  };

  const getButtonDataTestId = () =>
    boundIndex >= 0
      ? `searchForm-multiBound[${boundIndex}]-exchangeLocations-button`
      : 'searchForm-singleBound-exchangeLocations-button';

  const getLocationDataTestId = (locationType) =>
    boundIndex >= 0
      ? `searchForm-multiBound[${boundIndex}]-${locationType}-input`
      : `searchForm-singleBound-${locationType}-input`;

  const originDataTestId = getLocationDataTestId('origin');
  const destinationDataTestId = getLocationDataTestId('destination');

  return (
    <>
      <div className={inputWrapperStyles} data-testid={originDataTestId}>
        <InputLabel className={inputLabelStyles} isFullWidth label={t('Searchform.Origin.Label')}>
          <Location
            id={originDataTestId}
            innerRef={originRef}
            isRequired
            name={originName}
            onSwitchDirection={onSwitchDirection}
            placeholder={t('Air.Search.CitiesSelection.Placeholder.From')}
            validationRuleName={ORIGIN}
            {...validationIcons}
          />
        </InputLabel>
      </div>
      {selectedTripType !== tripTypes.MULTI_STOP && (
        <SwitchDirectionButton
          aria-label={t('Air.Search.SwitchLocations.Label')}
          className={switchDirectionButtonStyles}
          data-testid={getButtonDataTestId()}
          onClick={onSwitchDirection}
          title={t('Air.Search.SwitchLocations.Label')}
          type="button"
        />
      )}
      <div className={inputWrapperStyles} data-testid={destinationDataTestId}>
        <InputLabel isFullWidth label={t('Searchform.Destination.Label')}>
          <Location
            id={destinationDataTestId}
            innerRef={destinationRef}
            isRequired
            name={destinationName}
            onBlur={handleDestinationOnBlur}
            placeholder={t('Air.Search.CitiesSelection.Placeholder.To')}
            validationRuleName={DESTINATION}
            {...validationIcons}
          />
        </InputLabel>
      </div>
    </>
  );
};

OriginAndDestination.propTypes = {
  boundIndex: PropTypes.number,
  destination: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  destinationName: PropTypes.string,
  exchangeLocations: PropTypes.func,
  onLoadSearchResults: PropTypes.func,
  origin: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  originName: PropTypes.string,
  selectedTripType: PropTypes.oneOf([...Object.values(tripTypes)]),
  shouldOpenDatePassengerFields: PropTypes.func,
};

const mapStateToProps = (state, { destinationName, form, originName }) => ({
  origin: formValueSelector(form)(state, originName),
  destination: formValueSelector(form)(state, destinationName),
});

const mapDispatchToProps = (dispatch, { destinationName, form, originName }) => ({
  exchangeLocations: (oldOriginValue = '', oldDestinationValue = '') => {
    dispatch(changeFormValue(form, originName, oldDestinationValue));
    dispatch(touch(form, originName));
    dispatch(changeFormValue(form, destinationName, oldOriginValue));
    dispatch(touch(form, destinationName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OriginAndDestination);
