import type { SearchData } from '@eti/providers';
import { useSearch, useTranslation } from '@eti/providers';
import type { GetTripReminderInfoQuery } from '@eti/schema-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { routes } from '../../../constants/routesAndApisConstants';
import {
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  getTotalPrice,
} from '../../../utils/trip';
import { generateTotalTravelersDesktopText } from '../../trip-details/utils/format';
import TripReminder from '../components/TripReminder';
import { TRIP_REMINDER_QUERY } from './queries';

const getTripReminderData = (
  queryData: GetTripReminderInfoQuery,
  searchParams: SearchData | undefined,
  t: (arg: string) => string,
) => {
  if (queryData?.selectedTrip || queryData?.cart) {
    let trip;
    let purchaseFlowStep;
    let totalPrice;

    if (queryData?.cart) {
      [trip] = queryData.cart.trips;
      totalPrice = queryData.cart.price?.price.value;
      purchaseFlowStep = routes.PAYMENT;
    } else {
      trip = queryData.selectedTrip;
      totalPrice = getTotalPrice(trip);
      purchaseFlowStep = routes.TRAVELER_DETAILS;
    }

    const { type, travelers } = trip;
    const origin = getOrigin(trip);
    const destination = getDestination(trip);
    const departureDate = getDepartureDate(trip);
    const returnDate = getReturnDate(trip);
    const summary = generateTotalTravelersDesktopText(t, travelers);

    return {
      purchaseFlowStep,
      tripData: {
        date: {
          departure: departureDate,
          return: returnDate,
        },
        destination: Array.isArray(destination)
          ? destination[destination.length - 1].cityName
          : destination.cityName,
        origin: Array.isArray(origin) ? origin[0].cityName : origin.cityName,
        totalPrice,
        travelers: summary,
        type,
      },
    };
  }

  if (!isEmpty(searchParams)) {
    // TODO: Add back the `/result` case when we can initiate new searches properly (see WEB-6829).
    return null;
  }

  return null;
};

interface TripReminderGraphqlContainerProps {
  className?: string;
  onDiscardReminder: () => void;
}

const TripReminderGraphqlContainer = ({
  className,
  onDiscardReminder,
}: TripReminderGraphqlContainerProps) => {
  const { t } = useTranslation();
  const { currentSearchData } = useSearch();
  const { data } = useQuery(TRIP_REMINDER_QUERY, { fetchPolicy: 'no-cache' });
  const tripReminderData = data && getTripReminderData(data, currentSearchData, t);

  if (tripReminderData) {
    return (
      <TripReminder
        className={className}
        onDiscardReminder={onDiscardReminder}
        purchaseFlowStep={tripReminderData.purchaseFlowStep}
        tripData={tripReminderData.tripData}
      />
    );
  }

  return null;
};

export default TripReminderGraphqlContainer;
