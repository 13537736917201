import { CABIN_BAGGAGE } from '../../products';
import { getMinPrice, getMarkup } from '../common/utils/priceUtil';
import { getSelected, getSelectedForTraveler } from '../common/utils/units';
import { getCabinBaggagePrice } from './utils/price';
import { getNumberOfUnitsCabinBaggage } from './utils/units';
import getIcon from './utils/icon';
import { getDBFGraphQlSelection, getGraphQlSelection } from './utils/getGraphQlSelection';
import CabinBaggage from './containers/CabinBaggageContainer';
const {
  id,
  name: formSection
} = CABIN_BAGGAGE;
export const cabinBaggage = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(String(id), formSection),
  getDBFGraphQlSelection: getDBFGraphQlSelection(String(id), formSection),
  getIcon,
  getMarkup: getMarkup.bind(null, formSection),
  getMinPrice,
  getNumberOfUnits: getNumberOfUnitsCabinBaggage.bind(null, formSection),
  getPrice: getCabinBaggagePrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getSelectedForTraveler: getSelectedForTraveler.bind(null, formSection),
  ...CABIN_BAGGAGE
};
export default CabinBaggage;