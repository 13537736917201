import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const CookiesPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="content/cookies"
      pageTitle={t('PageTitle.rf.cookies.action')}
      textKey="Cookies.Text"
    />
  );
};

export default CookiesPage;
