import { X } from '@phosphor-icons/react';
import { css } from '@eti/styles';
import type { ComponentPropsWithoutRef, ReactNode, Ref } from 'react';

const clearButtonStyles = css`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 8px;
`;

interface ClearIndicatorProps {
  children?: ReactNode;
  innerProps: Exclude<ComponentPropsWithoutRef<'button'>, 'className'>;
  innerRef: Ref<HTMLButtonElement>;
}

const ClearIndicator = ({ children, innerProps, innerRef }: ClearIndicatorProps) => {
  return (
    <button
      {...innerProps}
      className={clearButtonStyles}
      data-testid="clear-cross"
      ref={innerRef}
      tabIndex={-1}
      type="button"
    >
      {children || <X size="20" weight="light" />}
    </button>
  );
};

export default ClearIndicator;
