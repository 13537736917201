import { useEffect, useRef } from 'react';

const useCookieBannerFocus = (id: string) => {
  const observerRef = useRef<MutationObserver | null>(null);
  const focusIntervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const focusTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const focusOnBanner = (banner: HTMLElement) => {
    if (focusTimeoutRef.current) {
      clearTimeout(focusTimeoutRef.current);
    }

    focusIntervalRef.current = setTimeout(() => {
      banner.focus();

      focusIntervalRef.current = setInterval(() => {
        if (document.activeElement === document.body) {
          banner.focus();
        }
      }, 100);

      setTimeout(() => {
        if (focusIntervalRef.current) {
          clearInterval(focusIntervalRef.current);
        }
        if (focusTimeoutRef.current) {
          clearTimeout(focusTimeoutRef.current);
        }
      }, 3000);
    }, 100);
  };

  useEffect(() => {
    observerRef.current = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          const banner = document.getElementById(id);

          if (banner) {
            focusOnBanner(banner);
            observerRef.current?.disconnect();
            break;
          }
        }
      }
    });

    observerRef.current.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [id]);
};

export default useCookieBannerFocus;
