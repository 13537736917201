import { change as changeFormValue, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { DeckTypeInput } from '@eti/schema-types';
import SeatMapContent from '../components/SeatMapContent';
import { getPrice, getSelected, getUpdatedSeatMap, processSeatSelection } from '../../utils/selection';
import { SEATING_PREFERENCE_VALUES } from '../../utils/constants';
const getDeckType = activeDeck => {
  switch (activeDeck) {
    case 'lowerDeck':
      return DeckTypeInput.LowerDeck;
    case 'mainDeck':
      return DeckTypeInput.MainDeck;
    case 'upperDeck':
      return DeckTypeInput.UpperDeck;
    default:
      return DeckTypeInput.MainDeck;
  }
};
const mapStateToProps = (state, _ref) => {
  let {
    bounds,
    form
  } = _ref;
  const getSelectedFunction = getSelected('seatMap', form)(state);
  const {
    price,
    markup
  } = getPrice('seatMap', form)(state);
  const selectedSeats = formValueSelector(form)(state, 'seatMap') || {};
  return {
    getSelected: activeSegment => Object.values(getSelectedFunction(activeSegment)),
    totalMarkup: markup,
    totalPrice: price,
    selectedSeats,
    bounds
  };
};
//
const mapDispatchToProps = (dispatch, _ref2) => {
  let {
    form,
    travelers
  } = _ref2;
  return {
    onChange: (boundSegmentIndex, travelerId, carrier, column, row, seat, price, markup, seatPreference, activeDeck) => {
      const traveler = travelers.find(t => t.id === travelerId);
      const getSeatPreference = selectedSeatingPreference => ({
        seatPreference: selectedSeatingPreference !== null && selectedSeatingPreference !== void 0 ? selectedSeatingPreference : SEATING_PREFERENCE_VALUES.NO_OPTION
      });
      const segment = {
        ...traveler,
        carrier,
        column,
        price,
        row,
        value: seat,
        markup,
        id: travelerId,
        deckType: getDeckType(activeDeck),
        ...getSeatPreference(seatPreference)
      };
      dispatch(changeFormValue(form, 'seatMap.value.value', 'true'));
      dispatch(changeFormValue(form, `seatMap.${boundSegmentIndex}.traveler${travelerId}`, segment));
    },
    onRemove: (boundSegmentIndex, travelerId, seatSelection) => {
      const seatMapData = getUpdatedSeatMap(boundSegmentIndex, travelerId, seatSelection);
      dispatch(changeFormValue(form, `seatMap`, seatMapData));
    },
    onSeatConfirmation: seatSelection => () => {
      const selectedSeats = processSeatSelection(seatSelection);
      if (selectedSeats.length === 0) {
        dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
      }
    },
    onOptOut() {
      dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
    }
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSeatConfirmation: dispatchProps.onSeatConfirmation(stateProps.selectedSeats)
});
export const SeatMapContentContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SeatMapContent);
export default SeatMapContentContainer;