import { useProperty, useSiteContext } from '@eti/providers';
import { TripType } from '@eti/schema-types';
import { getFormattedDate } from '../../../utils/search';
import type { SearchFormValues } from '../components/SeachFormNewContainer';

export interface SearchRedirectConfig {
  currency: string;
  language: string;
  url: string;
}

export const useSearchRedirectConfig = (): SearchRedirectConfig | null => {
  const { p } = useProperty();
  const { language, siteCurrency } = useSiteContext();

  const url = p('IbeClient.Search.Redirect.Resolver.Url');

  return url ? { currency: siteCurrency.code, language: language.code, url } : null;
};

const tripTypeMap = {
  [TripType.OneWay]: 'one-way',
  [TripType.Return]: 'round-trip',
  [TripType.MultiStop]: 'multi-city',
};

const getBounds = (formValues: SearchFormValues) => {
  if (formValues.tripType === TripType.MultiStop) {
    return formValues.multiBound;
  }
  if (formValues.tripType === TripType.Return) {
    return [
      {
        departureDate: formValues.singleBound.departureDate,
        destination: formValues.singleBound.destination,
        origin: formValues.singleBound.origin,
      },
      {
        departureDate: formValues.singleBound.returnDate,
        destination: formValues.singleBound.origin,
        origin: formValues.singleBound.destination,
      },
    ];
  }
  return [formValues.singleBound];
};

export const resolveSearchUrl = async (
  config: SearchRedirectConfig,
  formValues: SearchFormValues,
) => {
  let data;
  try {
    const body = {
      isRequestFromEtg: true,
      adults: formValues.passengers.adults,
      bounds: getBounds(formValues).map((bound) => ({
        date: getFormattedDate(bound.departureDate),
        destination: bound.destination?.iataCode,
        origin: bound.origin?.iataCode,
      })),
      childAges: formValues.passengers.childrenAges.slice(0, formValues.passengers.children),
      currency: config.currency,
      infantAges: formValues.passengers.infantsAges?.slice(0, formValues.passengers.infants),
      language: config.language,
      lowestFare: false,
      promoCode: '',
      tripType: tripTypeMap[formValues.tripType],
    };

    data = await fetch(config.url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      method: 'POST',
    }).then((response) => response.json());
  } catch (error) {
    return null;
  }

  return data?.success ? data.url : null;
};
