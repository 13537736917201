import { insertArgument } from '../string/string';
import { getPriceFormatter } from './provider';
const formattedPrice = price => getPriceFormatter().formatPrice(price);
export const formatPrice = function (price, customFormatter) {
  if (customFormatter === void 0) {
    customFormatter = null;
  }
  const formatter = customFormatter || getPriceFormatter();
  return formatter.formatPrice(price);
};
export const textWithPrice = (text, price) => insertArgument(text, formattedPrice(price));
export const generatePrice = (totalPrice, totalPriceText, minPrice, minPriceText) => {
  if (totalPrice > 0) {
    return {
      price: textWithPrice(totalPriceText, totalPrice)
    };
  }
  if (minPrice !== undefined) {
    return {
      placeholder: textWithPrice(minPriceText, minPrice)
    };
  }
  return {};
};
export const getPriceDivided = (price, divider) => {
  if (divider > 0 && Number.isInteger(divider)) {
    return divider > 1 ? Math.ceil(price / divider) : price;
  }
  return null;
};