import { useCallback, useEffect, useRef, useState } from 'react';
import { breakpoints, Inline, SiteAuxiliaryButton, vars } from '@etg/wings';
import { useAuth } from '@eti/authentication';
import { useDirection, useProperty, useSiteContext, useTranslation } from '@eti/providers';
import { AuthenticationType } from '@eti/schema-types';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { useApolloClient } from '@apollo/client';
import { CaretDown, SignOut, User } from '@phosphor-icons/react';
import { observeClickOutside } from 'react-ref-observe';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { routes } from '../../../constants/routesAndApisConstants';
import { useCloseOnEsc } from '../../../utils/useCloseOnEsc';
import { useCloseOnFocusOut } from '../../../utils/useCloseOnFocusOut';
import { asyncInit } from '../../../utils/facebook';
import { generateID } from '../../../utils/idGenerator';
import { closeWebMessagesChat } from '../../chat/webMessages/utils';
import { CONNECTED, SUCCESS } from '../constants';
import MyBookingsModal from '../containers/MyBookingsModal';

const wrapperStyles = css`
  position: relative;

  @media print {
    display: none;
  }
`;

const getDropdownStyles = (hiddenLabel?: boolean) => css`
  background-color: #fff;
  border: 1px solid ${vars.colors.divider};
  border-radius: 3px;
  margin-top: 3px;
  min-width: 170px;
  ${hiddenLabel ? `inset-inline-end: 0` : `inset-inline-start: 0`};
  position: absolute;
  width: auto;
  z-index: 2;

  @media (min-width: ${breakpoints._970}) {
    width: 100%;
  }
`;

const dropdownItemStyles = css`
  color: ${vars.colors.text};
  cursor: pointer;
  font-size: 0.875rem;
  padding: 16px;
  text-decoration: none;
  width: 100%;

  &:focus-visible {
    outline: 2px solid ${vars.colors.focusRing};
  }
`;

const dividerStyles = css`
  border-bottom: 1px solid ${vars.colors.divider};
`;

const getUserIconStyles = (hiddenLabel: boolean) => css`
  position: relative;

  &::before {
    background-color: ${vars.colors.signal.positive.light};
    border: 2px solid ${vars.colors.button.siteAuxiliary.default.base};
    border-radius: 50%;
    content: '';
    display: block;
    height: 0.625rem;
    inset-inline-start: calc(
      ${hiddenLabel ? vars.button.blockPadding.smallWithIcon : '1.5rem'} + 0.625rem
    );
    margin-bottom: 0.75rem;
    position: absolute;
    width: 0.625rem;
  }

  &:hover::before {
    border-color: ${vars.colors.button.siteAuxiliary.hover.base};
  }

  &:active::before {
    border-color: ${vars.colors.button.siteAuxiliary.active.base};
  }
`;

const getCaretStyles = (isOpen: boolean) => css`
  flex-shrink: 0;
  transform: rotateX(${isOpen ? '180' : '0'}deg);
  transition: transform 0.15s ease-in-out;
`;

const routesToRedirectToOrderLogin = [
  routes.ORDER,
  routes.ORDER_STATUS,
  routes.ORDER_LIST,
  routes.ORDER_DETAILS,
  routes.CHANGE_TRIP,
];

interface MyBookingsButtonProps {
  className?: string;
  'data-testid'?: string;
  shouldHideLabel?: boolean | null;
}

const MyBookingsButton = ({
  className,
  'data-testid': dataTestId = 'myBookingsButton-header',
  shouldHideLabel = null,
}: MyBookingsButtonProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const history = useHistory();
  const { isRTL } = useDirection();
  const { pathname } = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLAnchorElement | null>(null);
  const {
    language: { code: languageCode },
    market: { code: marketCode },
  } = useSiteContext();

  const { user, logoutUser } = useAuth();
  const client = useApolloClient();
  const isWiderViewport = useMediaQuery(`(min-width: ${breakpoints._970})`);

  const isMyBookingsButtonEnabled = !p('IbeClient.HideMyBookingsButton.Enabled');
  const isMyBookingsModalEnabled = p('IbeClient.MyBookingsModal.Enabled');
  const facebookClientID = p('IbeClient.MyBookings.Facebook.Client.Id');

  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isDropdownOpen]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  observeClickOutside([wrapperRef], closeDropdown);
  useCloseOnEsc(dropdownRef, closeDropdown, buttonRef);
  useCloseOnFocusOut(wrapperRef, closeDropdown);

  const isPostbookingProductsPage = pathname.includes(routes.POSTBOOKING_PRODUCTS);
  const isAuthenticationPage =
    pathname === routes.ORDER_AUTHENTICATION ||
    pathname === routes.POSTBOOKING_AUTHENTICATION ||
    pathname === routes.RYANAIR_ORDER_AUTHENTICATION;

  const redirectToStartPage = () => {
    history.push(routes.START);
  };

  const redirectToLoginPage = () => {
    history.push(routes.ORDER_AUTHENTICATION);
  };

  const cleanupAndRedirect = async () => {
    if (document.getElementById('webMessagesScript')) {
      closeWebMessagesChat();
    }
    if (routesToRedirectToOrderLogin.some((route) => pathname.includes(route))) {
      if (isMyBookingsModalEnabled) {
        redirectToStartPage();
      } else {
        redirectToLoginPage();
      }
    }

    if (isPostbookingProductsPage) {
      history.push(routes.POSTBOOKING_AUTHENTICATION);
    }
    await client.clearStore();
  };

  const handleClick = () => {
    if (!user) {
      if (isMyBookingsModalEnabled && !isAuthenticationPage) {
        setIsLoginModalOpen(true);
      } else {
        redirectToLoginPage();
      }
    } else if (isDropdownOpen) {
      closeDropdown();
    } else {
      setIsDropdownOpen(true);
    }
  };

  const facebookLogout = () => {
    asyncInit(facebookClientID, languageCode, marketCode, () => {
      window.FB.getLoginStatus((response) => {
        if (response.status === CONNECTED) {
          window.FB.logout(() => {
            cleanupAndRedirect();
          });
        } else {
          cleanupAndRedirect();
        }
      });
    });
  };

  const handleLogout = async () => {
    closeDropdown();

    try {
      const { data: logoutData } = await logoutUser();

      if (logoutData?.logoutUser?.response === SUCCESS) {
        if (user?.loginType === AuthenticationType.Facebook) {
          facebookLogout();
        } else {
          cleanupAndRedirect();
        }
      }
    } catch (error) {
      console.error('Error during logout:', error); // eslint-disable-line no-console
      throw error;
    }
  };

  const handleLoginModalDismiss = useCallback(() => {
    setIsLoginModalOpen(false);
  }, []);

  const elementType = user || (isMyBookingsModalEnabled && !isAuthenticationPage) ? 'button' : Link;
  const isLabelHidden = shouldHideLabel !== null ? shouldHideLabel : !isWiderViewport;
  const menuId = `myBookingsMenu-${generateID()}`;

  return (
    <div className={wrapperStyles} data-testid="my-bookings-button-header" ref={wrapperRef}>
      {isMyBookingsButtonEnabled && (
        <SiteAuxiliaryButton
          aria-controls={user ? menuId : undefined}
          aria-expanded={user ? isDropdownOpen : undefined}
          aria-label={!user ? t('Postbooking.Login.Header.Title') : undefined}
          as={elementType}
          className={cx(className, user && getUserIconStyles(isLabelHidden))}
          data-testid={dataTestId}
          hideLabel={isLabelHidden}
          iconPrefix={<User aria-hidden />}
          iconSuffix={
            user ? <CaretDown aria-hidden className={getCaretStyles(isDropdownOpen)} /> : undefined
          }
          onClick={handleClick}
          ref={buttonRef}
          size="large"
          {...(elementType === Link ? { to: routes.ORDER_AUTHENTICATION } : {})}
        >
          {t('SiteHeader.My.Bookings.Button')}
        </SiteAuxiliaryButton>
      )}
      {isDropdownOpen && (
        <ul className={getDropdownStyles(isLabelHidden)} id={user ? menuId : undefined}>
          <li>
            <Inline
              as={Link}
              className={dropdownItemStyles}
              data-testid="myBookings-see-order"
              onClick={closeDropdown}
              ref={dropdownRef}
              to={routes.ORDER_LIST}
            >
              {t('Postbooking.Show.Bookings.Button')}
            </Inline>
          </li>
          <div className={dividerStyles} />
          <li>
            <Inline
              as="button"
              className={cx(resetButtonStyle, dropdownItemStyles)}
              data-testid="myBookings-logout"
              onClick={handleLogout}
              spacing={8}
            >
              <SignOut aria-hidden mirrored={!isRTL} size={18} weight="regular" />
              {t('Postbooking.Logout.Button')}
            </Inline>
          </li>
        </ul>
      )}
      <MyBookingsModal isLoginModalOpen={isLoginModalOpen} onDismiss={handleLoginModalDismiss} />
    </div>
  );
};

export default MyBookingsButton;
