/* eslint-disable react/prop-types */
import { useState } from 'react';
import { breakpoints, Button, Dialog, Inline, Radio, RadioGroup, Stack } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import DebugButton from './DebugButton';

const handleSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const alwaysUseKeysOption = formData.get('alwaysUseKeysOption');
  const useKeyWhenTextNotFoundOption = formData.get('useKeyWhenTextNotFoundOption');
  const warnNotFoundKeysOption = formData.get('warnNotFoundKeysOption');
  sessionStorage.setItem(
    'debugTextResolver',
    JSON.stringify({
      alwaysUseKeys: alwaysUseKeysOption === 'yes',
      useKeyWhenTextNotFound: useKeyWhenTextNotFoundOption === 'yes',
      warnNotFoundKeys: warnNotFoundKeysOption === 'yes',
    }),
  );
  window.location.reload();
};

const loadSetting = (setting, defaultValue) => {
  const storedValues = JSON.parse(sessionStorage.getItem('debugTextResolver'));
  return storedValues?.[setting] ?? defaultValue;
};

const loadAlwaysUseKeysSetting = () => loadSetting('alwaysUseKeys', false);

const loadUseKeyWhenTextNotFoundSetting = () => loadSetting('useKeyWhenTextNotFound', false);

const loadWarnNotFoundKeysSetting = () => loadSetting('warnNotFoundKeys', false);

const YesOrNoChoice = ({ label, name, onChange, value }) => (
  <RadioGroup name={name} onChange={onChange} selectedValue={value ? 'yes' : 'no'}>
    <Stack spacing={8}>
      <RadioGroup.Legend>{label}</RadioGroup.Legend>
      <Inline spacing={24}>
        <Radio.Label htmlFor={`${name}-yes`}>
          <Inline alignY="center" spacing={8}>
            <Radio id={`${name}-yes`} value="yes" />
            Yes
          </Inline>
        </Radio.Label>
        <Radio.Label htmlFor={`${name}-no`}>
          <Inline alignY="center" spacing={8}>
            <Radio id={`${name}-no`} value="no" />
            No
          </Inline>
        </Radio.Label>
      </Inline>
    </Stack>
  </RadioGroup>
);

const DebugTexts = () => {
  const { t } = useTranslation();
  const [showDebugSettings, setShowDebugSettings] = useState(false);
  const [alwaysUseKeys, setAlwaysUseKeys] = useState(loadAlwaysUseKeysSetting());
  const [useKeyWhenTextNotFound, setUseKeyWhenTextNotFound] = useState(
    loadUseKeyWhenTextNotFoundSetting(),
  );
  const [warnNotFoundKeys, setWarnNotFoundKeys] = useState(loadWarnNotFoundKeysSetting());

  const closeSettings = () => {
    setShowDebugSettings(false);
  };

  const openSettings = () => {
    setShowDebugSettings(true);
  };

  return (
    <>
      <DebugButton onClick={openSettings}>Texts</DebugButton>
      <Dialog
        closeButtonAriaLabel={t('ScreenReader.CloseButtonDialog.AriaLabel.Text')}
        footer={
          <Inline align="end" collapseBelow={breakpoints._560} spacing={8}>
            <Button
              form="textResolverOptionsForm"
              style={{ minWidth: '200px' }}
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Inline>
        }
        isOpen={showDebugSettings}
        onDismiss={closeSettings}
        title="Texts & translations"
      >
        <form id="textResolverOptionsForm" method="POST" onSubmit={handleSubmit}>
          <Stack spacing={24}>
            <YesOrNoChoice
              label="Display key names instead of translations?"
              name="alwaysUseKeysOption"
              onChange={() => {
                setAlwaysUseKeys(!alwaysUseKeys);
              }}
              value={alwaysUseKeys}
            />
            <YesOrNoChoice
              label="Display key name when no translation is found?"
              name="useKeyWhenTextNotFoundOption"
              onChange={() => {
                setUseKeyWhenTextNotFound(!useKeyWhenTextNotFound);
              }}
              value={useKeyWhenTextNotFound}
            />
            <YesOrNoChoice
              label="Warn in the console if key has no translation?"
              name="warnNotFoundKeysOption"
              onChange={() => {
                setWarnNotFoundKeys(!warnNotFoundKeys);
              }}
              value={warnNotFoundKeys}
            />
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default DebugTexts;
