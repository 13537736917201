import type { RefObject, KeyboardEvent } from 'react';
import { useCallback } from 'react';

type FocusOptions = {
  focusVisible?: boolean;
  preventScroll?: boolean;
};

interface UseNavigateOnArrowKeysProps {
  buttonRef: RefObject<HTMLButtonElement>;
  containerRef: RefObject<HTMLUListElement>;
  isSubMenuOpen: boolean;
}

export const useNavigateOnArrowKeys = ({
  buttonRef,
  containerRef,
  isSubMenuOpen,
}: UseNavigateOnArrowKeysProps) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!buttonRef.current || !containerRef.current) {
        return;
      }

      const items = Array.from(
        containerRef.current.querySelectorAll('li a, li button'),
      ) as HTMLElement[];
      const isFocusInMenu = containerRef.current.contains(document.activeElement);
      const currentIndex = items.findIndex((item) => item === document.activeElement);
      const focusOptions: FocusOptions = { focusVisible: true };

      if (event.key === 'ArrowDown' && isSubMenuOpen && !isFocusInMenu) {
        event.preventDefault();
        items[0]?.focus(focusOptions);
        return;
      }

      if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        event.preventDefault();
        const nextIndex = currentIndex === -1 ? 0 : Math.min(currentIndex + 1, items.length - 1);
        items[nextIndex]?.focus(focusOptions);
        return;
      }

      if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
        event.preventDefault();
        const previousIndex = currentIndex === -1 ? 0 : Math.max(currentIndex - 1, 0);
        items[previousIndex]?.focus(focusOptions);
      }
    },
    [buttonRef, containerRef, isSubMenuOpen],
  );

  return { handleKeyDown };
};
