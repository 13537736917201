import { css } from '@eti/styles';
import type { ReactNode, Ref } from 'react';

const styledMenuListStyles = css`
  max-height: 400px;
  overflow: scroll;

  & div[id*='react-select'][id*='option']:not(:first-child) {
    border-top: 1px solid #d8d8d8;
  }

  & > div {
    &:last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;

interface MenuListProps {
  children: ReactNode;
  innerRef: Ref<HTMLDivElement>;
}

const MenuList = ({ children, innerRef }: MenuListProps) => {
  return (
    <div className={styledMenuListStyles} ref={innerRef}>
      {children}
    </div>
  );
};

export default MenuList;
