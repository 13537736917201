import { isAfter, isValid, parse } from 'date-fns';
import { createValidator } from 'revalidate';
import { INVALID_INFANT_BIRTH_DATE } from '../constants/defaultErrorMessages';
const isValidInfantWithNoFutureBirthDate = function (format) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  return createValidator(message => input => {
    const birthDate = parse(input, format, new Date());
    const validBirthDate = isValid(birthDate);
    return !validBirthDate || isAfter(birthDate, new Date()) ? message || INVALID_INFANT_BIRTH_DATE : null;
  }, INVALID_INFANT_BIRTH_DATE);
};
export default isValidInfantWithNoFutureBirthDate;