import { Suspense } from 'react';
import { css } from '@eti/styles';
import { vars } from '@etg/wings';
import { LoadingDots } from '@eti/components';
import { useContentful } from '../../../common/custom-hooks/useContentful';
import ContentfulComponents from '../../../common/contentful/ContentfulComponents';
import type { AllDataTypes } from '../../../common/contentful/ContentfulTypes';

const wrapperStyles = css`
  background: ${vars.colors.pageBackdrop};
  height: 100%;
`;
const loadingDotsContainerStyles = css`
  color: #0b99f0;
  display: flex;
  font-size: 2.5rem;
  justify-content: center;
  margin-bottom: 25%;
  margin-top: 25%;
`;

const CmsContentPage = () => {
  const { loading, content } = useContentful();

  if (loading) {
    return <LoadingDots containerStyle={loadingDotsContainerStyles} />;
  }

  if (content) {
    return (
      <div className={wrapperStyles}>
        <Suspense fallback={<LoadingDots containerStyle={loadingDotsContainerStyles} />}>
          {content.map((item: AllDataTypes) => (
            <ContentfulComponents block={item} key={item.sys.id} />
          ))}
        </Suspense>
      </div>
    );
  }
  return null;
};

export default CmsContentPage;
