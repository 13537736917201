import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const SelfTransferPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="content/travel-conditions"
      pageTitle={t('PageTitle.rf.self-transfer.action')}
      textKey="SelfTransfer.Text"
    />
  );
};

export default SelfTransferPage;
