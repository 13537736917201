import { css } from '@eti/styles';
import { vars } from '@etg/wings';
export const cardWrapper = css`
  border: solid #e5e5e5 1px;
  display: grid;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
export const cardTitle = isButton => css`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  ${isButton ? 'cursor: pointer;' : ''}
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: space-between;
  padding-inline: 0;
`;
export const listWrapper = css`
  display: grid;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const title = css`
  display: grid;
  font-weight: 700;
  margin-bottom: 16px;
`;
export const buttonInfo = css`
  color: ${vars.colors.signal.caution.dark};

  &:hover {
    color: ${vars.colors.signal.caution.dark};
  }

  &:active {
    color: ${vars.colors.signal.caution.dark};
  }

  &:focus-visible {
    outline: 0.125rem solid ${vars.colors.signal.caution.dark};
  }

  svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;
export const airportRow = isAvailable => css`
  align-content: center;
  border-radius: 5px;
  display: grid;
  height: 36px;
  padding-inline: 8px;

  ${!isAvailable ? `background-color: ${vars.colors.signal.caution.tint};` : ''}
  ${!isAvailable ? `color: ${vars.colors.signal.caution.dark};` : ''}

  .airportName {
    grid-area: 1 / 1 / 2 / 2;
  }

  .airportStatus {
    display: flex;
    grid-area: 1 / 2 / 2 / 3;
    justify-content: end;
  }

  .statusText {
    padding-inline-start: 4px;
  }
`;
export const separator = css`
  border-bottom: solid #e5e5e5 1px;
  border-top: none;
  margin-inline: -16px;
`;
export const toggleIconStyles = open => css`
  transform: rotateX(${open ? '180deg' : '0deg'});
  transition: transform 0.2s ease-in-out;
`;