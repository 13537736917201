import {
  addDays,
  addYears,
  differenceInCalendarDays,
  endOfDay,
  isAfter,
  isBefore,
  isLastDayOfMonth,
  isSameDay,
  isWithinInterval,
  startOfDay,
  startOfTomorrow,
  subDays,
} from 'date-fns';
import { string } from '@eti/utils';
import { tripTypes } from '../../../constants/tripTypesConstants';
import { DEPARTURE_DATE, RETURN_DATE } from '../constants/formFieldNames';

const { MULTI_STOP, RETURN } = tripTypes;

export const getStartOfToday = () => startOfDay(new Date());

export const getStartOfTomorrow = () => startOfTomorrow(new Date());

export const getOneYearAndOneDayFromToday = () => addDays(addYears(getStartOfToday(), 1), 1);

export const getOneYearFromToday = () => addYears(getStartOfToday(), 1);

const getDaysFromToday = (days) => addDays(getStartOfToday(), days);

export const isSameDate = (day1, day2) => isSameDay(day1, day2);

export const isDayBefore = (day1, day2) => {
  if (!day1 || !day2) {
    return false;
  }
  return isBefore(day1, day2);
};

export const isDayAfter = (day1, day2) => {
  if (!day1 || !day2) {
    return false;
  }
  return isAfter(day1, day2);
};

export function isDayInRange(day, { from, to }) {
  let valid;
  const start = startOfDay(from);
  const end = endOfDay(to);

  try {
    valid = isWithinInterval(day, { start, end });
  } catch (err) {
    valid = false;
  }
  return valid;
}

export const isSelected = (day, departureDate, returnDate) =>
  isSameDate(day, departureDate) || isSameDate(day, returnDate);

export const isInRange = (day, departureDate, returnDate, mask) => {
  if (!departureDate || !returnDate || !day) {
    return false;
  }

  const range = {
    from: addDays(departureDate, 1),
    to: subDays(returnDate, 1),
  };
  return isDayInRange(day, range) && (!mask.from || !mask.to);
};

const getStartOfValidRange = (day, numberOfDaysFromToday, selectedTripType) =>
  selectedTripType === MULTI_STOP && day ? day : getDaysFromToday(numberOfDaysFromToday);

export const isDisabled = (
  day,
  numberOfDaysFromToday = 0,
  previousBoundDepartureDate,
  selectedTripType,
) => {
  if (!day) {
    return false;
  }

  const validRange = {
    from: getStartOfValidRange(previousBoundDepartureDate, numberOfDaysFromToday, selectedTripType),
    to: getOneYearFromToday(),
  };

  return !isDayInRange(day, validRange);
};

export const isMasked = (day, mask, selectedTripType, departureDate, returnDate) => {
  if (selectedTripType !== RETURN) {
    return false;
  }

  if (!mask.from || !mask.to || !day) {
    return false;
  }

  return isDayInRange(day, mask) && !isSameDate(day, departureDate) && !isSameDate(day, returnDate);
};

const dateMask = (from, to) => ({
  from,
  to,
});

export const calculateMask = (currentField, departureDate, returnDate, day) => {
  if (currentField === DEPARTURE_DATE) {
    if (isDayBefore(day, returnDate)) {
      return dateMask(day, returnDate);
    }
    if (isSameDate(day, returnDate)) {
      return dateMask(day, day);
    }
    if (isDayAfter(day, returnDate)) {
      return dateMask(day, null);
    }
  } else if (currentField === RETURN_DATE) {
    if (isDayAfter(day, departureDate)) {
      return dateMask(departureDate, day);
    }
    if (isSameDate(day, departureDate)) {
      return dateMask(day, day);
    }
    if (isDayBefore(day, departureDate)) {
      return dateMask(null, day);
    }
  }

  return dateMask(departureDate, returnDate);
};

export const isDepartureDateValid = (day, returnDate) =>
  Boolean(returnDate && isDayBefore(day, returnDate));

export const isReturnDateValid = (day, departureDate) =>
  Boolean(departureDate && (isSameDate(day, departureDate) || isDayAfter(day, departureDate)));

export const calculateDayRange = (
  currentField,
  departureDate,
  returnDate,
  day,
  setDepartureDate,
  setReturnDate,
) => {
  if (currentField === DEPARTURE_DATE) {
    if (isDayAfter(day, returnDate)) {
      setReturnDate(undefined);
    }
    setDepartureDate(day);
    return;
  }

  if (currentField === RETURN_DATE) {
    if (isDayBefore(day, departureDate)) {
      setDepartureDate(day);
      setReturnDate(undefined);
      return;
    }
    setReturnDate(day);
  }
};

export const calculateInitialMonth = (day, numberOfDaysFromToday, departureDate, returnDate) => {
  if (departureDate) {
    return departureDate;
  }
  if (returnDate) {
    return returnDate;
  }
  if (isLastDayOfMonth(day) && numberOfDaysFromToday === 1) {
    return new Date(day.getFullYear(), day.getMonth() + 1, 1);
  }
  return day;
};

export const generateDurationText = (from, to, durationTextSingular, durationTextPlural) => {
  const duration = from && to && !isSameDate(from, to) ? differenceInCalendarDays(to, from) + 1 : 1;
  const durationText = duration > 1 ? durationTextPlural : durationTextSingular;

  return string.insertArgument(durationText, duration);
};
