import { css, cx } from '@eti/styles';
import type { ComponentPropsWithoutRef, ReactNode, Ref } from 'react';

const styledMenuStyles = css`
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 0 0 3px 3px;
  border-top: none;
  box-shadow:
    0 0 1px 0 rgb(0 0 0 / 4%),
    0 2px 6px 0 rgb(0 0 0 / 4%),
    0 10px 20px 0 rgb(0 0 0 / 4%);
  box-sizing: border-box;
  margin-bottom: 2px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
`;

interface MenuProps {
  children: ReactNode;
  className: string;
  innerProps: Exclude<ComponentPropsWithoutRef<'div'>, 'className'>;
  innerRef: Ref<HTMLDivElement>;
}

const Menu = ({ className, children, innerProps, innerRef }: MenuProps) => (
  <div {...innerProps} className={cx(styledMenuStyles, className)} ref={innerRef}>
    {children}
  </div>
);

export default Menu;
