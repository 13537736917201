import { formValueSelector } from 'redux-form';
import { TAX_ID_INPUT } from './constants';
export const removeSessionTaxId = () => sessionStorage.removeItem(TAX_ID_INPUT);
export const persistTaxId = taxId => {
  if ((taxId === null || taxId === void 0 ? void 0 : taxId.length) > 0) {
    return sessionStorage.setItem(TAX_ID_INPUT, taxId);
  }
  return removeSessionTaxId();
};
export const getTaxId = (name, form) => state => {
  const formData = formValueSelector(form)(state, name);
  return formData || '';
};