import { ComboBox } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import type { Option } from '../models';

interface RegionSelectProps {
  regions: { name: string; code: string }[];
  value: Option;
  onChange: (option: Option) => void;
}

const RegionSelect = ({ regions, value, onChange }: RegionSelectProps) => {
  const regionOptions = regions.map((region: { name: string; code: string }) => ({
    label: region.name,
    value: region.code,
  }));
  const { t } = useTranslation();
  const label = t('Settings.Region.Label');
  return (
    <ComboBox
      data-testid="settings-region-input"
      label={label}
      name="regionSelect"
      noMatchLabel={t('Settings.NoMatchLabel')}
      onChange={onChange}
      options={regionOptions}
      placeholder={label}
      value={value}
    />
  );
};

export default RegionSelect;
