import PropTypes from 'prop-types';
import { components } from 'react-select';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import SwitchDirectionButton from './SwitchDirectionButton';
import ClearIndicator from './dropdown/ClearIndicator';

const switchDirectionButtonStyles = css`
  color: #878787;
  display: block;
`;

const getCustomInnerProps = (props, callback) => ({
  ...props,
  innerProps: {
    ...props.innerProps,
    onMouseDown: () => {
      callback();
    },
    onTouchEnd: () => {
      callback();
    },
  },
});

export const Custom = {
  ClearIndicator: (props) => <ClearIndicator {...props} />,
  SwitchDirectionIndicator: ({ onSwitchDirection, ...props }) => {
    const { t } = useTranslation();

    return (
      <components.ClearIndicator {...getCustomInnerProps(props, onSwitchDirection)}>
        <SwitchDirectionButton
          aria-label={t('Air.Search.SwitchLocations.Label')}
          className={switchDirectionButtonStyles}
          data-testid="searchForm-origin-input-switch-direction-button"
          rotate={90}
          title={t('Air.Search.SwitchLocations.Label')}
        />
      </components.ClearIndicator>
    );
  },
};

Custom.propTypes = {
  ClearIndicator: PropTypes.any,
};

Custom.SwitchDirectionIndicator.propTypes = {
  onSwitchDirection: PropTypes.func,
};
