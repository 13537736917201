import { vars } from '@etg/wings';
import { css } from '@eti/styles';

const linkStyles = css`
  color: ${vars.colors.links.default};

  &:hover {
    color: ${vars.colors.links.focus};
  }

  &:focus-visible {
    outline: 0.125rem solid ${vars.colors.focusRing};
  }
`;

const textKeyTitleStyles = css`
  h4 {
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 8px;
  }

  p + h4 {
    padding-top: 16px;
  }

  ul + h4 {
    padding-top: 8px;
  }
`;

const bulletStyles = (paddingBottom: number = 16, paddingTop: number = 16) => css`
  ul {
    list-style: unset;
    padding-bottom: ${paddingBottom}px;
    padding-inline-start: 24px;
    padding-top: ${paddingTop}px;
    width: 100%;

    & li:not(:last-child) {
      padding-bottom: 8px;
    }
  }
`;

const screenReadersOnlyStyles = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const focusStyles = css`
  outline: none;

  &:focus-visible {
    outline: 0.125rem solid ${vars.colors.focusRing};
    outline-offset: 0.1875rem;
  }
`;

export { linkStyles, bulletStyles, focusStyles, screenReadersOnlyStyles, textKeyTitleStyles };
