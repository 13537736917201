import { useQuery } from '@apollo/client';
import { gql, PaymentStatus } from '@eti/schema-types';

export const PAYMENT_STATUS = gql(`
  query getPaymentStatus($paymentTransactionId: Long!) {
    paymentStatus(paymentTransactionId: $paymentTransactionId) {
      status
    }
  }
`);

const usePaymentStatus = (paymentTransactionId: number | string) => {
  const variables = {
    paymentTransactionId: Number(paymentTransactionId),
  };
  const { data, loading, stopPolling, error } = useQuery(PAYMENT_STATUS, {
    pollInterval: 5000,
    variables,
  });

  if (
    data?.paymentStatus?.status === PaymentStatus.Completed ||
    data?.paymentStatus?.status === PaymentStatus.Error
  ) {
    stopPolling();
  }

  if (error) {
    stopPolling();
  }

  return {
    error,
    loading,
    status: data?.paymentStatus?.status,
  };
};

export default usePaymentStatus;
